import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

const requireComponent = require.context('@/components/base', true, /\.vue$/);
export default {
  install(app) {
    for (const file of requireComponent.keys()) {
      const componentConfig = requireComponent(file);
      const name = file
        .replace(/index.js/, '')
        .replace(/^\.\//, '')
        .replace(/\.\w+$/, '');
      const componentName = upperFirst(camelCase(name));

      app.component(
        `Base${componentName}`,
        componentConfig.default || componentConfig
      );
    }
  }
}