<template>
  <v-col cols="12" class="pa-0" id="tire-architecture-page">
    <v-skeleton-loader :loading="true" style="height: 530px;" v-if="metaArchLoading || scanLoading" type="table-heading, list-item-two-line, image, table-tfoot">
    </v-skeleton-loader>
    <v-card class="position-relative mb-2" v-if="!metaArchLoading && !scanLoading && tireData">
      <div class="py-5">
        <template v-for="(row, index) in tireData" :key="index">
          <v-row :no-gutters="!agentId" class="ma-0 tire-architecture align-center justify-space-between">
            <v-col cols="12" sm="6" :lg="!agentId ? 4 : 3" :xl="!agentId ? 4 : 3" class="left-arch" :class="{'pa-0 order-0': (agentId)}">
              <v-row class="ma-0 align-center justify-start justify-lg-center justify-xl-center cursor-pointer" :no-gutters="!agentId"
                  @click="selectedTireModel = row.L;openGraph = true;"
                >
                <template v-for="(tire, index) in row.L" :key="index">
                  <v-col :order="tire.position === 'O' ? 1 : 0" class="mb-3 d-flex align-center justify-center pa-0">
                    <div>
                        <img :src="tire.measurementImageUrl" style="max-width: 256px;" cover/>
                        <div v-if="tire.conclusionDescription && (tire.conclusionDescription.eatenTire !== 'NONE' || tire.conclusionDescription.pressureTire !== 'NONE')">
                          <v-list-subheader v-if="tire.conclusionDescription.eatenTire !== 'NONE'" class="h-auto text-caption">
                            <b>Uneven Wear:</b><span class="ml-2">{{$filters.titleCase(tire.conclusionDescription.eatenTire)}}</span>
                          </v-list-subheader>
                          <v-list-subheader v-if="tire.conclusionDescription.pressureTire !== 'NONE'" class="h-auto text-caption">
                            <b>Tire Pressure:</b><span class="ml-2">{{$filters.titleCase(tire.conclusionDescription.pressureTire)}}</span>
                          </v-list-subheader>
                        </div>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-col>
            <v-col cols="12" :lg="!agentId ? 3 : 6" :xl="!agentId ? 3 : 6" class="center-arch" :class="{'order-1 order-lg-0' : (agentId)}">
                <v-row class="ma-0" :no-gutters="!agentId">
                  <v-col :cols="agentId ? 6 : 5" class="d-flex align-center justify-center">
                    <template v-if="!agentId">
                        <img :src="tireLeft" cover style="max-height: 100px;"/>
                        <img :src="tireRight" cover style="max-height: 100px;margin-left: -3px" v-if="row.L.length > 1"/>
                    </template>
                    <v-card v-else class="w-full pa-0" tile>
                        <v-card-text class="text-center">
                            <h5 class="text-black text-uppercase">
                              {{ row.L[0].label }}
                            </h5>
                            <h6 class="font-weight-medium text-black text-break text-subtitle-1">  Selected Repair Type </h6>
                        </v-card-text>
                        <v-card-actions class="justify-center flex-column">
                            <template v-for="repair_type in ['Repair', 'Recheck Next Visit', 'Replace']" :key="repair_type">
                                <v-btn variant="outlined" :color="textColor" :style="{'--bg-color': color}" elevation="2" class="ma-0 mb-3 button-word-break" block
                                  :class="{ 'selected': selectedRepairs.some((repair) => repair.repair_type == repair_type && repair.index == index && repair.position == 'left') }"
                                  @click="selectRepairType(repair_type, row.L, index, 'left')"
                                >
                                  {{repair_type}}
                                </v-btn>
                            </template>
                        </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col v-if="!agentId" cols="2" class="fill-height red"></v-col>
                  <v-col :cols="agentId ? 6 : 5" class="d-flex align-center justify-center">
                    <template v-if="!agentId">
                        <img :src="tireLeft" cover style="max-height: 100px;" v-if="row.R.length > 1"/>
                        <img :src="tireRight" cover style="max-height: 100px;margin-left: -3px"/>
                    </template>
                    <v-card v-else class="w-full pa-0" tile>
                        <v-card-text class="text-center">
                            <h5 class="text-black text-uppercase">
                              {{ row.R[0].label }}
                            </h5>
                            <h6 class="font-weight-medium text-black text-break text-subtitle-1">  Selected Repair Type </h6>
                        </v-card-text>
                        <v-card-actions class="justify-center flex-column">
                            <template v-for="repair_type in ['Repair', 'Recheck Next Visit', 'Replace']" :key="repair_type">
                                <v-btn variant="outlined" :color="textColor" :style="{'--bg-color': color}" elevation="2" class="ma-0 mb-3 button-word-break" block
                                  :class="{ 'selected': selectedRepairs.some((repair) => repair.repair_type == repair_type && repair.index == index && repair.position == 'right') }" @click="selectRepairType(repair_type, row.R, index, 'right')"
                                >
                                        {{repair_type}}
                                </v-btn>
                            </template>
                        </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="6" :lg="!agentId ? 4 : 3" :xl="!agentId ? 4 : 3" class="right-arch" :class="{'pa-0 order-0': (agentId)}">
              <v-row class="ma-0 align-center justify-end justify-lg-center justify-xl-center cursor-pointer " :no-gutters="!agentId"
                @click="selectedTireModel = row.R;openGraph = true;"
                >
                <template v-for="(tire, index) in row.R" :key="index">
                    <v-col :order="tire.position=='I' ? 'first' : ''" class="mb-3 d-flex align-center justify-center pa-0">
                        <div>
                            <img :src="tire.measurementImageUrl" style="max-width: 256px; margin-top: 50px;" cover/>
                            <div v-if="tire.conclusionDescription && (tire.conclusionDescription.eatenTire !== 'NONE' || tire.conclusionDescription.pressureTire !== 'NONE')">
                              <v-list-subheader v-if="tire.conclusionDescription.eatenTire !== 'NONE'" class="h-auto text-caption">
                                <b>Uneven Wear:</b><span class="ml-2">{{$filters.titleCase(tire.conclusionDescription.eatenTire)}}</span>
                              </v-list-subheader>
                              <v-list-subheader v-if="tire.conclusionDescription.pressureTire !== 'NONE'" class="h-auto text-caption">
                                <b>Tire Pressure:</b><span class="ml-2">{{$filters.titleCase(tire.conclusionDescription.pressureTire)}}</span>
                              </v-list-subheader>
                            </div>
                        </div>
                      </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>

          <hr v-if="index < Object.entries(tireData).length" class="mt-5">
        </template>
      </div>
    </v-card>
    <GraphModal v-if="openGraph" :model="selectedTireModel" @close="selectedTireModel=null;openGraph= false"/>
  </v-col>
</template>

<script>
import {mapGetters} from 'vuex';

import tireData from '../../service/tire-data';
import GraphModal from "./GraphModal.vue";
import tireLeft from "@/assets/images/tire-l.png";
import tireRight from "@/assets/images/tire-r.png";

export default {
    props: ['model', 'color', 'textColor', 'items'],
    components: {
       GraphModal,
    },
    data: (vm) => ({
        agentId: vm.$route.params.agentId || null,
        tireData: null,
        selectedTireModel: null,
        openGraph: false,
        selectedRepairs: [],
        tireLeft,
        tireRight,
    }),
    watch: {
        model(value) {
            if (value) this.tireData = tireData(value);
        },
        items(newItems) {
          this.setSelectedRepairs(newItems);
        }
    },
    created() {
        if (this.model) this.tireData = tireData(this.model);
    },
    computed: {
        ...mapGetters(['scanLoading', 'metaArchLoading'])
    },
    methods: {
      selectRepairType(repair_type, row, index, position) {
        // Define labels for each position
        const label = row.length > 0 ? row[0].label : '';
        const panel_id = row.length > 0 ? row[0].id : '';

        if (this.selectedRepairs.length > 0) {
          const selectedIndex = this.selectedRepairs.findIndex((selectedRepair) => selectedRepair.index === index && selectedRepair.position === position);
          if (selectedIndex > -1) {
            this.selectedRepairs[selectedIndex].repair_type = repair_type;
          } else {
            this.selectedRepairs.push({ index, label, panel_id, repair_type, position });
          }
        } else {
          this.selectedRepairs.push({ index, label, panel_id, repair_type, position });
        }
        this.$emit('selected-repairs-updated', this.selectedRepairs);
      },
      setSelectedRepairs(items) {
        if (!items || typeof items !== 'object') {
          console.error("Items is not an object or is null/undefined:", items);
          return;
        }
        const tempTireData = this.$helpers.cloneData(this.tireData)|| {};
        const tempSelectedRepairs = [];

        Object.keys(tempTireData).forEach((index) => {
          const row = tempTireData[index];

          ['L', 'R'].forEach((side) => {
            const positionLabels = this.getTireLabel(index);
            const position = side === 'L' ? 'left' : 'right';
            const label = positionLabels[position];

            row[side].forEach((tire) => {
              if (items.hasOwnProperty(tire.id)) {
                const repairData = {
                  index,
                  label,
                  panel_id: tire.id,
                  repair_type: items[tire.id].repair_type,
                  position
                };
                tempSelectedRepairs.push(repairData);
              }
            });
          });
        });
        this.selectedRepairs = tempSelectedRepairs;
        this.$emit('selected-repairs-updated', this.selectedRepairs);
      },
      getTireLabel(index) {
        const positionLabels = {
          'left': index == 1 ? 'FRONT LEFT' : index == Object.keys(this.tireData).length ? 'REAR LEFT' : `${index} LEFT`,
          'right': index == 1 ? 'FRONT RIGHT' : index == Object.keys(this.tireData).length ? 'REAR RIGHT' : `${index} RIGHT`
        };
        return positionLabels;
      },
    }
}
</script>

<style>
.selected {
  background-color: var(--bg-color) !important;
  border-color: var(--bg-color) !important;
}
.v-list-subheader{
  min-height: 0px !important;
  padding: 0px 16px !important;
}
.text-subtitle-1, .text-caption{
  font-family: 'Poppins' !important;
}
</style>