<template>
    <v-dialog :model-value="visible" scrollable max-width="50%" @click:outside="$emit('close')">
        <v-card class="pa-5">
            <v-row class="ma-0 pa-0 align-center justify-center">
                <v-col cols="12">
                    <v-btn-toggle density="comfortable" v-model="measure" mandatory class="d-flex flex-wrap">
                        <v-btn size="small" :value="0">
                            <v-chip size="x-small" class="pa-1 mr-2" :color="!measure ? 'primary' : undefined" label>
                                {{ totalMeasureCount || 0 }}
                            </v-chip>
                            All
                        </v-btn>
                        <v-btn size="small" value="oversize">
                            <v-chip size="x-small" class="pa-1 mr-2" :color="measure == 'oversize' ? 'primary' : undefined" label>
                            {{  meaureGroup.oversize ? meaureGroup.oversize.length : 0 }}
                            </v-chip>
                            Oversize
                        </v-btn>
                        <v-btn size="small" value="severe">
                            <v-chip size="x-small" class="pa-1 mr-2" :color="measure == 'severe' ? 'primary' : undefined" label>
                                {{  meaureGroup.severe ? meaureGroup.severe.length : 0 }}
                            </v-chip>
                            Severe
                        </v-btn>
                        <v-btn size="small" value="heavy">
                            <v-chip size="x-small" class="pa-1 mr-2" :color="measure == 'heavy' ? 'primary' : undefined" label>
                                {{  meaureGroup.heavy ? meaureGroup.heavy.length : 0 }}
                            </v-chip>
                            Heavy
                        </v-btn>
                        <v-btn size="small" value="medium">
                            <v-chip size="x-small" class="pa-1 mr-2" :color="measure == 'medium' ? 'primary' : undefined" label>
                                {{  meaureGroup.medium ? meaureGroup.medium.length : 0 }}
                            </v-chip>
                            Medium
                        </v-btn>
                        <v-btn size="small" value="light">
                            <v-chip size="x-small" class="pa-1 mr-2" :color="measure == 'light' ? 'primary' : undefined" label>
                                {{  meaureGroup.light ? meaureGroup.light.length : 0 }}
                            </v-chip>
                            Light
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <v-card-text style="max-height: 100%; max-width: 100%;" class="pa-0 pa-2">
                <template v-if="(!measure && totalMeasureCount) || meaureGroup[measure]">
                    <v-row class="ma-0 justify-center">
                        <v-col cols="12" md="6" lg="6" xl="6">
                            <v-lazy class="cursor-pointer" @click="viewImage">
                                <v-img height="100%" width="100%" :src="selected.src" class="rounded-lg" />
                            </v-lazy>
                        </v-col>
                        <v-col cols="12" md="6" lg="6" xl="6">
                            <v-card class="pa-0 w-full">
                                <v-table>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr v-for="(size, index) in measure ? meaureGroup[measure] : selected.data" :key="`measure-${index}`">
                                                <td>
                                                    <v-chip class="ma-2" rounded color="primary" size="small"> {{ index + 1 }}</v-chip>
                                                </td>
                                                <td>
                                                    <div class="d-flex">{{size.dimensions[0]}}mm x {{size.dimensions[1]}}mm <v-list-subheader class="w-auto text-capitalize">- {{size.measure}}</v-list-subheader></div>
                                                </td>
                                                <td>
                                                    <v-icon :color="measureColor[size.measure]" class="ma-0 pa-0">{{ 'mdi-checkbox-blank' }}</v-icon>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </template>
                <v-row v-else class="w-full justify-center ma-0 pa-5">
                    No Damage Detected!.
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            selected: {
                type: Object,
                default: () => ({}),
            },
        },
        data () {
            return {
                measure: null,
                measureColor: {
                    oversize: '#561329',
                    severe: '#81182E',
                    heavy: '#C1484E',
                    medium: '#CC9188',
                    light: '#D8D1C1',
                }
            }
        },
        computed: {
            meaureGroup() {
               return  _.groupBy(this.selected.data, 'measure');
            },
            totalMeasureCount() {
                const sizeData = this.meaureGroup;
               return _.reduce(Object.keys(sizeData), (result, size) => {
                    result = result + sizeData[size].length;
                    return result;
                }, 0)
            },
        },
        methods: {
            viewImage() {
                const image = [];
                image.push(this.selected.src);
                this.$viewerApi({
                    images: image
                })
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>