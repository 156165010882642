<template>
    <v-dialog :model-value="open" :persistent="persistent || false" scrollable :max-width="width || '300px'"
         @click:outside="$emit('close')">
        <slot></slot>
    </v-dialog>
</template>

<script>
export default {
    props: ['open', 'width', 'persistent'],
    emits: ['close']
}
</script>