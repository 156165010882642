<template>
  <v-navigation-drawer
    v-model="getThemeMode.verticalSidebarDrawer"
    class="sidebar-six white"
    light
    height="100%"
    width="280"
    :mobile-breakpoint="960"
    :floating="true"
  >
    <PerfectScrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      class="ps"
      style="height: 100%"
    >
      <div class="px-8 py-4 top-toolbar">
        <v-img :src="getLogo ? getLogo : Logo" class="flex-grow-0" contain/>
      </div>

      <v-divider class="mb-5"></v-divider>

      <v-list class="py-0 mx-4 sidebar-lists" density="comfortable">
        <template v-for="(item, i) in items">
          <!-- header title -->
          <div :key="item.subheader" v-if="item.subheader">
            <v-list-subheader>{{ item.subheader }}</v-list-subheader>
            <v-divider></v-divider>
          </div>
          <!-- group item of lists -->
          <base-item-group-two v-if="item.children && item.childAccess.some(item => userAccessPermission.includes(item))"
            :key="`group-${i}`" :item="item" />
          <!-- single item -->
          <base-item-two v-else-if="!item.children && userAccessPermission.includes(item.access)" :key="`item-${i}`" :item="item"/>
        </template>
      </v-list>
    </PerfectScrollbar>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";

import menuItems from "@/constants/sidebar-menu";
import Logo from "../../assets/images/scanspek-logo.png";

export default {
  name: "SidebarLayout",
  components:{
    PerfectScrollbar,
  },
  data: (vm) => ({
    userAccessPermission: vm.$helpers.getCurData('curUserPermissions'),
    selectedItem: 0,
    group: null,
    items: menuItems,
    Logo,
  }),
  computed: {
    ...mapGetters(["getThemeMode", 'getLogo']),
  }
};
</script>

<style lang="scss">
.sidebar-six {
  .v-list-item__icon {
    margin-right: 8px !important;
  }

  .v-list-item--link:before {
    border-radius: 8px;
  }
  .v-list-group__items .v-list-item {
    padding-inline-start: 15px !important;
  }
}
</style>
