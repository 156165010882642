import agents from '@/store/modules/agents/api';

export default {
    getAgentLists({ commit }) {
        commit("setAgentsListLoading", true);
        return new Promise((res, rej) => {
            agents.agentsList().then(response => {
                commit("setAgentsList", response);
                res(response);
            }).catch((error) => {
                commit("setAgentsListLoading", false);
                rej(error)
            });
        });
    },
    getSelectedAgent({ commit }, id) {
        commit("setAgentLoading", true);
        return new Promise((res, rej) => {
            agents.agentModel(id).then(response => {
                commit("setSelectedAgent", false);
                res(response);
            }).catch((error) => {
                commit("setAgentLoading", false);
                rej(error)
            });
        });
    },
    validatePincode({ commit }, data) {
        return new Promise((res, rej) => {
            agents.validatePincode(data.id, data.data).then(response => {
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
    createAgent({ commit }, data) {
        commit("setAgentLoading", true);
        return new Promise((res, rej) => {
            agents.createAgentModel(data).then(response => {
                commit("setAgentLoading", false);
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Agent Created Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setAgentLoading", false);
                rej(error)
            });
        });
    },
    updateSelectedAgent({ commit }, data) {
        commit("setAgentLoading", true);
        return new Promise((res, rej) => {
            agents.updateAgentModel(data.id, data.data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Agent Updated Successfully",
                    color: "success",
                });
                commit("setAgentLoading", false);
                res(response);
            }).catch((error) => {
                commit("setAgentLoading", false);
                rej(error)
            });
        });
    },
    deleteAgent({ commit }, agentId) {
        return new Promise((res, rej) => {
            agents.deleteAgentModel(agentId).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Agent Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    }
}