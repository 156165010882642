import parts from '@/store/modules/parts/api';

export default {
    getPartLists({ commit }, data) {
        commit("setPartsListLoading", true);
        return new Promise((res, rej) => {
            parts.partsList(data).then(response => {
                commit("setPartsList", response);
                res(response);
            }).catch((error) => {
                commit("setPartsListLoading", false);
                rej(error)
            });
        });
    },
    getSelectedPart({ commit }, id) {
        commit("setPartLoading", true);
        return new Promise((res, rej) => {
            parts.partModel(id).then(response => {
                commit("setPartLoading", false);
                res(response);
            }).catch((error) => {
                commit("setPartLoading", false);
                rej(error)
            });
        });
    },
    createPart({ commit }, data) {
        commit("setPartLoading", true);
        return new Promise((res, rej) => {
            parts.createPartModel(data).then(response => {
                commit("setPartLoading", false);
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Part Created Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setPartLoading", false);
                rej(error)
            });
        });
    },
    updateSelectedPart({ commit }, data) {
        commit("setPartLoading", true);
        return new Promise((res, rej) => {
            parts.updatePartModel(data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Part Updated Successfully",
                    color: "success",
                });
                commit("setPartLoading", false);
                res(response);
            }).catch((error) => {
                commit("setPartLoading", false);
                rej(error)
            });
        });
    },
    deletePart({ commit }, partId) {
        return new Promise((res, rej) => {
            parts.deletePartModel(partId).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Part Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    }
}