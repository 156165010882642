<template>
    <v-row class="ma-0 my-3">
      <v-chip :color="color" variant="flat" class="mb-3" label :style="`color: ${textColor}`">
        <v-icon start size="x-large" class="ml-n2">mdi-label</v-icon>
        Add Vehicle Photo
      </v-chip>
      <v-col cols="12" class="px-0">
        <v-text-field
          readonly
          v-model="model.vehicle_photo"
          placeholder="Upload Vehicle Photo"
          variant="outlined"
          ref="vehicle_photo"
          @click="$refs.file.click()"
          required
          hide-details="auto"
        >
          <template v-slot:append-inner>
            <v-icon
              v-if="model.vehicle_photo"
              class="mr-2"
              @click.stop="clearFile"
            >
              mdi-close
            </v-icon>
            <v-icon>
              mdi-file-upload
            </v-icon>
          </template>
        </v-text-field>
        <input
          type="file"
          accept="image/*"
          style="display: none"
          ref="file"
          @change="uploadedFile"
        />
      </v-col>
    </v-row>
  </template>

  <script>
  export default {
    props: {
      color: {
        type: String,
        default: 'primary'
      },
      textColor: {
        type: String
      },
      vehiclePhoto: {
        type: String,
        default: ''
      }
    },
    emits: ['vehiclePhotoUpdate'],
    data() {
      return {
        model: {
          vehicle_photo: this.vehiclePhoto,
        },
        file: ''
      };
    },
    watch: {
      vehiclePhoto(newVal) {
        this.model.vehicle_photo = newVal;
      }
    },
    methods: {
      uploadedFile(evt) {
        const files = evt.target.files[0];
        if (files === undefined) {
          this.model.vehicle_photo = '';
        } else {
          this.file = files;
          this.model.vehicle_photo = files.name;
          this.$emit('vehiclePhotoUpdate', files);
        }
      },
      clearFile() {
        this.model.vehicle_photo = null;
        this.file = '';
        this.$refs.file.value = '';
        this.$emit('vehiclePhotoUpdate', null);
      }
    }
  };
  </script>
