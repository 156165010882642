import http from '@/http';

class devices {
    devicesList(data) {
        return http('agent').get('devices', data);
    }
    deviceModel(deviceId) {
        return http('agent').get(`devices/${deviceId}`);
    }
    createDeviceModel(data) {
        return http('agent').post('devices', data);
    }
    updateDeviceModel(data) {
        return http('agent').put(`devices/${data.id}`, data);
    }
    deleteDeviceModel(deviceId) {
        return http('agent').deleteReq(`devices/${deviceId}`)
    }
}

export default new devices();