<template>
    <common-modal :open="show" :model-value="modelValue" :width="'800px'">
        <v-card>
            <v-toolbar color="primary" dark class="mb-5">
                <v-toolbar-title>Edit Damage Counts</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeModal">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row class="ma-0 pb-3">
                    <v-col cols="12" xl="6" lg="6" md="6" class="py-0 px-2">
                        <v-list shaped class="pa-0">
                            <div class="d-flex align-center justify-space-between mr-2 ml-2 my-2">
                                <div density="compact" class="mr-1 font-weight-bold">Dents</div>
                                <div density="compact" class="font-weight-bold">{{ calculateTotalDents() }}</div>
                            </div>
                            <v-list-item v-for="(item, i) in damageCountModel.lists" :key="i" density="compact" class="pr-0 pl-2">
                                <template #prepend>
                                    <v-icon :color="item.color" size="15" class="ma-0 pa-0">{{ item.icon }}</v-icon>
                                </template>
                                <div class="d-flex align-center justify-space-between w-100">
                                    <div density="compact" v-text="item.text" class="mr-2"></div>
                                    <div class="d-flex justify-end w-50">
                                        <v-text-field
                                            v-model="item.dent"
                                            type="number"
                                            density="compact"
                                            hide-details
                                            class="w-50"
                                            variant="outlined"
                                        ></v-text-field>
                                    </div>
                                </div>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" class="py-0 px-2">
                        <v-divider class="d-block d-md-none d-xl-none d-lg-none"/>
                        <v-list shaped class="pa-0">
                            <div class="d-flex align-center justify-space-between mr-2 ml-2 my-2">
                                <div density="compact" class="mr-1 font-weight-bold">Scratchs</div>
                                <div density="compact" class="font-weight-bold">{{ calculateTotalScratches() }}</div>
                            </div>
                            <v-list-item v-for="(item, i) in damageCountModel.lists" :key="i" density="compact" class="pr-0 pl-2">
                                <template #prepend>
                                    <v-icon :color="item.color" size="15" class="ma-0 pa-0">{{ item.icon }}</v-icon>
                                </template>
                                <div class="d-flex align-center justify-space-between w-100">
                                    <div density="compact" v-text="item.text" class="mr-2"></div>
                                    <div class="d-flex justify-end w-50">
                                        <v-text-field
                                            v-model="item.scratch"
                                            type="number"
                                            density="compact"
                                            hide-details
                                            class="w-50"
                                            variant="outlined"
                                        ></v-text-field>
                                    </div>
                                </div>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-end pa-4">
                <v-btn color="primary" variant="outlined" @click="closeModal">Cancel</v-btn>
                <v-btn color="primary" @click="save">Save</v-btn>
            </v-card-actions>
        </v-card>
    </common-modal>
</template>

<script>
import { mapActions } from 'vuex';

import { CommonModal } from "@/components/common"

export default {
    name: 'DamageCountModal',
    components: {
        CommonModal
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        selectedPanel: {
            type: Object,
            default: () => null
        },
        scanId: {
            type: String,
            required: true
        },
        measures: {
            type: Object,
        }
    },
    emits: ['update:modelValue', 'save'],
    data: () => ({
        show: false,
        damageCountModel: {
            dents: 0,
            lists: [
                { text: 'Oversize', icon: 'mdi-checkbox-blank', color: '#561329', dent: 0, scratch: 0 },
                { text: 'Severe', icon: 'mdi-checkbox-blank', color: '#81182E', dent: 0, scratch: 0},
                { text: 'Heavy', icon: 'mdi-checkbox-blank', color: '#C1484E', dent: 0, scratch: 0 },
                { text: 'Medium', icon: 'mdi-checkbox-blank', color: '#CC9188', dent: 0, scratch: 0 },
                { text: 'Light', icon: 'mdi-checkbox-blank', color: '#D8D1C1', dent: 0, scratch: 0 },
            ]
        }
    }),
    watch: {
        selectedPanel: {
            handler() {
                this.updateDamageCounts();
            },
            immediate: true,
        },
        measures: {
            handler() {
                this.updateDamageCounts();
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions(['updateEditDamageCount']),

        updateDamageCounts() {
            if (!this.measures || !this.selectedPanel || !this.selectedPanel.carPanel) {
                console.warn('Measures or selectedPanel is missing.');
                return;
            }

            const dents = this.measures.dents?.[this.selectedPanel.carPanel] || {};
            const scratchs = this.measures.scratchs?.[this.selectedPanel.carPanel] || {};

            this.damageCountModel.lists.forEach((item) => {
                item.dent = dents[item.text.toLowerCase()]?.count || 0;
                item.scratch = scratchs[item.text.toLowerCase()]?.count || 0;
            });
        },

        calculateTotalDents() {
            return this.damageCountModel.lists.reduce((sum, item) => sum + Number(item.dent), 0);
        },
        calculateTotalScratches() {
            return this.damageCountModel.lists.reduce((sum, item) => sum + Number(item.scratch), 0);
        },
        closeModal() {
            this.show = false;
            this.$emit('update:modelValue', false);
        },
        formatPayload() {
            const dents = {};
            const scratchs = {};

            this.damageCountModel.lists.forEach(item => {
                dents[item.text.toLowerCase()] = Number(item.dent);
                scratchs[item.text.toLowerCase()] = Number(item.scratch);
            });

            return {
                scan_id: this.scanId,
                car_panel: this.selectedPanel.carPanel,
                dents,
                scratchs
            };
        },
      async save() {
        try {
          const payload = this.formatPayload();
          await this.updateEditDamageCount(payload);

          this.$emit('save', { panel: this.selectedPanel, payload: payload });

          this.closeModal();
        } catch (error) {
          console.error('Error updating damage count:', error);
        }
      }
    }
}
</script>
