<template>
  <v-col cols="12" class="pt-0 px-0">
    <v-row class="ma-0 my-3">
      <v-chip :color="color" variant="flat" class="mb-3" label :style="`color: ${textColor}`">
        <v-icon start size="x-large" class="ml-n2"> mdi-label</v-icon>
        Add Parts
      </v-chip>
      <v-col cols="12" class="px-0">
        <v-combobox v-model="model" :filter="filter" :hide-no-data="!search" :items="items" item-title="text"
          v-model:search="search" hide-selected variant="solo" label="Add Parts" hide-details="auto" multiple chips
          persistent-hint @update:modelValue="partsChange">
          <template v-slot:prepend-item>
              <v-list-item class="header-item">
                  <v-list-item-title class="text-capitalize text-13 text-dark">Select parts or create one</v-list-item-title>
              </v-list-item>
          </template>
          <template v-slot:no-data>
            <v-list-item class="part-items">
                <v-list-item-title class="d-flex">
                  <span class="subheading gap-4">Create</span>
                  <v-chip color="grey-lighten-3" variant="flat" size="small">{{ search }}</v-chip>
                </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:chip="{ props, item, selected }">
            <v-chip v-bind="props" color="grey-lighten-3" variant="flat"
                    :input-value="selected" label size="small">
              <span class="pr-2 text-capitalize">{{ item.title }}</span>
              <v-icon size="small" @click="removeItem(item)"> $delete</v-icon>
            </v-chip>
          </template>
          <template v-slot:item="{ index, item }">
            <v-row dense class="d-flex w-100 align-center justify-space-between part-items" @click="selectChip(item)"
                   style="cursor: pointer;">
              <v-col cols="10" class="d-flex align-center align-self-stretch">
                <v-text-field class="w-full background-transparent pa-5 pt-0 pb-0 mt-n3"
                              v-if="editing === item" v-model="editing.title" autofocus variant="plain"
                              hide-details @keyup.enter="edit(index, item)" @keydown.stop="handleKeydown">
                </v-text-field>
                <v-list-item-title v-else class="ml-5">
                  <v-chip :color="item.color ? `${item.color}-lighten-3` : '#555555'" variant="flat" label
                          density="comfortable" size="small" class="text-capitalize">
                    {{ item.title }}
                  </v-chip>
                </v-list-item-title>
                <v-spacer></v-spacer>
              </v-col>
              <v-col cols="2" class="d-flex align-center justify-end align-self-stretch">
                <v-list-item-action @click.stop>
                  <v-btn class="mt-n" @click.stop.prevent="edit(index, item)" variant="text" :icon="editing !== item ? 'mdi-pencil' : 'mdi-check'">
                  </v-btn>
                </v-list-item-action>
              </v-col>
            </v-row>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: {
    parts: {
      type: Array,
      default: () => []
    },
    lists: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: 'primary'
    },
    textColor: {
      type: String
    }
  },
  emit: ['partsUpdate'],
  data() {
    return {
      activator: null,
      attach: null,
      editing: null,
      editingIndex: -1,
      nonce: 1,
      menu: false,
      model: [],
      x: 0,
      search: null,
      items: []
    };
  },
  watch: {
    lists(newValue) {
      if (newValue) {
        this.model = newValue.map(function (item) {
          if (typeof item == 'object' && item.hasOwnProperty('value')) {
            item = item.value;
          }

          return { text: item, value: item };
        });
      }
    },
    parts(newValue) {
      if (newValue.length) {
        this.items = [...this.items, ...this.parts];
      }
    },
  },
  methods: {
    edit (index, item) {
      if (!this.editing) {
        this.editing = item
        this.editingIndex = index
      } else {
        const editedTitle = this.editing.title.trim();
        if (editedTitle) {
          this.updateItems(index, editedTitle);
        }
        this.editing = null
        this.editingIndex = -1
      }
    },
    updateItems(index, newTitle) {
      const oldTitle = this.items[index].title;
      const modelIndex = this.model.findIndex(
        (v) => v.title === oldTitle || v === oldTitle
      );
      if (modelIndex > -1) {
        this.model.splice(modelIndex, 1, { text: newTitle, value: newTitle.toLowerCase() });
      }
      this.items.splice(index, 1, { text: newTitle, value: newTitle.toLowerCase() });
    },
    handleKeydown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    selectChip(item) {
      if (!this.model.includes(item.title)) {
        this.model.push(item.title);
        this.partsChange()
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : '');

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return text
        .toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1;
    },
    removeItem(item) {
      const itemIndex = this.model.findIndex((v) =>
        typeof v === 'string' ? v === item.title : v.text === item.title
      );

      if (itemIndex > -1) {
        this.model.splice(itemIndex, 1);
        if (!this.items.some(i => i.text === item.title)) {
          this.items.push({ text: item.title, value: item.title });
        }
      }

      this.partsChange();
    },
    partsChange() {
      const parts = this.model.map(function (item) {
        if (typeof item == 'object' && item.hasOwnProperty('value')) {
          item = item.value;
        }

        return item;
      });
      this.$emit('partsUpdate', parts);
    },
  }
}
</script>

<style scoped>
.part-items:hover {
  background-color: #f6f6f6 !important;
}
</style>
