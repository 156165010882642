import panelgroups from '@/store/modules/panel-groups/api';

export default {
    getPanelGroupLists({ commit }) {
        commit("setPanelGroupsListLoading", true);
        return new Promise((res, rej) => {
            panelgroups.panelGroupsList().then(response => {
                commit("setPanelGroupsList", response);
                res(response);
            }).catch((error) => {
                commit("setPanelGroupsListLoading", false);
                rej(error)
            });
        });
    },
    getPanelGroupPositions({ commit }) {
        commit("setPanelGroupsPosLoading", true);
        return new Promise((res, rej) => {
            panelgroups.panelGroupPositions().then(response => {
                commit("setPanelGroupsPosition", response);
                res(response);
            }).catch((error) => {
                commit("setPanelGroupsPosLoading", false);
                rej(error)
            });
        });
    },
    getSelectedPanelGroup({ commit }, id) {
        commit("setPanelGroupLoading", true);
        return new Promise((res, rej) => {
            panelgroups.panelGroupModel(id).then(response => {
                commit("setPanelGroupLoading", false);
                res(response);
            }).catch((error) => {
                commit("setPanelGroupLoading", false);
                rej(error)
            });
        });
    },
    createPanelGroup({ commit }, data) {
        commit("setPanelGroupLoading", true);
        return new Promise((res, rej) => {
            panelgroups.createPanelGroupModel(data).then(response => {
                commit("setPanelGroupLoading", false);
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Panel Group Created Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setPanelGroupLoading", false);
                rej(error)
            });
        });
    },
    updateSelectedPanelGroup({ commit }, data) {
        commit("setPanelGroupLoading", true);
        return new Promise((res, rej) => {
            panelgroups.updatePanelGroupModel(data.id, data.data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Panel Group Updated Successfully",
                    color: "success",
                });
                commit("setPanelGroupLoading", false);
                res(response);
            }).catch((error) => {
                commit("setPanelGroupLoading", false);
                rej(error)
            });
        });
    },
    deletePanelGroup({ commit }, PanelGroupId) {
        return new Promise((res, rej) => {
            panelgroups.deletePanelGroupModel(PanelGroupId).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Panel Group Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    }
}