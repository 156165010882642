<template>
  <v-row id="update-profile-page">
    <v-col cols="12" class="mt-n13">
        <v-card>
            <v-row class="ma-0 py-3 align-center justify-center">
                <v-card-title class="text-h5 text-primary font-weight-bold d-inline-block pa-0 title-border">
                    Update Profile
                </v-card-title>
            </v-row>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="saveProfile">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" lg='6'>
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">First Name</v-list-subheader>
                            <v-text-field  variant="outlined" ref="first_name" hide-details="auto" v-model="model.first_name" :error-messages="v$.model.first_name.$errors.map(e => e.$message)"
                                placeholder="First Name" @update:model-value="v$.model.first_name.$touch()"  @blur="v$.model.first_name.$touch()" required/>
                        </v-col>
                        <v-col cols="12" lg='6'>
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Last Name</v-list-subheader>                     
                            <v-text-field variant="outlined" ref="last_name" hide-details="auto" v-model="model.last_name" :error-messages="v$.model.last_name.$errors.map(e => e.$message)"
                                placeholder="Last Name" @update:model-value="v$.model.last_name.$touch()"  @blur="v$.model.last_name.$touch()" required/>
                        </v-col>
                    </v-row>

                    <v-col cols="12">
                        <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">E-mail</v-list-subheader> 
                        <v-text-field variant="outlined" v-model="model.email" hide-details="auto" :error-messages="v$.model.email.$errors.map(e => e.$message)"
                            placeholder="E-mail" ref="email" @update:model-value="v$.model.email.$touch()"  @blur="v$.model.email.$touch()" required />
                    </v-col>
                    
                    <v-col cols="12">
                        <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Password</v-list-subheader> 
                        <v-text-field  variant='outlined' ref="password" hide-details="auto" :type="show ? 'text' : 'password'" :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            v-model="model.password" :error-messages="v$.model.password.$errors.map(e => e.$message)"
                            placeholder="Password" @click:append="show = !show" required />
                    </v-col>

                    <div class="d-block d-lg-flex justify-end my-3">
                        <v-btn type="submit" color="primary">
                            update profile
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useHead } from '@vueuse/head'; 

import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";

export default {
  name: "updateProfile",
  setup() {
    useHead({
      title: "Update Profile",
    })
  },
  data: (vm) => ({
    show: false,
    model: vm.$helpers.getCurData('curUser') || {},
    v$: useVuelidate(),
  }),
  validations: {
     model: {
        first_name: { required},
        last_name: { required},
        email: { email, required},
        password: { required},
    }
  },
  methods: {
    saveProfile() {
        this.v$.$touch();
        if (this.v$.$invalid) {
            this.$helpers.focusErrorElement(this.v$.model, this.$refs);
            return;
        }
        // this.updateUserData(this.model).then((response) => {
        //     if (!response || !response.data) {
        //         return;
        //     }
        this.model.name = `${this.model.first_name} ${this.model.last_name}`;
        this.$helpers.lsPush("curUser", this.model);
        this.$store.commit("snackbar/SHOW_MESSAGE", {
            text: "Profile Updated Successfully",
            color: "success",
        });
        this.$router.push('/');
        // }).catch(() => {});
    }
  }
}
</script>

<style>

</style>