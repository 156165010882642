import appsettings from '@/store/modules/app-settings/api';

export default {
    getUserOptions({ commit }, data) {
        commit("setSelectAppSettingLoading", true);
        return new Promise((resolve, reject) => {
            appsettings.userOptions(data).then(response => {
                commit("setSelectAppSettingData", response);
                commit("setSelectAppSettingLoading", false);
                resolve(response);
            }).catch(error => {
                commit("setSelectAppSettingLoading", false);
                reject(error);
            });
        });
    },
    updateOptionsData({ commit }, { id, data }) {
        commit("setSelectAppSettingLoading", true);
        return new Promise((resolve, reject) => {
            appsettings.updateUserOptions(id, data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Setting Updated Successfully",
                    color: "success",
                }, { root: true });
                commit("setSelectAppSettingLoading", false);
                resolve(response);
            }).catch(error => {
                commit("setSelectAppSettingLoading", false);
                reject(error);
            });
        });
    },

    getOption({ commit }, { id, data }) {
        commit("setSelectAppSettingLoading", true);
        return new Promise((resolve, reject) => {
            appsettings.getOption(id, data).then(response => {
                commit("setSelectAppSettingData", response);
                commit("setSelectAppSettingLoading", false);
                resolve(response);
            }).catch(error => {
                commit("setSelectAppSettingLoading", false);
                reject(error);
            });
        });
    },
}