<template>
    <div>
        <h3 class="text-center text-uppercase"> New scans </h3>
        <v-row class="overflow-y-auto" style="max-height: 400px">
            <template v-if="loading">
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" v-for="(loop, i) in times" :key="'skeleton'+i">
                    <v-card elevation="2" width="100%">
                        <v-skeleton-loader :loading="true" elevation="2" type="table-heading, list-item-two-line, image, table-tfoot"></v-skeleton-loader>
                    </v-card>
                </v-col>
            </template>
            <template v-if="!loading && newScans.length">
                <v-col cols="12" sm="6" md="4" lg="3" v-for="(item) in newScans" :key="item.id">
                    <v-card width="100%">
                    <v-toolbar :color="model.primary_color" variant="flat" :style="`color: ${model.text_color}`" class="scan-title-bar" elevation="3">
                        <v-toolbar-title class="text-section-title">Car Details</v-toolbar-title>
                    </v-toolbar>
                    <v-row class="ma-0 mt-2 pa-2">
                        <v-col cols="12" sm="5" md="6" lg="5" xl="6" class="font-weight-bold text-break">
                        Registration:
                        </v-col>
                        <v-col cols="12" sm="7" md="6" lg="7" xl="6">
                        {{ item.licensePlate || 'Nil' }}
                        </v-col>
                        <v-col cols="12" sm="5" md="4" lg="5" xl="6" class="font-weight-bold text-break">
                        Scan Time:
                        </v-col>
                        <v-col cols="12" sm="7" md="8" lg="7" xl="6">
                        {{ item.timestamp ? $helpers.getMomentDatas('DD-MM-YYYY hh:mm A', item.timestamp) : 'Nil'}}
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center align-center">
                            <v-btn :color="model.primary_color"
                                :style="`color: ${model.text_color}`"
                                @click="$router.push(`/customer-portal/${agentId}/${item.id}`)">View Scan</v-btn>
                        </v-col>
                    </v-row>
                    </v-card>
                </v-col>
            </template>

            <template v-if="!loading && (!newScans || !newScans.length)">
                <v-col cols="12" class="text-center pa-16">
                    New Scans Not Available!.
                </v-col>
            </template>
        </v-row>

        <h3 class="text-center text-uppercase my-5"> Completed scans </h3>
        <v-row class="overflow-y-auto" style="max-height: 400px">
            <template v-if="loading">
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" v-for="(loop, i) in times" :key="'skeleton1'+i">
                    <v-card elevation="2" width="100%">
                        <v-skeleton-loader :loading="true" elevation="2" type="table-heading, list-item-two-line, image, table-tfoot"></v-skeleton-loader>
                    </v-card>
                </v-col>
            </template>
            <template v-if="!loading && completedScans.length">
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" v-for="(item) in completedScans" :key="item.id">
                    <v-card width="100%">
                        <v-toolbar :color="model.primary_color" :style="`color: ${model.text_color}`" class="scan-title-bar" elevation="3">
                            <v-toolbar-title class="text-section-title"> Car Details</v-toolbar-title>
                        </v-toolbar>
                        <v-row class="ma-0 mt-2 pa-2">
                            <v-col cols="12" sm="5" md="6" lg="5" xl="6" class="font-weight-bold text-break">
                                Registration:
                            </v-col>
                            <v-col cols="12" sm="7" md="6" lg="7" xl="6">
                                {{ item.licensePlate || 'Nil' }}
                            </v-col>
                            <v-col cols="12" sm="5" md="4" lg="5" xl="6" class="font-weight-bold text-break">
                                Scan Time:
                            </v-col>
                            <v-col cols="12" sm="7" md="8" lg="7" xl="6">
                                {{ item.timestamp ? $helpers.getMomentDatas('DD-MM-YYYY hh:mm A', item.timestamp) : 'Nil'}}
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center align-center">
                                <v-btn :color="model.primary_color" @click="$router.push(`/customer-portal/${agentId}/${item.id}`)"
                                    :style="`color: ${model.text_color}`">View Scan</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </template>
            <template v-if="!loading && (!completedScans || !completedScans.length)">
                <v-col cols="12" class="text-center pa-16">
                    Completed Scans Not Available!.
                </v-col>
            </template>
        </v-row>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';

export default {
    props: {
        model: {
            type: Object,
            default: () => {}
        }
    },
    data: (vm) => ({
        agentId: vm.$route.params.agentId,
        lists: [],
        loading: false,
        newScans: [],
        completedScans: []
    }),
    computed: {
        times() {
            return Array(5).fill(); // Replace 5 with the desired number of iterations
        }
    },
    watch: {
        $route(newVal) {
            this.agentId = newVal.params.agentId;
            this.getScansList();
        },
        lists(newVal) {
            this.scanListUpdate(newVal);
        }
    },
    created() {
        this.getScansList();
        this.wsScanData();
        if(this.lists.length) this.scanListUpdate();
    },
    methods: {
        ...mapActions(['getScanLists']),

        scanListUpdate(list) {
            if(list.length) {
                this.newScans = _.filter(list, { has_quote: false });
                this.completedScans =_.filter(list,  { has_quote: true });
            } else {
                this.newScans = this.completedScans = [];
            }
        },
        getScansList() {
            this.loading = true;

            this.getScanLists({params: { agent_id: this.agentId, ago: '48h' } }).then(res => {
                this.lists = res.data || [];
                this.loading = false;
            }).catch(err => {
                this.lists = [];
                this.loading = false;
            });
        },
        wsScanData() {
            Echo.channel(`agent.${this.agentId}.scans`).listen('.saved', (scan) => {
                if(scan.has_quote) {
                    const scanIndex = this.newScans.findIndex(item => item.id === scan.id);
                    if(scanIndex !== -1) this.newScans.splice(scanIndex, 1);
                    this.completedScans.unshift(scan);
                } else {
                    this.newScans.unshift(scan);
                }
            });
        }
    },
    beforeUnmount() {
        Echo.leaveChannel(`agent.${this.agentId}.scans`);
    }
}
</script>

<style lang="scss" scoped>

</style>
