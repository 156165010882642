<template>
  <div id="app-settings-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" divider=">" class="pl-0 config-breadcrumbs mt-n15">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="!item.disabled && $router.push(item.href)"
          :class="breadcrumbList.length > 1 && item.disabled ? 'text-secondary' : 'text-primary'">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <app-setting-form v-if="appSettingId" />
    <app-setting-home v-else />
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';

import AppSettingHome from './components/AppSettingHome.vue';
import AppSettingForm from './components/AppSettingForm.vue';
import { mapActions } from 'vuex';

export default {
  name: "AppSettings",
  setup() {
    useHead({
      title: "AppSettings",
    })
  },
  components: {
    AppSettingForm,
    AppSettingHome,
  },
  data: (vm) => ({
    appSettingId: vm.$route.params.appSettingId,
    breadcrumbList: [{
      text: 'App Settings',
      disabled: true,
      href: '/app-settings',
    }]
  }),
  watch: {
    $route(newVal) {
      this.appSettingId = newVal.params.appSettingId;
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
    this.getUserOptions();

  },
  methods: {
    ...mapActions(['getUserOptions']),
    setBreadCrumb() {
      if (this.appSettingId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: "Edit Setting",
          disabled: true,
          href: `/app-settings/${this.appSettingId}`,
        })
      } else {
          this.breadcrumbList = [{
          text: 'App Settings',
          disabled: true,
          href: '/app-settings',
        }]
      }
    }
  }
};
</script>
