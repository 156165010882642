<template>
    <v-row class="ma-0">
            <v-chip color="primary" variant="flat" class="mb-3" label text-color="white">
                <v-icon size="20" class="ml-n2" start> mdi-label</v-icon>Add Products
            </v-chip>
        <v-col cols="12">
            <v-card>
                <v-table>
                    <template v-slot:default>
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-center text-uppercase text-white">Product</th>
                                <th class="text-center text-uppercase text-white">Price ($)</th>
                                <th class="text-center text-uppercase text-white">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="quoteDetailLoading">
                                <td colspan="3" class="pa-0">
                                    <v-skeleton-loader :loading="true" class="w-full"
                                        type="table-heading, list-item-two-line, table-tfoot"
                                    ></v-skeleton-loader>
                                </td>
                            </tr>
                            <template v-if="!quoteDetailLoading && products.length">
                                <tr v-for="(prod, index) in products" :key="index">
                                    <td class="text-left pa-0 pa-3">
                                        <v-autocomplete v-model="products[index]" :key="index+1" :items="productsList" item-title="text" item-value="value"
                                            density="compact" variant="outlined" hide-details="auto" placeholder="Select Product"
                                            :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                                            :rules="selectRules" return-object  @update:model-value="changeProduct">
                                        </v-autocomplete>
                                    </td>
                                    <td class="text-center pa-0 pa-3">
                                        <v-text-field :key="index+1" class="box-shadow-none input-align-center px-0" type="number" min="0" solo
                                            v-model.number="products[index].price" density="compact" variant="outlined" hide-details="auto" placeholder="0"/>
                                    </td>
                                    <td>
                                        <v-btn :key="index+1" v-if="products.length !== 1" size="large" icon variant="text" color="error" @click="products.length > 0 && products.splice(index, 1)">
                                            <v-icon dark>mdi-delete-circle</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-else-if="!quoteDetailLoading && products.length === 0">
                                <tr>
                                    <td colspan="3" class="text-center text-caption pa-5">
                                        No Products Data Found!.
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </template>
                </v-table>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-btn density="comfortable" icon="mdi-plus"  color="primary" class="mr-3" @click="products.push({text: '' , value: '', price: null})">
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: ['lists'],
    emits: ['productsUpdate', 'priceUpdate'],
    data: (vm) => ({
        selectRules: [
            v => v && v.value ? vm.products.filter(item=> item.value == v.value).length === 1 || 'Select other product, Becauase already this product was selected.' : true,
        ],
        products: [],
        productsList: [],
        initalLoading: false
    }),
    computed: {
        ...mapGetters(['quoteDetails', 'quoteDetailLoading'])
    },
    watch:{
        lists(newValue) {
            if (newValue.length && !this.initalLoading) {
                this.products = newValue;
                this.$emit('priceUpdate', this.products.reduce((n, {price}) => n + Number(price), 0));
                this.initalLoading = true;
            }
        },
        quoteDetails(newValue) {
            if(newValue && newValue.client && this.productsList.length == 0) this.productsList = newValue.client.products || [];
        },
    },
    created() {
        if (this.lists.length && !this.initalLoading) {
            this.products = this.lists;
            this.$emit('priceUpdate', this.products.reduce((n, {price}) => n + Number(price), 0));
            this.initalLoading = true;
        }
        if(this.quoteDetails && this.quoteDetails.client) {
            this.productsList = this.quoteDetails.client.products || [];
        }
    },
    methods: {
        changeProduct() {
            const PROD_LIST= this.$helpers.cloneData(this.products);
            PROD_LIST.filter(item=> item.value && item.price);
            if (PROD_LIST.length) {
                this.$emit('productsUpdate', PROD_LIST);
                this.$emit('priceUpdate', PROD_LIST.reduce((n, {price}) => n + Number(price), 0));
            }
        }
    }
}
</script>

<style>

</style>