<template>
  <v-row id="communication-tab-page">
    <v-col cols="12" class="py-0">
        <v-container>
            <v-form lazy-validation @submit.prevent="sendMail">
                <v-row class="ma-0 pa-0">
                    <v-col cols="12" class='text-primary'>
                        <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">To</v-list-subheader>
                        <toMail @updateTo="model.recipients = $event" :error="$helpers.errorMsg('recipients', v$.model.recipients, 'Recipients')"/>
                    </v-col>
                    <v-col cols='12' class='text-primary'>
                        <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Subject</v-list-subheader>
                        <v-text-field ref="subject" variant="outlined" v-model="model.subject" 
                            placeholder="Enter Your Subject" density="comfortable"
                            hide-details="auto" required :error-messages="v$.model.subject.$errors.map(e => e.$message)"/>
                    </v-col>
                    <v-col cols="12" class='text-primary'>
                        <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Template</v-list-subheader>
                        <v-select  v-model="model.template" :items="tempList" item-title="text" variant="outlined" label=""
                                   placeholder="Choose Template" density="comfortable"
                                   :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                                   hide-details="auto" clearable no-data-text="Template Not Available!.">
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Message</v-list-subheader>
                        <div class="send-mail" :class="{'error-border': v$.model.message.$errors.length}">
                            <quill-editor v-model:value="model.message" ref="message" :options="editorOption"/>
                        </div>
                        <div v-if="v$.model.message.$errors.length" class="error-message mt-2 ml-2">
                            Message is required
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="ma-0 mb-3 align-center justify-space-between">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Attachments</v-list-subheader>
                            <v-row class="ma-0 ml-5 align-center justify-start" v-if="attached.length">
                                <v-alert variant="outlined" shaped type="success" color="success" dense class="ma-0">
                                    {{ attached.length }} File Attached!.
                                </v-alert>
                            </v-row>
                            <v-btn icon="mdi-plus" size="small" :color="color" @click="addAttachment" class="text-white p-2">
                                <v-icon dark>{{ `mdi-${toggleSelect ? 'minus' : 'plus'}` }}</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="align-center justify-end mb-2">
                    <v-btn type="submit" :color="color"  class="mr-4 text-white" :loading="loading" :disabled="loading">
                        Send Mail  <v-icon end>mdi-email-arrow-right-outline</v-icon>
                    </v-btn>
                </v-row>
            </v-form>
        </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import toMail from "./toMail.vue";
import { EDITOR_OPTIONS } from '@/constants/editor-options';

export default {
    components: { toMail },
    props: {
        attached: { type: Array, default: [] },
        tempList: { type: Array, default: [] },
        loading: { type: Boolean, default: false },
        el: { type: String, default: null },
        color: {type: String, default: 'primary'}
    },
    emits: [ 'toogleAdd', 'submit'],
    data: (vm) => ({
        model: { recipients: [], subject: '', message: '', attachments:[] },
        editorOption: EDITOR_OPTIONS('Your Message'),
        toggleSelect: false,
        v$: useVuelidate(),
    }),
    validations: {
        model: {
            recipients: { required },
            subject: { required },
            message: { required },
        },
    },
    methods: {
        addAttachment() {
            this.toggleSelect = !this.toggleSelect;
            this.$emit('toogleAdd', this.toggleSelect);
            if (this.toggleSelect && this.el) this.scrollElement();
        },
        scrollElement() {
            const element = document.getElementById(this.el);
            if(element) element.scrollIntoView();
        },
        sendMail() {
            this.model.attachments = this.attached;
            this.v$.$touch();
            if (this.v$.$invalid) {
                this.$helpers.focusErrorElement(this.v$.model, this.$refs);
                return;
            }
            this.$emit('submit', this.model);
        }
    }
}
</script>

<style>

</style>