<template>
    <v-row justify="center">
        <v-dialog :model-value="true" persistent width="auto">
            <v-card class="pa-4 d-flex flex-column">
                <v-row class="align-center justify-center" no-gutters>
                    <v-icon size="70" aria-hidden="false" color="warning" variant="flat">mdi-alert-circle-outline</v-icon>
                </v-row>
                <v-card-title class="d-flex align-center justify-center text-subtitle-1 text-capitalize pt-2">
                    {{title}} ?
                </v-card-title>
                <!-- <v-card-text></v-card-text> -->
                <v-card-actions class="align-center justify-center mt-2">
                    <v-btn elevation="2" small color="primary" variant="outlined" @click="$emit('close')"
                        class="mr-3 text-capitalize" :disabled="loading">
                        No, cancel!
                    </v-btn>
                    <v-btn elevation="2" small color="primary" variant="flat" @click="!loading && $emit('saved')" class="text-capitalize">
                        <span v-if="!loading">Yes{{success || ''}}</span>
                        <span v-else>Loading..</span>
                        <v-progress-circular indeterminate color="white" v-if="loading"
                            size="15" class="ml-2"></v-progress-circular>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    props:['title', 'success', 'loading'],
    emits: ['close', 'saved']
}
</script>

<style>

</style>