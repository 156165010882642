<template>
    <v-dialog v-model="open" max-width="1200" @click:outside="$emit('close')">
        <v-card>
            <v-card-text>
                <div class="ma-0 pt-5 text-center" v-if="!model">
                    No Data Available!.
                </div>
                <div style="width: 100%;" class="pt-4" v-if="model.length">
                    <h4 class="text-decoration-underline" v-if="model[0].position == 'I'">Inner</h4>
                    <div ref="tireLeftGraph" style="width: 100%; min-height: 500px">
                        <v-row class="ma-0 w-100 align-center justify-center" v-if="loading" style="height: 500px">
                            <div class="spinner-design-loader"></div>
                        </v-row>
                    </div>

                    <h4 class="text-decoration-underline" v-if="model.length > 1 && model[1].position == 'O'">Outer</h4>
                    <div ref="tireRightGraph" v-if="model.length > 1" class="pt-4" style="width: 100%; min-height: 500px"> </div>
                </div>
                <div v-else class="pt-5 text-center">
                    Graph Data Not Available!
                </div>
                <div v-if="model.length && model[0].imageFileUrl">
                    <v-lazy>
                        <img width="100%" cover :src="model[0].imageFileUrl" style="max-height: 130px;" crossorigin="anonymous" />
                    </v-lazy>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export default {
    props: ['model', 'conclustion'],
    emits: ['close'],
    data: (vm) => ({
        open: true,
        loading: true,
        chart: '',
    }),
    mounted() {
        const self = this;
        setTimeout(() => {
            self.loading = false;
            if(this.model && this.model[0].coordinates) self.chartInitiated('Left');
            if(this.model.length > 1 && this.model[1].coordinates) self.chartInitiated('Right');
        }, 1000);
    },
    methods: {
        async chartInitiated(ref) {
            // Create chart instance
            let root = am5.Root.new(this.$refs[`tire${ref}Graph`]);
            let seriesData = this.model[ref =='Left' ? 0 : 1].coordinates || [];

            if(seriesData.length > 0) {
              seriesData = [{
                x: seriesData[0].x,
                y: 30
              },
              ...seriesData,
              {
                x: seriesData[seriesData.length - 1].x,
                y: 30
              }];

              // Tire graph Open Value for max
              seriesData.forEach(p => p.openValue = 30);
            }

            //Remove Amchart Logo From Leftside Bottom
            root._logo.dispose();

            // Themes begin
            root.setThemes([am5themes_Animated.new(root)]);

            // Create container chart
            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                layout: root.verticalLayout
            }));

            // Add Axes
            var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
                min: 0,
                renderer: am5xy.AxisRendererX.new(root, {}),
            }));
            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                min: 0,
                max: 40,
                renderer: am5xy.AxisRendererY.new(root, {
                  inversed: true,
                  minGridDistance: 25
                })
            }));

            // Add cursor
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "none"
            }));
            cursor.lineY.set("forceHidden", true);
            const conclusion = this.model[ref =='Left' ? 0 : 1].measurementConclusion;
            // Add series and tooltip
            var tooltip = am5.Tooltip.new(root, {
                getFillFromSprite: false,
                autoTextColor: false,
                labelText: `[bold]Position:[/] {valueX} \n[bold]Depth:[/] {valueY}`
            });
            var series = chart.series.push(am5xy.LineSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "y",
                valueXField: "x",
                openValueYField: "openValue",
                fill: am5.color(0x999999),
                tooltip: tooltip
            }));
            tooltip.get("background").setAll({
                fill: am5.color(0x000000),
                fillOpacity: 0.8
            });
            series.strokes.template.setAll({
              stroke: am5.color(0x000000),
              strokeWidth: 3,
            });
            series.fills.template.setAll({
              fillOpacity: 0.7,
              visible: true
            });
            series.data.setAll(seriesData);

            series.appear(1000);
            // Make stuff animate on load
            chart.appear(1000, 100);

            this.chart = chart;
        }
    },
    beforeUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
