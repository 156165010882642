<template>
  <v-row id="permission-form-page">
    <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="savePermission">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Name</v-list-subheader>
                            <v-text-field  ref="name" v-model="model.name" :error-messages="v$.model.name.$errors.map(e => e.$message)"
                                placeholder="Name" variant="outlined" @update:model-value="v$.model.name.$touch()"  @blur="v$.model.name.$touch()" required/>
                        </v-col>
                        <v-col cols="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Display Name</v-list-subheader>
                            <v-text-field ref="display_name" variant="outlined" v-model="model.display_name" placeholder="Display Name"  required/>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Description</v-list-subheader>
                            <v-textarea hide-details="auto" density="compact" class="mb-3" auto-grow
                                rows="4" v-model="model.description" row-height="20" placeholder="Description" variant="outlined"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="primary"  class="mr-4">
                            Submit
                        </v-btn>
                        <v-btn type="button" variant="outlined" color="primary" class="mr-4" @click="$router.push('/permissions')">
                            Cancel
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions } from 'vuex';

export default {
    data: (vm) => ({
        permissionId: vm.$route.params.permissionId,
        show: false,
        model: {
            name: '',
            display_name: '',
            description: '',
        },
        v$: useVuelidate(),
    }),
    validations: {
        model: {
            name: { required},
        }
    },
    created() {
        if (this.permissionId != 'create') {
          this.getSelectedPermission(this.permissionId).then(response => this.model = response);
        }
    },
    methods: {
        ...mapActions(['getSelectedPermission', 'createPermission', 'updateSelectedPermission']),

        savePermission() {
            this.v$.$touch();
            if (this.v$.$invalid) {
                this.$helpers.focusErrorElement(this.v$.model, this.$refs);
                return;
            }
            if (this.permissionId != 'create') {
                this.updateSelectedPermission(this.model).then(resp => this.$router.push("/permissions"));
            } else {
                this.createPermission(this.model).then(resp => this.$router.push("/permissions"));
            }
        },
    },
};
</script>
