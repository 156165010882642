import users from '@/store/modules/users/api';

export default {
    getSelectedUser({ commit }, id) {
        commit("setUserLoading", true);
        return new Promise((res, rej) => {
            users.userModel(id).then(response => {
                commit("setUserLoading", false);
                res(response);
            }).catch((error) => {
                commit("setUserLoading", false);
                rej(error)
            });
        });
    },
    createUser({ commit }, data) {
        commit("setUserLoading", true);
        return new Promise((res, rej) => {
            users.createUserModel(data).then(response => {
                commit("setUserLoading", false);
                commit("snackbar/SHOW_MESSAGE", {
                    text: "User Created Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setUserLoading", false);
                rej(error)
            });
        });
    },
    updateSelectedUser({ commit }, data) {
        commit("setUserLoading", true);
        return new Promise((res, rej) => {
            users.updateUserModel(data).then(response => {
                if (!response.consent) {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "User Updated Successfully",
                        color: "success",
                    });
                }
                commit("setUserLoading", false);
                res(response);
            }).catch((error) => {
                commit("setUserLoading", false);
                rej(error)
            });
        });
    },
    getUserApiToken({ commit }, id) {
        return new Promise((res, rej) => {
            users.userModel(`${id}/apikey`).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Token Generated Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
    deleteUser({ commit }, userId) {
        return new Promise((res, rej) => {
            users.deleteUserModel(userId).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "User Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    }
}