const state = {
    primary: '',
    color: '',
    Logo: ''
};

const mutations = {
    setPrimaryColor(state, color) {
        state.primary = color;
    },
    setTextColor(state, color) {
        state.color = color;
    },
    setLogo(state, logo) {
        state.Logo = logo;
    },
    resetToDefault(state) {
        state.primary = '';
        state.color = '';
        state.Logo = '';
    }
};

const actions = {
    updatePrimaryColor({ commit }, color) {
        commit('setPrimaryColor', color);
    },
    updateTextColor({ commit }, color) {
        commit('setTextColor', color);
    },
    updateLogo({ commit }, logo) {
        commit('setLogo', logo);
    },
    resetToDefault({ commit }) {
        commit('resetToDefault');
    }
};

const getters = {
    primary: (state) => state.primary,
    color: (state) => state.color,
    getLogo: (state) => state.Logo
};

export default {
    state,
    mutations,
    actions,
    getters
};