<template>
  <div id="clients-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" divider=">" class="pl-0 config-breadcrumbs mt-n15">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="!item.disabled && $router.push(item.href)"
          :class="breadcrumbList.length > 1 && item.disabled ? 'text-secondary' : 'text-primary'">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <client-form v-if="clientId" />
    <client-home v-else />
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';

import clientForm from "../clients/ClientForm.vue";
import clientHome from "../clients/ClientHome.vue";

export default {
  name: "Clients",
  setup() {
    useHead({
      title: "Clients",
    })
  },
  components: {
    clientForm,
    clientHome,
  },
  data: (vm) => ({
    clientId: vm.$route.params.clientId,
    breadcrumbList: [{
      text: 'Clients',
      disabled: true,
      href: '/clients',
    }]
  }),
  watch: {
    $route(newVal) {
      this.clientId = newVal.params.clientId;
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.clientId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.clientId == 'create' ? 'Create' : 'Update'} Client`,
          disabled: true,
          href: `/clients/${this.clientId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Clients',
          disabled: true,
          href: '/clients',
        }]
      }
    }
  }
};
</script>
