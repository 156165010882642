import clients from '@/store/modules/clients/api';

export default {
    getClientLists({ commit }) {
        commit("setClientsListLoading", true);
        return new Promise((res, rej) => {
            clients.clientsList().then(response => {
                commit("setClientsList", response);
                res(response);
            }).catch((error) => {
                commit("setClientsListLoading", false);
                rej(error)
            });
        });
    },
    getPDRLists({ commit }) {
        commit("setPDRlistLoading", true);
        return new Promise((res, rej) => {
            clients.pdrList().then(response => {
                commit("setPDRlists", response);
                res(response);
            }).catch((error) => {
                commit("setPDRlistLoading", false);
                rej(error)
            });
        });
    },
    getClient({ commit }, params) {
        commit("setClientLoading", true);
        return new Promise((res, rej) => {
            clients.clientModel(params).then(response => {
                commit("setClientLoading", false);
                res(response);
            }).catch((error) => {
                commit("setClientLoading", false);
                rej(error)
            });
        });
    },
    createClient({ commit }, data) {
        commit("setClientLoading", true);
        return new Promise((res, rej) => {
            clients.createClientModel(data).then(response => {
                commit("setClientLoading", false);
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Client Created Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setClientLoading", false);
                rej(error)
            });
        });
    },
    updateSelectedClient({ commit }, data) {
        commit("setClientLoading", true);
        return new Promise((res, rej) => {
            clients.updateClientModel(data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Client Updated Successfully",
                    color: "success",
                });
                commit("setClientLoading", false);
                res(response);
            }).catch((error) => {
                commit("setClientLoading", false);
                rej(error)
            });
        });
    },
    deleteClient({ commit }, clientId) {
        return new Promise((res, rej) => {
            clients.deleteClientModel(clientId).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Client Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
    calculateCategoryClient({ commit }, payload) {
        console.log(`payload`, payload)

        commit("setClientLoading", true);
        return new Promise((res, rej) => {
            clients.calculateCategoryClientModel(payload).then(response => {
                commit("setClientLoading", false);
                res(response);
            }).catch((error) => {
                commit("setClientLoading", false);
                rej(error)
            });
        });
    },
}
