import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import 'vuetify/styles';

import { themePreset } from '@/themeConfig';

// Create a Vuetify instance
const vuetify = createVuetify({
  components,
  directives,
  theme: themePreset.theme,
  rtl: themePreset.rtl,
});

export default vuetify;
