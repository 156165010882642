<template>
    <v-col cols="12" lg="3">
        <template v-if="quoteDetailLoading">
            <v-row v-for="(load, index) in [1, 2, 3]" :key="load+index">
                <v-col col="12" class="quote-skeleton">
                    <v-skeleton-loader :loading="true" type="table-heading, list-item-two-line, image, table-tfoot"></v-skeleton-loader>
                </v-col>
            </v-row>
        </template>
        <div class="quote-md-grid" v-if="!quoteDetailLoading">
            <v-card class="mb-5">
                <v-toolbar color="primary" dark class="scan-title-bar" elevation="3">
                    <v-toolbar-title class="text-section-title">Quote Summary</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <h5 class="font-weight-bold justify-center text-black text-center">
                        Total {{ currencyFormat(totalModel?.grand ?? 0.00) }}
                    </h5>
                    <v-row class="flex-column ma-0 align-center justify-center">
                        <h5 class="pa-0 text-black font-weight-regular">
                            Products: {{ currencyFormat(productPrice ?? 0.00) }}
                        </h5>
                    </v-row>
                    <v-divider class="my-3"/>
                    <v-row class="flex-column ma-0 align-center justify-center">
                        <h5 class="pa-0 mb-1 text-black font-weight-regular">
                            PDR: {{ currencyFormat(totalModel?.pdr ?? 0.00) }}
                        </h5>
                        <h5 class="pa-0 mb-1 text-black font-weight-regular">
                            Conventional: {{ currencyFormat(totalModel?.conventional ?? 0.00) }}
                        </h5>
                        <h5 class="pa-0 mb-1 text-black font-weight-regular">
                            Replace / Paint: {{ currencyFormat(totalModel?.replace ?? 0.00) }}
                        </h5>
                        <h5 class="pa-0 text-black font-weight-regular">
                            Push to Paint: {{ currencyFormat(totalModel?.p2p ?? 0.00) }}
                        </h5>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card class="mb-5">
                <v-toolbar color="primary" dark class="scan-title-bar" elevation="3">
                    <v-toolbar-title class="text-section-title">Scan Details</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row class="ma-0">
                        <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="header-label font-weight-bold text-break">
                            <h6 class="pa-0 mb-1 text-black font-weight-regular">Registration:</h6>
                        </v-col>
                        <v-col cols="12" sm="8" md="8" lg="8" xl="8" class="content-detail">
                            {{ scanModel && scanModel.licensePlate ? scanModel.licensePlate : '-' }}
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="header-label font-weight-bold text-break">
                            <h6 class="pa-0 mb-1 text-black font-weight-regular">Date/Time:</h6>
                        </v-col>
                        <v-col cols="12" sm="8" md="8" lg="8" xl="8" class="content-detail">
                            {{ scanModel && scanModel.timestamp ? $helpers.getMomentDatas('DD-MM-YYYY hh:mm A', scanModel.timestamp) : '-' }}
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="header-label font-weight-bold text-break">
                            <h6 class="pa-0 mb-1 text-black font-weight-regular">Agent:</h6>
                        </v-col>
                        <v-col cols="12" sm="8" md="8" lg="8" xl="8" class="content-detail">
                            {{ scanModel && scanModel.agent_name ? scanModel.agent_name : '-' }}
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="header-label font-weight-bold text-break">
                            <h6 class="pa-0 mb-1 text-black font-weight-regular">Client:</h6>
                        </v-col>
                        <v-col cols="12" sm="8" md="8" lg="8" xl="8" class="content-detail">
                            {{ scanModel && scanModel.client_name ? scanModel.client_name : '-' }}
                        </v-col>
                    </v-row>
                    <v-card-actions class="justify-center">
                        <router-link :to="`/scans/${scanId}`">
                            <v-btn variant="outlined" color="primary">
                                VIEW FULL SCAN DATA
                            </v-btn>
                        </router-link>
                    </v-card-actions>
                </v-card-text>
            </v-card>
            <v-card v-if="scanModel && scanModel.pdf_url">
                <v-toolbar color="primary" dark class="scan-title-bar" elevation="3">
                    <v-toolbar-title class="text-section-title">Vehicle Damage Report</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row class="ma-0 cursor-pointer align-center justify-center" @click="openPDF()">
                        <v-icon size="80" color="error darken-2">
                            mdi-file-pdf-box
                        </v-icon>
                        <div class="ml-2 text-h6 font-weight-medium text-break">
                            View PDF
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
    </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props:['totalModel', 'productPrice'],
    computed: {
        ...mapGetters(['quoteDetails', 'quoteDetailLoading'])
    },
    data: (vm) => ({
        scanId: vm.$route.params.quoteScanId,
        scanModel: null,
        currencyName: '$',
    }),
    watch: {
       quoteDetails(newVal) {
            if (newVal && newVal.scanInfo) this.scanModel = newVal.scanInfo;
            else this.scanModel = null;
            this.currencyName = newVal?.client?.currency || '$';
       }
    },
    created() {
      this.scanModel = this.quoteDetails?.scanInfo ?? null;
      this.currencyName = this.quoteDetails?.client?.currency ?? '$';
    },
    methods: {
        openPDF() {
          window.open(this.scanModel.pdf_url, '_blank');
        },
        currencyFormat(amount) {
          return this.currencyName+' '+Number(amount).toFixed(2)
        }
    }
}
</script>

<style>

</style>
