import panels from '@/store/modules/panels/api';

export default {
    getPanelLists({ commit }) {
        commit("setPanelsListLoading", true);
        return new Promise((res, rej) => {
            panels.panelsList().then(response => {
                commit("setPanelsList", response);
                res(response);
            }).catch((error) => {
                commit("setPanelsListLoading", false);
                rej(error)
            });
        });
    },
    getSelectedPanel({ commit }, id) {
        commit("setPanelLoading", true);
        return new Promise((res, rej) => {
            panels.panelModel(id).then(response => {
                commit("setPanelLoading", false);
                res(response);
            }).catch((error) => {
                commit("setPanelLoading", false);
                rej(error)
            });
        });
    },
    createPanel({ commit }, data) {
        commit("setPanelLoading", true);
        return new Promise((res, rej) => {
            panels.createPanelModel(data).then(response => {
                commit("setPanelLoading", false);
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Panel Created Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setPanelLoading", false);
                rej(error)
            });
        });
    },
    updateSelectedPanel({ commit }, data) {
        commit("setPanelLoading", true);
        return new Promise((res, rej) => {
            panels.updatePanelModel(data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Panel Updated Successfully",
                    color: "success",
                });
                commit("setPanelLoading", false);
                res(response);
            }).catch((error) => {
                commit("setPanelLoading", false);
                rej(error)
            });
        });
    },
    deletePanel({ commit }, panelId) {
        return new Promise((res, rej) => {
            panels.deletePanelModel(panelId).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Panel Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    }
}