<template>
  <div id="quote-index-page">
      <AdvanceFilter @search="searchFilter" @reset="filterData = null;getDataFromApi('refresh')" :loading="loading"
      @remove="searchFilter($event, 'remove')"/>
      <v-card>
          <v-row class="ma-0 pa-3 pb-5 align-center justify-end">
              <v-col cols="7" class="d-flex d-xs-none d-sm-none pa-0">
              </v-col>
              <v-col cols="5" md="5" lg="5" xl="5" class="pa-3">
                  <div class="w-full">
                      <v-text-field v-model.trim="search" append-inner-icon="mdi-magnify" class="pt-0"
                      label="" placeholder="Search Quote" :disabled="loading" hide-details
                      @update:model-value="searchDatatable" variant="underlined">
                      </v-text-field>
                  </div>
              </v-col>
          </v-row>
          <v-data-table-server
              :headers="headers"
              :items="tableItems"
              :items-length="totalItems"
              :loading="loading"
              class="elevation-1"
              hover
              @update:options="options = $event"
          >
              <template v-slot:[`item.date`]="{item, column}">
              {{ item[column.field] ? $helpers.getMomentDatas(column.format, item[column.field]) : ''}}
              </template>
              <template v-slot:[`item.currency`]="{item, column}">
              $ {{ item.totals[column.field] ? (item.totals[column.field]).toFixed(2) : 0}}
              </template>
              <template v-slot:[`item.action`]="{item}">
                  <v-icon size="20" v-if="item.pdf" class="mr-2 mb-1 cursor-pointer" color="error darken-2" @click="downloadPDF(item.pdf)">
                  mdi-file-pdf-box
                  </v-icon>
                  <v-icon size="20" class="mr-2 mb-1 cursor-pointer" color="info" @click="$router.push(`quotes/${item.id}/invoice`)">
                  mdi-file-eye
                  </v-icon>
                  <v-icon size="20" class="mr-2 mb-1 cursor-pointer" color="primary" @click="$router.push(`quotes/${item.scan_id}`)"
                  :id="`quotes/${item.scan_id}`">
                  mdi-pencil
                  </v-icon>
                  <v-icon size="20" class="mr-2 mb-1 cursor-pointer" color="danger" @click="notify=item.id">
                  mdi-trash-can
                  </v-icon>
              </template>
          </v-data-table-server>
          <Notify v-if="notify" @close="notify=null" :loading="deleteLoading" @saved="deleteQuoteItem()"
              title="are you sure" success=", delete it!"/>
      </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';

import { Notify } from "@/components/common";
import AdvanceFilter from "./components/AdvanceFilter.vue";

export default {
components: { Notify , AdvanceFilter},
data() {
  return {
    totalItems: 0,
    tableItems: [],
    search: '',
    filterData: null,
    loading: true,
    options: {},
    notify: null,
    deleteLoading: false,
    headers: [
        { title: "Date/Time", key: "date", field:'created_at', format: 'DD-MM-YYYY hh:mm A', sortable: false },
        { title: 'Registration', key: 'licensePlate'},
        {title: 'PDR', key: 'currency', field:'pdr'},
        {title: 'Conventional', key: 'currency', field:'conventional'},
        {title: 'Replace', key: 'currency', field:'replacement'},
        {title: "Push to Paint", key: 'currency', field:'p2p'},
        {title: "Product", key: 'currency', field:'product'},
        {title: "GST", key: 'currency', field:'tax_percent'},
        {title: 'Grand Total', key: 'currency', field: 'grant_total'},
        {title: 'Action', key: 'action', sortable: false}
    ],
    pageLink: `${window.VUE_APP_MS_URL}quote/quotes?page=1`,
    prevOptions: null,
  }
},
watch: {
  options: {
    handler () {
      this.getDataFromApi();
    },
    deep: true,
  },
},
created() {
  this.searchDatatable = _.debounce(this.searchDatatable, 500);
},
methods: {
  ...mapActions(['getModel', 'deleteQuote']),

  searchDatatable() { this.getDataFromApi('search') },
  getDataFromApi (type) {
    this.loading = true;
    this.getDTDetails(type).then(data => {
      this.tableItems = data.items;
      this.totalItems = data.total;
      this.loading = false;
    }).catch(err => {
      this.tableItems = [];
      this.totalItems = 0;
      this.loading = false;
    });
  },
  getDTDetails (type) {
    return new Promise((resolve, reject) => {
      const { sortBy, page, itemsPerPage } = this.options;
        const sortKey = sortBy.length ? sortBy[0].key : null;
        const sortOrder = sortBy.length ? sortBy[0].order : null;

        const endPoint = new URL(this.pageLink);
        endPoint.searchParams.set("per_page", itemsPerPage);
        endPoint.searchParams.set("page", page);
        if(type) endPoint.searchParams.set("page", 1);
        if(this.filterData) {
          Object.keys(this.filterData).map(key => this.filterData[key] && endPoint.searchParams.set(key, this.filterData[key]));
        }
        if(this.search !== '') {
          endPoint.searchParams.set("q", this.search);
        }
      this.getAPIdata(endPoint, this.options, type).then(resp => {
        let { items, total } = resp;
        if (sortKey && sortOrder) {
          items = items.sort((a, b) => {
            const sortA = a[sortKey];
            const sortB = b[sortKey];

            if (sortOrder === 'desc') {
              if (sortA < sortB) return 1
              if (sortA > sortB) return -1
              return 0
            } else {
              if (sortA < sortB) return -1
              if (sortA > sortB) return 1
              return 0
            }
          });
        }
        this.prevOptions = this.options;
        resolve({
          items,
          total,
        });

      }).catch(err => reject(err));
    });
  },
  getAPIdata(endPoint, options, type) {
    const { page, itemsPerPage } = options;
    return new Promise((resolve, reject) => {
      if (!type && this.prevOptions && this.prevOptions.page == page && this.prevOptions.itemsPerPage == itemsPerPage) {
          resolve({items: this.tableItems, total: this.totalItems});
          return true;
      }
      this.getModel(endPoint.toString()).then(resp => {
        resolve(resp);
      }).catch(err => reject(err));
    });
  },
  searchFilter(data, type) {
    if (type) {
      if (this.filterData && this.filterData[data]) this.filterData[data] = null;
      return;
    }
    this.filterData = data;
    this.getDataFromApi('filter')
  },
  async downloadPDF(file) {
    const response = await this.$http.get(file, {
        responseType: "arraybuffer",
    });
    const blob = new Blob([response.data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "quote.pdf";
    link.click();
},
  deleteQuoteItem() {
    this.deleteLoading = true;   
    this.deleteQuote(this.notify).then(() => {
      this.notify = null;
      this.deleteLoading = false;
      this.getDataFromApi('delete');
    }).catch(err => this.deleteLoading = false);
  }
},
};
</script>
