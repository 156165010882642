<template>
  <v-row id="agent-form-page">
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-form lazy-validation @submit.prevent="saveAgent">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" lg="6">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Name</v-list-subheader>
                <v-text-field v-model="model.name" :error-messages="v$.model.name.$errors.map(e => e.$message)"
                              placeholder="Name" variant="outlined" ref="name" @update:model-value="v$.model.name.$touch()" @blur="v$.model.name.$touch()"
                              required hide-details="auto"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Upload Logo
                  <span v-if="previewLogo" class="mx-2">( Previous uploaded Logo:
                                    <span class="text-info cursor-pointer" @click="viewLogo">{{ previewLogo.name }}</span>)
                                </span>
                </v-list-subheader>
                <v-text-field readonly v-model="model.logo" placeholder="Upload Logo" append-inner-icon="mdi-file-upload" variant="outlined" ref="logo"
                              @click="$refs.file.click()" required hide-details="auto">
                  <template v-slot:append>
                  </template>
                </v-text-field>
                <input type="file" accept="image/*" style="display: none" ref="file" @change="uploadedFile"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Phone</v-list-subheader>
                <v-text-field type="text" v-model="model.phone" :error-messages="v$.model.phone.$errors.map(e => e.$message)"
                              placeholder="Phone" variant="outlined" ref="phone" @update:model-value="v$.model.phone.$touch()" @blur="v$.model.phone.$touch()"
                              required hide-details="auto"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Email</v-list-subheader>
                <v-text-field v-model="model.email" :error-messages="v$.model.email.$errors.map(e => e.$message)"
                              placeholder="Email" variant="outlined" ref="email" @update:model-value="v$.model.email.$touch()" @blur="v$.model.email.$touch()"
                              required hide-details="auto"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">ABN</v-list-subheader>
                <v-text-field v-model="model.abn" :error-messages="v$.model.abn.$errors.map(e => e.$message)"
                              placeholder="ABN" variant="outlined" ref="abn" @update:model-value="v$.model.abn.$touch()" @blur="v$.model.abn.$touch()"
                              required hide-details="auto"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Address</v-list-subheader>
                <v-textarea row-height="15" rows="1" auto-grow v-model="model.address" :error-messages="v$.model.address.$errors.map(e => e.$message)"
                            placeholder="Address" variant="outlined" ref="address" @update:model-value="v$.model.address.$touch()" @blur="v$.model.address.$touch()"
                            required hide-details="auto"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Portal Email Notify</v-list-subheader>
                <v-text-field v-model="model.portal_email_address" :error-messages="v$.model.portal_email_address.$errors.map(e => e.$message)"
                              placeholder="Portal Email" variant="outlined" ref="portal_email_address" @update:model-value="v$.model.email.$touch()" @blur="v$.model.email.$touch()"
                              required hide-details="auto"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Pincode</v-list-subheader>
                <v-text-field v-model="model.pincode" :error-messages="v$.model.pincode.$errors.map(e => e.$message)"
                              placeholder="Pincode" variant="outlined" ref="pincode" @update:model-value="v$.model.pincode.$touch()" @blur="v$.model.pincode.$touch()"
                              required hide-details="auto"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Enable</v-list-subheader>
                <v-switch v-model="model.is_enabled" inset label="" :true-value="1" :false-value="0"></v-switch>
              </v-col>
              <v-col cols="12" lg="6">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Default Client</v-list-subheader>
                <v-select v-model="model.default_client_id" :error-messages="v$.model.default_client_id.$errors.map(e => e.$message)"
                          :items="clientsList" item-title="text" item-value="value" placeholder="Select Default Client" @update:modelValue="v$.model.default_client_id.$touch()" @blur="v$.model.default_client_id.$touch()"
                          variant="outlined" ref="default_client_id" required hide-details="auto"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Primary Color</v-list-subheader>
                <v-color-picker hide-inputs v-model="model.primary_color"></v-color-picker>
              </v-col>
              <v-col cols="12" lg="6">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Text Color</v-list-subheader>
                <v-color-picker hide-inputs v-model="model.text_color"></v-color-picker>
              </v-col>
              <v-col cols="12">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Blurb</v-list-subheader>
                <quill-editor ref="blurb" v-model:value="model.blurb" :options="editorOption" style="max-height: 150px;" />
              </v-col>
            </v-row>
            <div class="d-block d-lg-flex justify-end mt-8">
              <v-btn type="submit" color="primary" class="mr-4" :loading="loading">
                Submit
              </v-btn>
              <v-btn type="button" variant="outlined" color="primary" class="mr-4" :disabled="loading"
                     @click="$router.push('/agents')">
                Cancel
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="mt-16" v-if="agentId != 'create'">
      <webhook-home :agentId="agentId"/>
    </v-col>
  </v-row>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { email, required, helpers } from "@vuelidate/validators";
import {mapActions, mapGetters} from 'vuex';

import WebhookHome from "./WebhookHome.vue";

const number = helpers.regex("serial", /^[0-9]{10}$/);

export default {
  components: {
    WebhookHome,
  },
  data: (vm) => ({
    agentId: vm.$route.params.agentId,
    model: {
      logo: '',
      name: '',
      phone: '',
      email: '',
      address: '',
      portal_email_address: '',
      abn: '',
      is_enabled: 0,
      pincode: '',
      primary_color: null,
      text_color: null,
      blurb: '',
      default_client_id: null
    },
    file: '',
    previewLogo: null,
    loading: false,
    clientOptions: [],
    editorOption: {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'align': [] }],
        ]
      },
      placeholder: `Enter blurb ...`,
    },
    v$: useVuelidate(),
  }),
  validations() {
    return {
      model: {
        name: { required },
        phone: { required },
        email: { email, required },
        address: { required },
        portal_email_address: { email, required },
        abn: { required },
        pincode: { required },
        default_client_id: { required },
      }
    }
  },
  computed: {
    ...mapGetters(['clientsList']),

    clientName() {
        const client = _.find(this.clientsList, item => item.value === this.model.default_client_id);
        return this.model.default_client_id && client ? client.text : null;
    },
  },
  created() {
    if (this.agentId != 'create') {
      this.getSelectedAgent(this.agentId).then(response => {
        if (response && response.logo) {
          this.previewLogo = {
            name: response.name,
            logo: response.logo
          };
          response.logo = '';
        }
        response.primary_color = response.primary_color || '#0c1839';
        response.text_color = response.text_color || '#000000';
        this.model = response;
      });
    }
  },
  methods: {
    ...mapActions(['getSelectedAgent', 'createAgent', 'updateSelectedAgent']),

    viewLogo() {
      const image = [];
      image.push(this.previewLogo.logo);
      this.$viewerApi({
        images: image
      })
    },
    uploadedFile(evt) {
      const files = evt.target.files[0];
      if (files == undefined) this.model.logo = '';
      else {
        this.file = files;
        this.model.logo = files.name;
        this.previewLogo = null;
      }
    },
    saveAgent() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.$helpers.focusErrorElement(this.v$.model, this.$refs);
        return;
      }
      const reqData = { ...this.model };
      if (!this.file) {
        delete reqData.logo;
      }
      const headers = { 'Content-Type': 'application/json' };
      this.loading = true;
      if (this.agentId !== 'create') {
        const updateReq = {
          id: this.model.id,
          data: reqData,
        };
        this.updateSelectedAgent(updateReq, headers).then(resp => {
          this.loading = false;
          this.$router.push("/agents");
        }).catch(err => this.loading = false);
      } else {
        this.createAgent(reqData, headers).then(resp => {
          this.loading = false;
          this.$router.push("/agents");
        }).catch(err => this.loading = false);
      }
    },
  },
};
</script>
