export default {
    setPanelLoading(state, data) {
        state.panelLoading = data;
    },
    setPanelsListLoading(state, data) {
        state.panelsListLoading = data;
    },
    setPanelsList(state, data) {        
        state.panelsList = data;
        state.panelsListLoading = false;
    }
}