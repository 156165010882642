import email from '@/store/modules/email/api';

export default {
    sendMailTo({ commit }, data) {
        return new Promise((res, rej) => {
            email.sendMail(data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: `Mail Send Successfully`,
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error);
            });
        });
    },
    getModelList({ commit }, endPoint) {
        return new Promise((res, rej) => {
            email.getModel(`${endPoint}/lists`).then(response => {
                res(response);
            }).catch((error) => {
                rej(error);
            });
        });
    },
    createOptModel({ commit }, options) {
        return new Promise((res, rej) => {
            email.createModel(options.endPoint, options.data).then(response => {
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
    getOptModel({ commit }, options) {
        return new Promise((res, rej) => {
            email.createModel(options.endPoint, options.data).then(response => {
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
    getEmailTempModel({ commit }, id) {
        return new Promise((res, rej) => {
            email.getModel(`emailTemplates/${id}`).then(response => {
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
    createEmailTempModel({ commit }, data) {
        return new Promise((res, rej) => {
            email.createModel('emailTemplates', data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Email Template Created Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
    updateSelectedEmailTemp({ commit }, data) {
        return new Promise((res, rej) => {
            email.updateModel('emailTemplates', data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Email Template Updated Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
    deleteEmailTemp({ commit }, id) {
        return new Promise((res, rej) => {
            email.deleteModel(`emailTemplates/${id}`).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Email Template Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    }
}