<template>
    <v-expansion-panels class="mb-6">
        <v-expansion-panel>
            <v-expansion-panel-title v-slot="{ open }" @click="openPanel = !openPanel">
                <v-row no-gutters :class="open ? 'px-4' : 'px-2'">
                    <v-col :cols="openPanel ? 12 : 3"
                        :class="openPanel ? 'text-h6 font-weight-bold' : 'd-sm-flex d-lg-flex d-xl-flex font-weight-bold'">
                        {{ openPanel ? 'Advance Filter' : 'Expand to Filter' }}
                    </v-col>
                    <v-col cols="8" class="d-none d-md-flex d-lg-flex d-xl-flex text-secondary">
                        <v-fade-transition leave-absolute>
                            <v-col v-if="!openPanel" cols="12">
                                <v-row class="w-100">
                                    <v-col xs="3" sm="4" md="4" lg="4" xl="4" class="text-start">
                                        Date/Time: {{ dateTime || 'Not set' }}
                                    </v-col>
                                    <v-col xs="3" sm="4" md="4" lg="4" xl="4" class="text-start">
                                        Type: {{ model.type ? typeList.find(item => item.value == model.type).text :
                                        'Not set' }}
                                    </v-col>
                                    <v-col xs="3" sm="4" md="4" lg="4" xl="4" class="text-start">
                                        Status: {{ model.status ? statusList.find(item => item.value ==
                                        model.status).text : 'Not set' }}
                                    </v-col>
                                </v-row>
                                <v-row class="w-100">
                                    <v-col lg="4" xl="4" md="4" sm="4" xs="3" class="text-start">
                                        Agent: {{ agentName || 'Not set' }}
                                    </v-col>
                                    <v-col xs="3" sm="4" md="4" lg="4" xl="4" class="text-start">
                                        Client: {{ clientName || 'Not set' }}
                                    </v-col>
                                    <v-col xs="3" sm="4" md="4" lg="4" xl="4" class="text-start">
                                        Location: {{ locationName || 'Not set' }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-fade-transition>
                    </v-col>
                </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <v-form lazy-validation @submit.prevent="$emit('search', model)">
                    <v-row class="px-5 pb-0">
                        <v-col cols="12" sm="6" md="6" lg="4">
                            <v-menu v-model="menu" transition="scale-transition" :close-on-content-click="false" offset-y>
                                <template v-slot:activator="{ props }">
                                    <div v-bind="props">
                                        <v-tooltip location="top" :disabled="!dateTime">
                                        <template v-slot:activator="{ props }">
                                            <v-text-field density="compact" v-model="dateTime" variant="outlined" placeholder="Date / Time"
                                            prepend-inner-icon="mdi-calendar" readonly hide-details="auto" clearable v-bind="props"
                                            @click:clear="clearDate">
                                            </v-text-field>
                                        </template>
                                        <span>{{ dateTime }}</span>
                                        </v-tooltip>
                                    </div>
                                </template>
                                <DateTimePicker
                                    v-model="modelDateTime"
                                    :initialDate="date"
                                    :modelValue="[model.ts, model.te]"
                                    @close="menu=false"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="2">
                            <v-select density="compact" v-model="model.type" :items="typeList" item-title="text"
                                item-value="value" variant="outlined" placeholder="Select Type"
                                :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                                hide-details="auto" clearable @click:clear="$emit('remove', 'type')">
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="3">
                            <v-select density="compact" v-model="model.status" :items="statusList" item-title="text"
                                item-value="value" variant="outlined" placeholder="Select Status"
                                :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                                hide-details="auto" clearable @click:clear="$emit('remove', 'status')">
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="3">
                            <v-select density="compact" v-model="model.agent_id" :items="agentsList" item-title="text"
                                item-value="value" variant="outlined" placeholder="---Select Agent---"
                                :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                                hide-details="auto" clearable @click:clear="$emit('remove', 'agent_id')"
                                @update:modelValue="model.agent_name = agentName">
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="3">
                            <v-select density="compact" v-model="model.client_id" :items="clientsList" item-title="text"
                                item-value="value" variant="outlined" placeholder="---Select Client---"
                                :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                                hide-details="auto" clearable @click:clear="$emit('remove', 'client_id')">
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="3">
                            <v-select density="compact" v-model="model.location_id" :items="locationsList"
                                item-title="text" item-value="value" variant="outlined"
                                placeholder="---Select Location---"
                                :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                                hide-details="auto" clearable @click:clear="$emit('remove', 'location_id')"
                                @update:modelValue="model.location_name = locationName">
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-btn type="submit" color="primary" class="mr-4 mt-2" :loading="loading"
                                :disabled="loading || !Object.keys(model).some(key => model[key])">
                                Search
                            </v-btn>
                            <v-btn type="button" variant="outlined" color="primary" class="mr-4 mt-2" @click="resetForm"
                                :disabled="loading">
                                Reset
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import DateTimePicker from '../../../components/common/DateTimePicker.vue';

export default {
    components: {
        DateTimePicker,
    },
    props: ['loading'],
    emits: ['search', 'reset', 'remove'],
    data: (vm) => ({
        statusList: [{ text: 'Meta Data', value: 'metadata' }, { text: 'Ready', value: 'ready' },
        { text: 'Uploaded', value: 'uploaded' }],
        typeList: [{ text: 'Arch', value: 'arch' }, { text: 'Chasis', value: 'chasis' }, { text: 'Tire', value: 'tire' }],
        model: {
            ts: null,
            te: null,
            status: null,
            type: null,
            agent_id: null,
            client_id: null,
            location_id: null
        },
        menu: false,
        date: [],
        reseted: false,
        openPanel: false,
    }),
    computed: {
        ...mapGetters(['locationsList', 'agentsList', 'clientsList']),

        modelDateTime: {
            get() {
                return [this.model.ts, this.model.te];
            },
            set(value) {
                this.model.ts = value[0];
                this.model.te = value[1];
            }
        },
        dateTime: {
            get() {
                if (!this.model.ts && !this.model.te) return null;
                return (`${this.model.ts ? this.$helpers.getMomentDatas('DD-MM-YYYY hh:mm A', this.model.ts) + ' - ' : ''}${this.$helpers.getMomentDatas('DD-MM-YYYY hh:mm A', this.model.te)}`).trim();
            },
            set(value) {
                if (!value) this.model.ts = this.model.te = null;
            }
        },
        agentName() {
            const agent = _.find(this.agentsList, item => item.value === this.model.agent_id);
            return this.model.agent_id && agent ? agent.text : null;
        },
        clientName() {
            const client = _.find(this.clientsList, item => item.value === this.model.client_id);
            return this.model.client_id && client ? client.text : null;
        },
        locationName() {
            const location = _.find(this.locationsList, item => item.value === this.model.location_id);
            return this.model.location_id && location ? location.text : null;
        },
    },
    created() {
        this.getLocationLists();
        this.getAgentLists();
        this.getClientLists();
    },
    methods: {
        ...mapActions(['getLocationLists', 'getAgentLists', 'getClientLists']),

        clearDate() {
            this.model.ts = this.model.te = null;
            this.date = [];
            this.$emit('remove', 'ts');
            this.$emit('remove', 'te');
        },
        resetForm() {
            this.reseted = true;
            this.$emit('reset');
            this.model = {
                ts: null,
                te: null,
                status: null,
                type: null,
                agent_id: null,
                client_id: null,
                location_id: null
            };
            this.date = [];
            setTimeout(() => {
                this.reseted = false;
            });
        }
    }
}
</script>

<style> </style>
