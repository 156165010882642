export default {
    setPartLoading(state, data) {
        state.partLoading = data;
    },
    setPartsListLoading(state, data) {
        state.partsListLoading = data;
    },
    setPartsList(state, data) {        
        state.partsList = data;
        state.partsListLoading = false;
    }
}