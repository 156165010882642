const price = {
    params: ['price'],
    inserted (el, binding, vnode) {
        const regexString = "[^0-9,]";
        const replaceString = '';
        const regex = new RegExp(regexString);
        const replaceRegex = new RegExp(`${regexString}`, 'g')
        el.addEventListener('input', (event) => {
          const value = event.target.value;
          if (event.target && regex.test(value)) {
            event.preventDefault();
            event.target.value = value.replace(replaceRegex, replaceString);
          }
        });
    }
};

export default price;
