export default {
    setLocationLoading(state, data) {
        state.locationLoading = data;
    },
    setLocationsListLoading(state, data) {
        state.locationsListLoading = data;
    },
    setLocationsList(state, data) {        
        state.locationsList = data;
        state.locationsListLoading = false;
    }
}