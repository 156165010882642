<template>
  <div id="scan-page" class="w-full">
    <div class="d-flex align-center justify-space-between">
      <v-breadcrumbs :items="breadcrumbList" divider=">" class="pl-0 config-breadcrumbs mt-n15">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item @click="!item.disabled && $router.push(item.href)"
            :class="breadcrumbList.length > 1 && item.disabled ? 'text-secondary' : 'text-primary'">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-tooltip location="bottom" content-class="car-architecture-tooltip" attach :disabled="scanLoading || metaArchLoading || refreshLoading">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" size="x-small" class="mx-2 mb-1 bg-primary text-white" v-if="scanId" icon dark
            @click="!scanLoading && !metaArchLoading && !refreshLoading && refreshScan()">
              <v-icon dark size="20" v-if="!scanLoading && !metaArchLoading && !refreshLoading">mdi-sync</v-icon>
              <v-progress-circular v-else size="15" :width="3" color="white" indeterminate></v-progress-circular>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>
    </div>
    <scan-form v-if="scanId" :refresh="refreshPage" @refreshed="refreshPage=false"/>
    <scan-home v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useHead } from '@vueuse/head';

import scanForm from "./ScanForm.vue";
import scanHome from "./ScanHome.vue";

export default {
    name: "Scans",
    setup() {
      useHead({
        title : 'Scans',
      })
    },
    components: {
      scanForm,
      scanHome,
    },
    data: (vm) => ({
      scanId: vm.$route.name !== 'Scan Quote' ? vm.$route.params.scanId : null,
      breadcrumbList: [{
      text: 'Scans',
      disabled: true,
      href: '/scans',
      }],
      refreshLoading: false,
      refreshPage: false
    }),
    computed: {
      ...mapGetters(['scanLoading', 'metaArchLoading'])
    },
    watch: {
      $route(newVal) {
          this.scanId = newVal.params.scanId;
          this.setBreadCrumb();
      }
    },
    created() {
      this.setBreadCrumb();
    },
    methods: {
      ...mapActions(['setScanRefresh']),

      setBreadCrumb() {
        if(this.scanId) {
            this.breadcrumbList[0].disabled = false;
            this.breadcrumbList.push({
            text: 'Update Scan',
            disabled: true,
            href: `/scans/${this.scanId}`,
            })
        } else {
            this.breadcrumbList = [{
            text: 'Scans',
            disabled: true,
            href: '/scans',
            }]
        }
      },
      refreshScan() {
        this.refreshLoading = true;
        this.setScanRefresh(this.scanId).then(data => {
            this.refreshPage = true;
            this.refreshLoading = false;
        }).catch(err=> this.refreshLoading = false);
      }
    }
};
</script>
