import products from '@/store/modules/products/api';

export default {
    getProductLists({ commit }) {
        commit("setProductsListLoading", true);
        return new Promise((res, rej) => {
            products.productsList().then(response => {
                commit("setProductsList", response);
                res(response);
            }).catch((error) => {
                commit("setProductsListLoading", false);
                rej(error)
            });
        });
    },
    getSelectedProduct({ commit }, id) {
        commit("setProductLoading", true);
        return new Promise((res, rej) => {
            products.productModel(id).then(response => {
                commit("setProductLoading", false);
                res(response);
            }).catch((error) => {
                commit("setProductLoading", false);
                rej(error)
            });
        });
    },
    createProduct({ commit }, data) {
        commit("setProductLoading", true);
        return new Promise((res, rej) => {
            products.createProductModel(data).then(response => {
                commit("setProductLoading", false);
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Product Created Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setProductLoading", false);
                rej(error)
            });
        });
    },
    updateSelectedProduct({ commit }, data) {
        commit("setProductLoading", true);
        return new Promise((res, rej) => {
            products.updateProductModel(data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Product Updated Successfully",
                    color: "success",
                });
                commit("setProductLoading", false);
                res(response);
            }).catch((error) => {
                commit("setProductLoading", false);
                rej(error)
            });
        });
    },
    deleteProduct({ commit }, productId) {
        return new Promise((res, rej) => {
            products.deleteProductModel(productId).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Product Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    }
}