import http from '@/http';

class parts {
    partsList(data) {
        return http('quote').get('parts/lists', data);
    }
    partModel(partId) {
        return http('quote').get(`parts/${partId}`);
    }
    createPartModel(data) {
        return http('quote').post('parts', data);
    }
    updatePartModel(data) {
        return http('quote').put(`parts/${data.id}`, data);
    }
    deletePartModel(partId) {
        return http('quote').deleteReq(`parts/${partId}`)
    }
}

export default new parts();