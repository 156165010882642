<template>
    <div class="page-wrap" id="error-page">
        <div class="ma-auto">
            <div class="d-flex align-center justify-center mb-4">
                <v-avatar tile size="120" class="mr-4">
                    <v-img :src="errorImage" alt="" />
                </v-avatar>
                <div class="error-title">
                    <h1 class="font-weight-bold mb-0">404</h1>
                    <div class="text-18 font-weight-bold">PAGE NOT FOUND !</div>
                </div>
            </div>
            <div class="error-title text-center text-white">
                YOU SEEM TO BE TRYING TO FIND THIS WAY HOME
            </div>
            <div class="d-flex justify-center flex-wrap">
                <v-btn small class="ma-2" variant="outlined" color="primary" @click="$router.push(fromAuth ? 'login' : 'info')"
                    >Back To Home</v-btn
                >
                <v-btn small class="ma-2" variant="outlined" color="danger"
                    >Report the problem</v-btn
                >
            </div>
        </div>
    </div>
</template>
<script>
import { useHead } from '@vueuse/head'; 

import errorImage from "@/assets/images/svg/warning.svg";

export default {
    name: 'Error404',
    setup() {
      useHead({
        title: "Error 404",
      })
    },
    data() {
        return {
            authPath: ['/reset-password', '/login', '/forgot-password'],
            fromAuth: false,
            errorImage,
        }
    },
    created () {
        this.fromAuth = this.authPath.indexOf(this.$router.currentRoute.value.path) !== -1 ;
    }
}
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.error-title {
    h1 {
        font-size: 5rem;
    }
}
</style>
