<template>
  <div id="products-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" divider=">" class="pl-0 config-breadcrumbs mt-n15">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="!item.disabled && $router.push(item.href)"
          :class="breadcrumbList.length > 1 && item.disabled ? 'text-secondary' : 'text-primary'">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <product-form v-if="productId" />
    <product-home v-else />
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';

import productForm from "./components/ProductForm.vue";
import productHome from "./components/ProductHome.vue";

export default {
  name: "Products",
  setup() {
    useHead({
      title: "Products",
    })
  },
  components: {
    productForm,
    productHome,
  },
  data: (vm) => ({
    productId: vm.$route.params.productId,
    breadcrumbList: [{
      text: 'Products',
      disabled: true,
      href: '/products',
    }]
  }),
  watch: {
    $route(newVal) {
      this.productId = newVal.params.productId;
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.productId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.productId == 'create' ? 'Create' : 'Update'} Product`,
          disabled: true,
          href: `/products/${this.productId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Products',
          disabled: true,
          href: '/products',
        }]
      }
    }
  }
};
</script>
