<template>
  <div id="agents-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" divider=">" class="pl-0 config-breadcrumbs mt-n15">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="!item.disabled && $router.push(item.href)"
          :class="breadcrumbList.length > 1 && item.disabled ? 'text-secondary' : 'text-primary'">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <agent-form v-if="agentId" />
    <agent-home v-else />
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';

import agentForm from "../agents/components/AgentForm.vue";
import agentHome from "../agents/components/AgentHome.vue";

export default {
  name: "Agents",
  setup() {
    useHead({
      title: "Agents",
    })
  },
  components: {
    agentForm,
    agentHome,
  },
  data: (vm) => ({
    agentId: vm.$route.params.agentId,
    breadcrumbList: [{
      text: 'Agents',
      disabled: true,
      href: '/agents',
    }]
  }),
  watch: {
    $route(newVal) {
      this.agentId = newVal.params.agentId;
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.agentId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.agentId == 'create' ? 'Create' : 'Update'} Agent`,
          disabled: true,
          href: `/agents/${this.agentId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Agents',
          disabled: true,
          href: '/agents',
        }]
      }
    }
  }
};
</script>
