<template>
    <PerfectScrollbar
        :settings="{suppressScrollX: true, wheelPropagation: false}"
        class="h-100 rtl-ps-none ps scroll"
        style="height: 100%;"
    >
        <div class="pa-4 position-relative">
            <v-app-bar
                :color="$vuetify.theme.dark ? 'dark' : 'grey-lighten-4'"
                :theme="$vuetify.theme.dark"
                class="profile-bar pt-1"
                flat
            >
                <div class="d-flex justify-space-between align-center">
                    <h6 class="ma-0 ml-5">My Account</h6>
                    <div style="margin-left: 175px;">
                        <slot name="userDrawerCloseButton"></slot>
                    </div>
                </div>
            </v-app-bar>
        </div>

        <div class="pa-5 mt-10">
            <div class="heading-label pa-0 mb-4">Profile</div>
            <div class="d-flex align-center mb-10">
                <v-avatar size="56" class="mr-2">
                    <v-img
                        width="50"
                        height="50"
                        :src="Profile"
                        :lazy-src="Profile"
                        alt=""
                    />
                </v-avatar>
                <div>
                    <a @click="$router.push('/update-profile')" class="text-decoration-none update-user">
                        <v-row class="font-weight-medium ma-0 text-capitalize align-end justify-start">
                            {{user && user.name ? user.name : 'User'}}
                            <v-icon class="text-primary update-user" style="cursor: pointer;">mdi-account-edit</v-icon>
                        </v-row>
                    </a>
                </div>
            </div>
        </div>
    </PerfectScrollbar>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";

import Profile from "@/assets/images/1.png";

export default {
    name: 'UserDrawer',
    components: {
        PerfectScrollbar,
    },
    props: {},
    data () {
        return {
            Profile,
        }
    },
    computed: {},
    created() {
        this.user = this.$helpers.getCurData('curUser');
    },
    methods: {}
}
</script>

<style scoped>
.update-user:hover {
    color:  var(--v-info-base) !important;
}
.profile-bar{
    top : 0 !important; 
    left: 0 !important; 
    width: 100% !important;
}
</style>
