<template>
  <v-main app class="">
    <div class="d-flex flex-column flex-grow-1 h-full">
      <AppHeader />
      <AppContainer />
      <AppFooter />
    </div>
  </v-main>
</template>

<script>
import AppHeader from "../../layouts/component/Header.vue";
import AppContainer from "../../layouts/component/Container.vue";
import AppFooter from "../../layouts/component/Footer.vue";

export default {
  name: "LayoutScreen",
  components: {
    AppHeader,
    AppContainer,
    AppFooter,
  }
};
</script>