import roles from '@/store/modules/roles/api';

export default {
    getRoleLists({ commit }) {
        commit("setRolesListLoading", true);
        return new Promise((res, rej) => {
            roles.rolesList().then(response => {
                commit("setRolesList", response);
                res(response);
            }).catch((error) => {
                commit("setRolesListLoading", false);
                rej(error)
            });
        });
    },
    getSelectedRole({ commit }, id) {
        commit("setRoleLoading", true);
        return new Promise((res, rej) => {
            roles.roleModel(id).then(response => {
                commit("setRoleLoading", false);
                res(response);
            }).catch((error) => {
                commit("setRoleLoading", false);
                rej(error)
            });
        });
    },
    createRole({ commit }, data) {
        commit("setRoleLoading", true);
        return new Promise((res, rej) => {
            roles.createRoleModel(data).then(response => {
                commit("setRoleLoading", false);
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Role Created Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setRoleLoading", false);
                rej(error)
            });
        });
    },
    updateSelectedRole({ commit }, data) {
        commit("setRoleLoading", true);
        return new Promise((res, rej) => {
            roles.updateRoleModel(data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Role Updated Successfully",
                    color: "success",
                });
                commit("setRoleLoading", false);
                res(response);
            }).catch((error) => {
                commit("setRoleLoading", false);
                rej(error)
            });
        });
    },
    deleteRole({ commit }, roleId) {
        return new Promise((res, rej) => {
            roles.deleteRoleModel(roleId).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Role Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    }
}