<template>
  <v-row id="user-form-page">
    <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="saveUser">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">First Name</v-list-subheader>
                            <v-text-field  ref="first_name" v-model="model.first_name" :error-messages="v$.model.first_name.$errors.map(e => e.$message)"
                                placeholder="First Name" variant="outlined" @update:model-value="v$.model.first_name.$touch()"  @blur="v$.model.first_name.$touch()" required/>
                        </v-col>
                        <v-col cols="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Last Name</v-list-subheader>
                            <v-text-field ref="last_name" v-model="model.last_name" :error-messages="v$.model.last_name.$errors.map(e => e.$message)"
                                placeholder="Last Name" variant="outlined" @update:model-value="v$.model.last_name.$touch()"  @blur="v$.model.last_name.$touch()" required/>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">E-mail</v-list-subheader>
                            <v-text-field v-model="model.email" :error-messages="v$.model.email.$errors.map(e => e.$message)"
                                placeholder="E-mail" variant="outlined" ref="email" @update:model-value="v$.model.email.$touch()"  @blur="v$.model.email.$touch()" required />
                        </v-col>
                        <v-col cols="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Password</v-list-subheader>
                            <v-text-field ref="password" :type="show ? 'text' : 'password'" :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                v-model="model.password" :error-messages="userId == 'create' ? v$.model.password.$errors.map(e => e.$message) : ''"
                                placeholder="Password" variant="outlined" @click:append="show = !show" :required="userId == 'create'" />
                        </v-col>
                    </v-row>
                    <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Roles</v-list-subheader>
                    <v-autocomplete ref="roles" v-model="model.roles" :items="rolesList" variant="outlined" chips multiple
                        closable-chips placeholder="Roles" :error-messages="v$.model.roles.$errors.map(e => e.$message)">
                        <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="toggleSelect('roles')">
                                <v-row>
                                    <v-col cols="auto" class="ml-2">
                                        <v-icon :color="model.roles.length > 0 ? 'secondary' : ''">
                                            {{ model.roles.length === rolesList.length ? 'mdi-close-box' :
                                                model.roles.length > 0 ? 'mdi-minus-box' : 'mdi-checkbox-blank-outline' }}
                                        </v-icon>
                                    </v-col>
                                    <v-col class="ml-n3">
                                        <v-list-item-title>Select All</v-list-item-title>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{item, index}">
                            <v-chip v-if="index <= 4" close  @click:close="model.roles.splice(index, 1)">
                                <span class="text-capitalize">{{ item }}</span>
                            </v-chip>
                            <span v-if="index === 5" class="text-grey text-caption">
                                (+{{ model.roles.length - 5 }} others)
                            </span>
                        </template>
                    </v-autocomplete>
                    <v-row class="ma-0 pa-0" v-if="model.roles.includes('Client') || model.roles.includes('Agent')">
                        <v-col cols="6" class="ma-0 pt-0 pb-0 pl-0" v-if="model.roles.includes('Agent')">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Agents</v-list-subheader>
                            <v-autocomplete ref="agents" v-model="model.agents" :items="agentsList" item-title="text"
                                item-value="value" variant="outlined" closable-chips chips multiple placeholder="Agents" :error-messages="v$.model.agents.$errors.map(e => e.$message)">
                                <template v-slot:prepend-item>
                                    <v-list-item ripple @mousedown.prevent @click="toggleSelect('agents')">
                                        <v-row>
                                            <v-col cols="auto" class="ml-2">
                                                <v-icon :color="model.agents.length > 0 ? 'secondary' : ''">
                                                    {{ model.agents.length ===  agentsList.length ? 'mdi-close-box' :
                                                        model.agents.length > 0 ? 'mdi-minus-box' : 'mdi-checkbox-blank-outline' }}
                                                </v-icon>
                                            </v-col>
                                            <v-col class="ml-n3">
                                                <v-list-item-title> Select All </v-list-item-title>
                                            </v-col>
                                        </v-row>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:selection="{item, index}">
                                    <v-chip close @click:close="model.agents.splice(index, 1)">
                                        {{ item.text}}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6" class="ma-0 pt-0 pb-0 pl-0" v-if="model.roles.includes('Client')">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Clients</v-list-subheader>
                            <v-autocomplete ref="clients" v-model="model.clients" :items="clientsList" item-title="text"
                                item-value="value" variant="outlined" closable-chips chips multiple placeholder="Clients" :error-messages="v$.model.clients.$errors.map(e => e.$message)">
                                <template v-slot:prepend-item>
                                    <v-list-item ripple @mousedown.prevent @click="toggleSelect('clients')">
                                        <v-row>
                                            <v-col cols="auto" class="ml-2">
                                                <v-icon :color="model.clients.length > 0 ? 'secondary' : ''">
                                                    {{ model.clients.length ===  clientsList.length ? 'mdi-close-box' :
                                                        model.clients.length > 0 ? 'mdi-minus-box' : 'mdi-checkbox-blank-outline' }}
                                                </v-icon>
                                            </v-col>
                                            <v-col class="ml-n3">
                                                <v-list-item-title> Select All </v-list-item-title>
                                            </v-col>
                                        </v-row>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:selection="{item, index}">
                                    <v-chip close @click:close="model.clients.splice(index, 1)">
                                        {{ item.text}}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <div class="d-block d-lg-flex align-center justify-end">
                        <v-row no-gutters class="ma-0 mb-4 mb-lg-0" align="center" justify="start" v-if="userId !== 'create'">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Api Keys:</v-list-subheader>
                            <v-tooltip location="top" :disabled="tokenLoading || loading" v-if="!tokenLoading && !loading">
                                <template v-slot:activator="{ props }">
                                    <v-chip :color="copyTxt ? 'success':'secondary'" small class="ml-2 text-primary"
                                        @click="toogleCopyTxt()" ref="apiKey" id="apiKey">
                                        {{ model.api_access_token || 'Nil'}}
                                        <v-icon size="13" class="ml-2" @click="toogleCopyTxt()">mdi-content-copy</v-icon>
                                    </v-chip>
                                </template>
                                <span>{{copyTxt ? 'Copied!' : 'Copy'}}</span>
                            </v-tooltip>
                            <v-btn color="primary" text small icon class="ml-3" :disabled="tokenLoading || loading"
                                @click="getUserToken">
                                <v-icon :size="25" :class="{'icon-loader': tokenLoading}">mdi-sync-circle</v-icon>
                            </v-btn>
                        </v-row>
                        <v-btn type="submit" color="primary"  class="mr-4 bg-primary" :loading="loading">
                            Submit
                        </v-btn>
                        <v-btn type="button" variant="outlined" color="primary" class="mr-4 button" :disabled="loading"
                            @click="$router.push('/users')">
                            Cancel
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { mapActions, mapGetters } from 'vuex';

export default {
    data: (vm) => ({
        userId: vm.$route.params.userId,
        show: false,
        model: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            roles:[],
            agents: [],
            clients: [],
        },
        loading: false,
        tokenLoading: false,
        copyTxt: false,
        v$: useVuelidate(),
    }),
    validations() {
        const validateModel = {
            model: {
                first_name: { required},
                last_name: { required},
                email: { email, required},
                roles: { required},
            }
        }
        if(this.model.roles.includes('Agent')) {
            validateModel.model['agents'] = { required }
        }
        if(this.model.roles.includes('Client')) {
            validateModel.model['clients'] = { required }
        }

        if (this.userId == 'create') {
            validateModel.model['password'] = { required }
            return validateModel;
        } else {
            return validateModel;
        }
    },
    watch: {
        'model.roles': function (newValue) {
            if(!this.model.roles.includes('Agent')) {
                this.model.agents = [];
            }
            if(!this.model.roles.includes('Client')) {
                this.model.clients = [];
            }
        },
    },
    computed: {
        ...mapGetters(['rolesListLoading', 'rolesList', 'agentsList', 'clientsList']),
    },
    created() {
        if (this.userId != 'create') {
          this.getSelectedUser(this.userId).then(response => this.model = { ...this.model, ...response} );
        }
        this.getRoleLists();
        this.getAgentLists();
        this.getClientLists();
    },
    methods: {
        ...mapActions(['getRoleLists', 'getSelectedUser', 'createUser', 'updateSelectedUser',
            'getClientLists', 'getAgentLists', 'getUserApiToken']),

        toggleSelect(field) {
            if (this.model[field].length === this[`${field}List`].length) {
                this.model[field] = [];
            } else {
                this.model[field] = this[`${field}List`].length === 0 ? [] :
                    typeof this[`${field}List`][0] === 'string' ? this[`${field}List`] : this[`${field}List`].map(item => item.value);
            }
        },
        toogleCopyTxt() {
            if (!this.copyTxt) {
                this.$helpers.copyTextFunc(this.$refs.apiKey, 'noInput');
                this.copyTxt = true;
                setTimeout(() => {
                    this.copyTxt = false;
                }, 5000);
            }
        },
        getUserToken() {
            const self = this;
            this.tokenLoading = true;
            this.getUserApiToken(this.userId).then(resp => {
            self.model.api_access_token = resp.token;
            self.tokenLoading = false;
            }).catch( err=> self.tokenLoading = false);
        },
        saveUser() {
            this.v$.$touch();
            if (this.v$.$invalid) {
                this.$helpers.focusErrorElement(this.v$.model, this.$refs);
                return;
            }
            const self = this;
            this.loading = true;
            if (this.userId != 'create') {
                this.updateSelectedUser(this.model).then(resp => {
                    self.loading = false;
                    this.$router.push("/users");
                }).catch(err => self.loading = false);
            } else {
                this.createUser(this.model).then(resp => {
                    self.loading = false;
                    this.$router.push("/users");
                }).catch(err => self.loading = false);
            }
        },
    },
};
</script>
