<template>
  <div id="locations-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" divider=">" class="pl-0 config-breadcrumbs mt-n15">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="!item.disabled && $router.push(item.href)"
          :class="breadcrumbList.length > 1 && item.disabled ? 'text-secondary' : 'text-primary'">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <location-form v-if="locationId" />
    <location-home v-else />
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';

import locationForm from "../locations/components/LocationForm.vue";
import locationHome from "../locations/components/LocationHome.vue";

export default {
  name: "Locations",
  setup() {
    useHead({
      title: "Locations",
    })
  },
  components: {
    locationForm,
    locationHome,
  },
  data: (vm) => ({
    locationId: vm.$route.params.locationId,
    breadcrumbList: [{
      text: 'Locations',
      disabled: true,
      href: '/locations',
    }]
  }),
  watch: {
    $route(newVal) {
      this.locationId = newVal.params.locationId;
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.locationId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.locationId == 'create' ? 'Create' : 'Update'} Location`,
          disabled: true,
          href: `/locations/${this.locationId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Locations',
          disabled: true,
          href: '/locations',
        }]
      }
    }
  }
};
</script>
