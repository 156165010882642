import permissions from '@/store/modules/permissions/api';

export default {
    getPermissionLists({ commit }) {
        commit("setPermissionsListLoading", true);
        return new Promise((res, rej) => {
            permissions.permissionsList().then(response => {
                commit("setPermissionsList", response);
                res(response);
            }).catch((error) => {
                commit("setPermissionsListLoading", false);
                rej(error)
            });
        });
    },
    getSelectedPermission({ commit }, id) {
        commit("setPermissionLoading", true);
        return new Promise((res, rej) => {
            permissions.permissionModel(id).then(response => {
                commit("setPermissionLoading", false);
                res(response);
            }).catch((error) => {
                commit("setPermissionLoading", false);
                rej(error)
            });
        });
    },
    createPermission({ commit }, data) {
        commit("setPermissionLoading", true);
        return new Promise((res, rej) => {
            permissions.createPermissionModel(data).then(response => {
                commit("setPermissionLoading", false);
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Permission Created Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setPermissionLoading", false);
                rej(error)
            });
        });
    },
    updateSelectedPermission({ commit }, data) {
        commit("setPermissionLoading", true);
        return new Promise((res, rej) => {
            permissions.updatePermissionModel(data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Permission Updated Successfully",
                    color: "success",
                });
                commit("setPermissionLoading", false);
                res(response);
            }).catch((error) => {
                commit("setPermissionLoading", false);
                rej(error)
            });
        });
    },
    deletePermission({ commit }, permissionId) {
        return new Promise((res, rej) => {
            permissions.deletePermissionModel(permissionId).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Permission Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    }
}