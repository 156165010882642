export default {
    setAgentLoading(state, data) {
        state.agentLoading = data;
    },
    setAgentsListLoading(state, data) {
        state.agentsListLoading = data;
    },
    setAgentsList(state, data) {
        state.agentsList = data;
        state.agentsListLoading = false;
    },
    setSelectedAgent(state, data) {
        state.selectedAgent = data;
        state.agentLoading = false;
    }
}