import { themeConfig, themePreset } from "@/themeConfig";
import globalHelper from '@/helpers/global.helper';

const state = () => ({
  themeMode: themeConfig,
  vuetifyPreset: themePreset,
  appLoading: false,
  themePageLoading: false,
  themePageLoadContent: 'Loading',
  headerIconHide: false
});

const getters = {
  getThemeMode: state => state.themeMode,
  getAppLoading: state => state.appLoading,
  getVuetifyPreset: state => state.vuetifyPreset,
  getPageLoading: state => state.themePageLoading,
  getPageLoadingContent: state => state.themePageLoadContent,
  getHeaderIconHide: state => state.headerIconHide,
};

const actions = {
  changeThemeMode({ commit }) {
    commit("toggleThemeMode");
  },
  changeThemeLayout({ commit }, data) {
    commit("toggleThemeLayout", data);
  },
  changeThemeDarkMode({ commit }, data) {
    commit("toggleThemeDarkMode", data);
  },
  changeThemeRtl({ commit }) {
    commit("toggleThemeRtl");
  },
  changeVerticalSidebarDrawer({ commit }) {
    commit("toggleVerticalSidebarDrawer");
  },
  changeVerticalSaasSidebarDrawer({ commit }) {
    commit("toggleVerticalSaasSidebarDrawer");
  },
  changeVerticalSidebarDrawerColor({ commit }, data) {
    commit("toggleVerticalSidebarDrawerColor", data);
  },
  updateVerticalSidebarDrawer({ commit }, data) {
    commit("setVerticalSidebarDrawer", data);
  },
  changeAppBarColor({ commit }, data) {
    commit("toggleAppBarColor", data);
  },
  changeCompactVerticalSidebarDrawer({ commit }) {
    commit("toggleCompactVerticalSidebarDrawer");
  },
  changeVerticalSidebarMini({ commit }) {
    commit("toggleVerticalSidebarMini");
  },
  changeThemeLoadingState({ commit }, payload) {
    commit("toggleThemeLoadingState", payload);
  },
  changeHideHeaderIcon({ commit }, payload) {
    commit("toggleHideHeaderIcon", payload);
  },
};

const mutations = {
  setPageLoading(state, data) {
    state.themePageLoading = data;
  },
  setPageLoadingContent(state, data) {
    state.themePageLoadContent = data;
  },
  toggleThemeDarkMode(state, data) {
    state.themeMode.isDark = data;
    this.$vuetify.theme.dark = data;
  },
  toggleThemeLayout(state, data) {
    state.themeMode.layout = data;
  },
  toggleThemeRtl(state) {
    state.themeMode.rtl = !state.themeMode.rtl;
  },
  toggleThemeLoadingState(state, payload) {
    state.themeMode.isLoading = payload;
  },
  toggleVerticalSidebarDrawer(state, payload) {
    state.themeMode.verticalSidebarDrawer = !state.themeMode.verticalSidebarDrawer;
  },
  setVerticalSidebarDrawer(state, payload) {
    state.themeMode.verticalSidebarDrawer = payload;
  },
  toggleVerticalSaasSidebarDrawer(state) {
    state.themeMode.verticalSaasSidebarDrawer = !state.themeMode
      .verticalSaasSidebarDrawer;
  },
  toggleVerticalSidebarDrawerColor(state, data) {
    state.themeMode.verticalSidebarDrawerColor = data;
  },
  toggleAppBarColor(state, data) {
    state.themeMode.appBarColor = data;
  },
  toggleCompactVerticalSidebarDrawer(state) {
    state.themeMode.verticalCompactSidebarDrawer = !state.themeMode
      .verticalCompactSidebarDrawer;
  },
  toggleVerticalSidebarMini(state) {
    state.themeMode.verticalSidebarMini = !state.themeMode.verticalSidebarMini;
  },
  toggleAppLoading(state) {
    state.appLoading = !state.appLoading;
  },
  toggleHideHeaderIcon(state, data) {
    state.headerIconHide = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
