<template>
  <v-combobox v-model="model" ref="recipients" :filter="filter" :hide-no-data="!search" :items="items"
              item-title="text" v-model:search="search" @focus="toLabel = ''" density="comfortable"
              @blur=" toLabel = 'Enter Your Recipients'" hide-details="auto" multiple small-chips hide-selected variant="outlined" :label="model.length == 0 ? toLabel : ''" @update:model-value="updateRecipients" :error-messages="error" required >
      <template v-slot:prepend-item>
        <v-list-item class="header-item">
            <v-list-item-title class="text-capitalize text-15 text-dark">Enter Your Recipients</v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title class="d-flex">
            <span class="subheading mr-3">Select</span>
              <v-chip color="grey lighten-3" variant="flat" size="small">{{ search }}</v-chip>
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:chip="{ props, item, selected }">
        <v-chip v-bind="props" color="grey-lighten-3" variant="flat"
                :model-value="selected" label size="small">
          <span class="pr-2 text-capitalize text-primary">{{ item.title }}</span>
          <v-icon size="small" @click="removeItem(item)"> $delete</v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ index, item }">
        <v-row
          dense
          class="d-flex w-100 align-center justify-space-between part-items"
          @click="selectChip(item)"
          style="cursor: pointer;"
        >
          <v-col cols="10" class="d-flex align-center align-self-stretch">
            <v-text-field
              class="w-full background-transparent pa-5 pt-0 pb-0 mt-n3"
              v-if="editing === item"
              v-model="editing.title"
              autofocus
              variant="plain"
              hide-details
              @keyup.enter.stop.prevent="edit(index, item)"
              @click.stop
              @keydown.stop="handleKeydown"
            />
            <v-list-item-title v-else class="ml-5">
              <v-chip
                :color="item.color ? `${item.color}-lighten-3` : '#555555'"
                variant="flat"
                label
                density="comfortable"
                size="small"
                class="text-capitalize"
              >
                {{ item.title }}
              </v-chip>
            </v-list-item-title>
            <v-spacer />
          </v-col>
          <v-col cols="2" class="d-flex align-center justify-end align-self-stretch">
            <v-list-item-action @click.stop>
              <v-btn
                class="mt-n"
                @click.stop.prevent="edit(index, item)"
                variant="text"
                :icon="editing !== item ? 'mdi-pencil' : 'mdi-check'"
              />
            </v-list-item-action>
          </v-col>
        </v-row>
      </template>
  </v-combobox>
</template>

<script>
export default {
  props: ['error'],
  emits: ['updateTo'],
  data() {
    return {
      model: [],
      x: 0,
      search: null,
      y: 0,
      editing: null,
      editingIndex: -1,
      items: [],
      toLabel: 'Enter Your Recipients'
    }
  },
  watch: {
    model (val, prev) {
      if (val.length === prev.length) return;
      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = { text: v, value: v.toLowerCase()}
          this.items.push(v)
          this.nonce++
        }
        return v
      })
    },
  },
  methods: {
    edit (index, item) {
      if (!this.editing) {
        this.editing = item
        this.editingIndex = index
      } else {
        const editedTitle = this.editing.title.trim();
        if (editedTitle) {
          this.updateItems(index, editedTitle);
        }
        this.editing = null
        this.editingIndex = -1
      }
    },
    updateItems(index, newTitle) {
      const oldTitle = this.items[index].title;
      const modelIndex = this.model.findIndex(
        (v) => v.title === oldTitle || v === oldTitle
      );
      if (modelIndex > -1) {
        this.model.splice(modelIndex, 1, { text: newTitle, value: newTitle.toLowerCase() });
      }
      this.items.splice(index, 1, { text: newTitle, value: newTitle.toLowerCase() });
    },
    handleKeydown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    selectChip(item) {
      if (!this.model.includes(item.title)) {
        this.model.push(item.title);
      }
    },
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    updateRecipients() {
      const toRecp = this.model.map(item => {
        return typeof item === 'string' ? item.toLowerCase() : item.value;
      });
      this.$emit('updateTo', toRecp);
    },
    removeItem(item) {
      const itemIndex = this.model.findIndex((v) =>
        typeof v === 'string' ? v === item.title : v.text === item.title
      );

      if (itemIndex > -1) {
        this.model.splice(itemIndex, 1);
        if (!this.items.some(i => i.text === item.title)) {
          this.items.push({ text: item.title, value: item.title });
        }
      }
    }
  }
}
</script>

<style>

</style>