import { createApp } from 'vue';

// NPM Style Sheets
import 'viewerjs/dist/viewer.css';

// Component Imports
import App from '@/App.vue';
import router from '@/router';
import { store } from '@/store';
import globalHelper from '@/helpers/global.helper';

// Theme plugin Imports
import vuetify from '@/plugins/vuetify';
import '@/plugins';
import themeKit from '@/plugins/theme.kit';
import filters from '@/filters';
import GoogleTranslateSelect from '@google-translate-select/vue3';
import i18n from './lang/lang';
import base from '@/plugins/base.js';

// Create Vue app instance
const app = createApp(App);

// Use plugins
app.use(vuetify);
app.use(themeKit);
app.use(base);
app.use(i18n);

// Set global properties
app.config.globalProperties.$helpers = globalHelper;
app.config.globalProperties.$filters = filters;

// Install vGoogleTranslate plugin
app.use(GoogleTranslateSelect, {
    pageLanguage: 'en',
    includedLanguages: 'en,ja,fr,es,de',
});

// Install router and store
app.use(router);
app.use(store);

// Mount the app
app.mount('#app');
