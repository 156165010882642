const data = (tireScan) => {
    if (!tireScan) return null;
    const rowItems = tireScan.reduce((result, item) => {

        // Get app object corresponding to current item from result (or insert if not present)
        var app = result[item.row] = result[item.row] || {};

        // Get type array corresponding to current item from app object (or insert if not present)
        var type = app[item.direction] = app[item.direction] || [];

        // Add current item to current type array
        type.push(item);

        // Return the result object for this iteration
        return result;

    }, {});

    return rowItems;
}

export default data;