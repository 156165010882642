import _ from 'lodash';

export const CAR_ARCH_DATA = (data) => {
    const panelParts = _.reduce(data, (result, panel) => {
        const panelNames = panel.panels.map(item => item.carPanel);
        panelNames.map(name => {
            result[name] = {
                group_name: panel.name,
                name: name,
                dent_total: 0,
                scratch_total: 0,
                tooltip: [
                    { text: 'Oversize', icon: 'mdi-checkbox-blank', color: '#561329', dent: 0, scratch: 0 },
                    { text: 'Severe', icon: 'mdi-checkbox-blank', color: '#81182E', dent: 0, scratch: 0},
                    { text: 'Heavy', icon: 'mdi-checkbox-blank', color: '#C1484E', dent: 0, scratch: 0 },
                    { text: 'Medium', icon: 'mdi-checkbox-blank', color: '#CC9188', dent: 0, scratch: 0 },
                    { text: 'Light', icon: 'mdi-checkbox-blank', color: '#D8D1C1', dent: 0, scratch: 0 },
                ]
            }
        })
        return result;
    }, {});
    return panelParts;
};