<template>
  <v-app
    :style="{
      background: $vuetify.theme.themes[theme].background,
      color: $vuetify.theme.themes[theme].color,
    }"
    class="app-admin-wrap-layout-6"
  >
    <SideBar v-if="!$router.currentRoute.value.fullPath.includes('/customer-portal/')"/>
    <base-view />
    <v-fade-transition v-if="getPageLoading">
      <v-overlay color="dark lighten-5" class="progress-overlay-loading">
          <v-row width="1000" class="ma-0 pa-5 white text-center font-weight-bold rounded-xl elevation-5 align-center justify-center" :class="color ? color : 'text-black'">
            <v-progress-circular
              indeterminate
              color="secondary"
            ></v-progress-circular>
            <span class="ml-3">{{ getPageLoadingContent }}</span>
          </v-row>
      </v-overlay>
    </v-fade-transition>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

import SideBar from "./Sidebar.vue";
import BaseView from "./View.vue";

export default {
  name: "FullLayout",
  components: {
    SideBar,
    BaseView,
  },
  computed: {
    ...mapGetters(["getThemeMode", "getPageLoading", "getPageLoadingContent"]),

    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>

<style lang="scss">
.app-admin-wrap-layout-6 {
  .sidebar-six {
    &.theme--dark.v-navigation-drawer {
      background-color: #111827;
      // background-color: #111827;
    }

    // .top-toolbar {
    //   background-color: #111827;
    // }
  }
}
div.theme--dark .v-navigation-drawer {
  background-color: #272727 !important;
}
</style>
