<template>
    <v-row class="ma-0"  justify="center">
        <v-col cols="6" lg="6" md="6">
            <v-row class="ma-0"  justify="end">
                <v-col cols="6" lg="3" xl="3">Registration:</v-col>
                <v-col cols="6" lg="5" xl="5">
                    {{ model && model.licensePlate ? model.licensePlate : '-' }}
                </v-col>
            </v-row>
            <v-row class="ma-0"  justify="end">
                <v-col cols="6" lg="3" xl="3">Make:</v-col>
                <v-col cols="6" lg="5" xl="5">
                    {{ model && model.make ? model.make : '-'}}
                </v-col>
            </v-row>
            <v-row class="ma-0"  justify="end">
                <v-col cols="6" lg="3" xl="3">Model:</v-col>
                <v-col cols="6" lg="5" xl="5">
                    {{ model && model.model ? model.model : '-'}}
                </v-col>
            </v-row>
            <v-row class="ma-0"  justify="end">
                <v-col cols="6" lg="3" xl="3">Colour:</v-col>
                <v-col cols="6" lg="5" xl="5" class="text-capitalize">
                    {{ model && model.carColor ? model.carColor : '-'}}
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="6" lg="6" md="6">
            <v-row class="ma-0"  justify="start">
                <v-col cols="6" lg="4" xl="4">Scan Date/Time:</v-col>
                <v-col cols="6">
                    {{ model && model.timestamp ? $helpers.getMomentDatas('DD-MM-YYYY hh:mm A',model.timestamp) : '-' }}
                </v-col>
            </v-row>
            <v-row class="ma-0"  justify="start">
                <v-col cols="6" lg="4" xl="4">Assessor: [logged in user]:</v-col>
                <v-col cols="6">{{ assessor ? assessor : '-'}}</v-col>
            </v-row>
            <v-row class="ma-0"  justify="start">
                <v-col cols="6" lg="4" xl="4">Agent:</v-col>
                <v-col cols="6">{{ model && model.agent_name ? model.agent_name : '-' }}</v-col>
            </v-row>
            <v-row class="ma-0"  justify="start">
                <v-col cols="6" lg="4" xl="4">Client:</v-col>
                <v-col cols="6">{{ model && model.client_name ? model.client_name : '-' }}</v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ['model', 'assessor']
}
</script>

<style>

</style>