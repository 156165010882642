<template>
  <v-row id="location-form-page">
    <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="saveLocation">
                    <v-row>
                        <v-col cols="12" lg="6" xl="6">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Name</v-list-subheader>
                            <v-text-field v-model="model.name" :error-messages="v$.model.name.$errors.map(e => e.$message)"
                                placeholder="Name" variant="outlined" ref="name" @update:model-value="v$.model.name.$touch()"  @blur="v$.model.name.$touch()" required />
                        </v-col>
                        <v-col cols="12" lg="6" xl="6">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">State</v-list-subheader>
                            <v-select ref="state"  v-model="model.state" :items="stateList" item-title="text"   item-value="value" variant="outlined" label="" class="text-capitalize"
                                :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                                :error-messages="v$.model.state.$errors.map(e => e.$message)" placeholder="----Code - State Name - Country Code----"
                                @update:modelValue="v$.model.state.$touch()"  @blur="v$.model.state.$touch()" required
                                no-data-text="State Not Available!.">
                            </v-select>
                        </v-col>
                    </v-row>
                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="primary"  class="mr-4">
                            Submit
                        </v-btn>
                        <v-btn type="button" variant="outlined" color="primary" class="mr-4" @click="$router.push('/locations')">
                            Cancel
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions } from 'vuex';

export default {
    data: (vm) => ({
        locationId: vm.$route.params.locationId,
        model: {
            name: '',
            state: '',
        },
        stateList: [
            { text: 'ACT - Australian Capital Territory - AUS', value:'ACT'}, { text: 'NSW - New South Wales - AUS', value: 'NSW'},
            { text: 'NT - Northern Territory - AUS', value:'NT'}, { text: 'QLD - Queensland - AUS', value:'QLD'},
            { text: 'SA - South Australia - AUS', value:'SA'}, { text: 'TAS - Tasmania - AUS', value:'TAS'},
            { text: 'VIC - Victoria - VIC', value:'ACT'}, { text: 'WA - Western Australia - AUS', value:'WA'}
        ],
        v$: useVuelidate(),
    }),
    validations() {
        return {
            model: {
                name: { required },
                state: { required },
            }
        }
    },
    created() {
        if (this.locationId != 'create') {
          this.getSelectedLocation(this.locationId).then(response => this.model = response);
        }
    },
    methods: {
        ...mapActions(['getSelectedLocation', 'createLocation', 'updateSelectedLocation']),

        saveLocation() {
            this.v$.$touch();
            if (this.v$.$invalid) {
                this.$helpers.focusErrorElement(this.v$.model, this.$refs);
                return;
            }
            if (this.locationId != 'create') {
                this.updateSelectedLocation(this.model).then(resp => this.$router.push("/locations"));
            } else {
                this.createLocation(this.model).then(resp => this.$router.push("/locations"));
            }
        },
    },
};
</script>
