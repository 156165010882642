<template>
  <v-row id="email-header-footer-page">
    <v-col cols="12">
        <v-card>
            <v-card-title class="text-primary">Email Header & Footer</v-card-title>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="onSubmit">
                  <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="pb-9">
                        <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Header</v-list-subheader>
                        <div class="send-mail" :class="{'error-border': submit && v$.model.email_default_header.$errors.length}">
                          <quill-editor ref="email_default_header"  v-model:value="model.email_default_header" :options="headerOption"/>
                        </div>
                        <div v-if="submit && v$.model.email_default_header.$errors.length" class="error-message mt-2 ml-2">
                            Header is required
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Footer</v-list-subheader>
                        <div class="send-mail" :class="{'error-border': submit && v$.model.email_default_footer.$errors.length}">
                            <quill-editor ref="email_default_footer" v-model:value="model.email_default_footer" :options="footerOption"/>
                        </div>
                        <div v-if="submit && v$.model.email_default_footer.$errors.length" class="error-message mt-2 ml-2">
                            Footer is required
                        </div>
                    </v-col>
                  </v-row>
                  <div class="d-flex justify-end mt-3">
                      <v-btn type="submit" color="primary" :disabled="loading" :loading="loading" class="mr-4">
                          Submit
                      </v-btn>
                  </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions } from 'vuex';

import { EDITOR_OPTIONS } from '@/constants/editor-options';

export default {
  data: (vm) => ({
    headerOption: EDITOR_OPTIONS('the header'),
    footerOption: EDITOR_OPTIONS('the footer'),
    model: {
      email_default_header: '',
      email_default_footer: ''
    },
    submit: false,
    loading: false,
    v$: useVuelidate(),
  }),
  validations: {
    model: {
        email_default_header: {required },
        email_default_footer: { required },
    },
  },
  created() {
    this.getModelData();
  },
  methods: {
    ...mapActions(['getOptModel', 'createOptModel']),

    async getModelData() {
      const opt = { option_name: [] };
      await Object.keys(this.model).forEach(key => opt.option_name.push(key.toUpperCase()));
      this.getOptModel({endPoint: 'options/search', data: opt}).then(resp => {
        Object.keys(resp).forEach(key => this.model[key.toLowerCase()] = resp[key]);
      })
    },
    async onSubmit() {
      this.submit = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.loading = true;
      let reqData = []
      await Object.keys(this.model).forEach(key => {
        reqData.push({option_name: key.toUpperCase(), option_value: this.model[key]});
      })
      this.createOptModel({endPoint: 'options/bulksave', data: reqData})
        .then((response) => {
          this.loading = this.submit = false;
          this.$store.commit("snackbar/SHOW_MESSAGE", {
              text: `Email Header & Footer Updated Successfully`,
              color: "success",
          });
        }).catch(err => this.loading = this.submit = false);
    },
  }
}
</script>

<style>

</style>