<template>
  <v-row id="role-form-page">
    <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="saveRole">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Name</v-list-subheader>
                            <v-text-field ref="name" v-model="model.name" :error-messages="v$.model.name.$errors.map(e => e.$message)" placeholder="Name" variant="outlined" @update:model-value="v$.model.name.$touch()"  @blur="v$.model.name.$touch()" required/>
                        </v-col>
                        <v-col cols="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Display Name</v-list-subheader>
                            <v-text-field ref="display_name" variant="outlined" v-model="model.display_name" placeholder="Display Name"  required/>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Description</v-list-subheader>
                            <v-textarea hide-details="auto" class="mb-3" density="compact" auto-grow rows="4" v-model="model.description"
                                row-height="20" placeholder="Description" variant="outlined"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Permissions</v-list-subheader>
                            <v-combobox ref="permissions"  @update:model-value="v$.model.permissions.$touch()"  @blur="v$.model.permissions.$touch();searchClear('permissions')"
                                v-model="model.permissions"  :error-messages="v$.model.permissions.$errors.map(e => e.$message)"
                                :items="permissionsList" closable-chips chips placeholder="Permissions" multiple variant="outlined">
                                <template v-slot:prepend-item>
                                    <v-list-item ripple @mousedown.prevent @click="toggle">
                                        <v-row>
                                            <v-col cols="auto" class="ml-2">
                                                <v-icon :color="model.permissions.length > 0 ? 'secondary' : ''">
                                                {{ icon }}
                                                </v-icon>
                                            </v-col>
                                            <v-col class="ml-n3">
                                                <v-list-item-title>Select All</v-list-item-title>
                                            </v-col>
                                        </v-row>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index <= 4" close  @click:close="model.permissions.splice(index, 1)">
                                    <span class="text-capitalize">{{ item }}</span>
                                    </v-chip>
                                    <span
                                    v-if="index === 5"
                                    class="text-grey text-caption"
                                    >
                                    (+{{ model.permissions.length - 5 }} others)
                                    </span>
                                </template>
                            </v-combobox>
                        </v-col>
                    </v-row>
                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="primary"  class="mr-4">
                            Submit
                        </v-btn>
                        <v-btn type="button" variant="outlined" color="primary" class="mr-4" @click="$router.push('/roles')">
                            Cancel
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapGetters, mapActions } from 'vuex';

export default {
    data: (vm) => ({
        roleId: vm.$route.params.roleId,
        show: false,
        model: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            permissions:[],
        },
        v$: useVuelidate(),
    }),
    validations: {
        model: {
            name: { required},
            permissions: { required},
        }
    },
    computed: {
        ...mapGetters(['permissionsListLoading', 'permissionsList']),

        selectedAllPermissions() {
            return this.model.permissions.length === this.permissionsList.length
        },
        selectSomePermissions() {
            return this.model.permissions.length > 0 && !this.selectedAllPermissions
        },
        icon() {
            if (this.selectedAllPermissions) return 'mdi-close-box'
            if (this.selectSomePermissions) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    created() {
        if (this.roleId != 'create') {
          this.getSelectedRole(this.roleId).then(response => this.model = response);
        }
        this.getPermissionLists();
    },
    methods: {
        ...mapActions(['getSelectedRole', 'createRole', 'updateSelectedRole', 'getPermissionLists']),

        toggle() {
            this.$nextTick(() => {
            if (this.selectedAllPermissions) {
                this.model.permissions = []
            } else {
                this.model.permissions = this.permissionsList.slice()
            }
            })
        },
        searchClear(value) {
            if (this.model[value].length === 0) {
                this.$refs[value].lazySearch = '';
            }
        },
        saveRole() {
            this.v$.$touch();
            if (this.v$.$invalid) {
                this.$helpers.focusErrorElement(this.v$.model, this.$refs);
                return;
            }
            if (this.roleId != 'create') {
                this.updateSelectedRole(this.model).then(resp => this.$router.push("/roles"));
            } else {
                this.createRole(this.model).then(resp => this.$router.push("/roles"));
            }
        },
    },
};
</script>
