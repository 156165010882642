import http from '@/http';

class appSettings {
    userOptions(data) {
        return http('user').get('options', data);
    }
    updateUserOptions(id, data) {
        return http('user').put(`options/${id}`, data);
    }

    getOption(id, data) {
        return http('user').get(`options/${id}`, data);
    }
}

export default new appSettings();