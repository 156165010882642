import _ from 'lodash';

export const PANEL_PARTS = (data) => {
    const panelParts = _.reduce(data, (result, panel) => {
        result[panel.name] = panel.panels.map(item => item.carPanel);
        return result;
    }, {});

    return panelParts;
}

export const REPAIR_LISTS = [{text: 'Conventional', value: 'conventional'}, {text: 'Replace / Paint', value: 'replacement'},
    {text: 'Push to Paint', value: 'p2p'}, {text: 'PDR', value: 'pdr'}];

export const CATEGORY_LISTS = ['light', 'medium', 'heavy', 'oversize', 'severe'];