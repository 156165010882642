<template>
  <v-row id="template-form-page">
    <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="saveTemplate">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Template Name</v-list-subheader>
                            <v-text-field  ref="templateName" v-model="model.templateName" :error-messages="v$.model.templateName.$errors.map(e => e.$message)"
                                placeholder="Template Name" variant="outlined" hide-details="auto" required/>
                        </v-col>
                        <v-col cols="12">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Template Body</v-list-subheader>
                            <div class="send-mail" :class="{'error-border': submit && v$.model.templateBody.$errors.length}">
                                <quill-editor ref="templateBody"  v-model:value="model.templateBody" :options="editorOption"/>
                            </div>
                            <div v-if="submit && v$.model.templateBody.$errors.length" class="error-message mt-2 ml-2">
                                Template Body is required
                            </div>
                        </v-col>
                    </v-row>
                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="primary"  class="mr-4" :loading="loading" :disabled="loading">
                            Submit
                        </v-btn>
                        <v-btn type="button" variant="outlined" color="primary" class="mr-4" :disabled="loading" @click="$router.push('/email-templates')">
                            Cancel
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions } from 'vuex';

import { EDITOR_OPTIONS } from '@/constants/editor-options';

export default {
    data: (vm) => ({
        emailTemplateId: vm.$route.params.emailTemplateId,
        editorOption: EDITOR_OPTIONS('the body template'),
        submit: false,
        loading: false,
        model: {
            templateName: "",
            templateBody: ""
        },
        v$: useVuelidate(),
    }),
    validations: {
        model: {
            templateName: { required},
            templateBody: { required}
        }
    },
    created() {
        if (this.emailTemplateId != 'create') {
          this.getEmailTempModel(this.emailTemplateId).then(response => this.model = response);
        }
    },
    methods: {
        ...mapActions(['getEmailTempModel', 'createEmailTempModel', 'updateSelectedEmailTemp']),

        saveTemplate() {
            this.submit = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return;
            }
            this.submit = false;
            this.loading = true;
            if (this.emailTemplateId != 'create') {
                this.updateSelectedEmailTemp(this.model).then(resp => this.$router.push("/email-templates"))
                    .catch(err => this.loading = false);
            } else {
                this.createEmailTempModel(this.model).then(resp => this.$router.push("/email-templates"))
                    .catch(err => this.loading = false);
            }
        },
    },
};
</script>
