import http from '@/http';

class roles {
    rolesList(data) {
        return http('user').get('roles/lists', data);
    }
    roleModel(roleId) {
        return http('user').get(`roles/${roleId}`);
    }
    createRoleModel(data) {
        return http('user').post('roles', data);
    }
    updateRoleModel(data) {
        return http('user').put(`roles/${data.id}`, data);
    }
    deleteRoleModel(roleId) {
        return http('user').deleteReq(`roles/${roleId}`)
    }
}

export default new roles();