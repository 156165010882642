<template>
  <div>
    <v-list-subheader v-if="item.divider" class="text-12 text-uppercase">{{
      item.divider
    }}</v-list-subheader>

    <v-list-group
      :href="href"
      :rel="href && href !== '#' ? 'noopener' : undefined"
      :target="href && href !== '#' ? '_blank' : undefined"
      :to="{ name: item.to }"
      :active-class="` ${
        isDark ? ' text-grey text-lighten-5' : 'text-primary'
      }`"
      :height="48"
      class="text-18 base-item pl-6"
      v-ripple="{ class: 'text-primary' }"
      slot:prepend
    >
      <v-icon
        v-if="text"
        class="v-list-item__icon--text"
        :icon="computedText"
      />

      <v-icon
        v-else-if="item.icon"
        class="text-16 vertical-sidebar-icon me-2"
        :color="isDark ? 'primary' : 'black'"
        :icon="item.icon"
      />

      <div
        class="pa-0 text-13 vertical-sidebar-text"
        v-if="item.title || item.subtitle"
      >
        <v-list-item-title class="ma-0 text-13">{{ item.title }} </v-list-item-title>

        <v-list-item-subtitle >{{ item.subtitle }}</v-list-item-subtitle>
      </div>
    </v-list-group>
  </div>
</template>

<script>
import { useTheme } from "vuetify";
import { mapGetters } from "vuex";

export default {
  name: "Item",

  setup() {
    const { isDark } = useTheme();

    return {
      isDark
    };
  },

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(["getThemeMode"]),
    computedText() {
      if (!this.item || !this.item.title) return "";

      let text = "";

      this.item.title.split(" ").forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
  },
};
</script>
<style lang="scss">
.base-item {
  .v-list-item__icon {
    margin-right: 0.9375rem !important;
  }
}
</style>
