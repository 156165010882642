export default {
    setQuoteDetailLoading(state, data) {
        state.quoteDetailLoading = data;
    },
    setQuoteDetails(state, data) {
        state.quoteDetails = data;
        state.quoteDetailLoading = false;
    },
    setQuoteLoading(state, data) {
        state.quoteLoading = data;
    },
}