<template>
  <div id="users-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" divider=">" class="pl-0 config-breadcrumbs mt-n15">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="!item.disabled && $router.push(item.href)"
          :class="breadcrumbList.length > 1 && item.disabled ? 'text-secondary' : 'text-primary'">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <user-form v-if="userId" :userId="userId" />
    <user-home v-else />
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';

import userForm from "../users/components/UserForm.vue";
import userHome from "../users/components/UserHome.vue";

export default {
  name: "Users",
  setup() {
    useHead({
      title: "Users",
    })
  },
  components: {
    userForm,
    userHome,
  },
  data: (vm) => ({
    userId: vm.$route.params.userId,
    breadcrumbList: [{
      text: 'Users',
      disabled: true,
      href: '/users',
    }]
  }),
  watch: {
    $route(newVal) {
      this.userId = newVal.params.userId;
      this.setBreadCrumb();
    },
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.userId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.userId == 'create' ? 'Create' : 'Update'} User`,
          disabled: true,
          href: `/users/${this.userId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Users',
          disabled: true,
          href: '/users',
        }]
      }
    }
  }
};
</script>
