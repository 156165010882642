import { createStore } from 'vuex';

import themeConfig from '@/store/themeConfig';
import commonModules from '@/store/common-modules';
import modules from '@/store/modules';

// Create the store
export const store = createStore({
  modules: {
    themeConfig,
    commonModules,
    modules
  }
});
