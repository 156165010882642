export default {
    setScanLoading(state, data) {
        state.scanLoading = data;
    },
    setMetaArchLoading(state, data) {
        state.metaArchLoading = data;
    },
    setArchMetaDetails(state, data) {
        state.archMetaDetails = data;
        state.metaArchLoading = false;
    },
    setAnnotationLoading(state, data) {
        state.annotateSaveLoading = data;
    },
}