<template>
  <v-card>
    <v-row class="ma-0 pa-3 pb-5 align-center justify-space-between">
      <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="d-flex pa-0">
        <router-link :to="{ name: 'Update Agent', params: { agentId : 'create'}}">
          <v-btn color="primary">
            <v-icon start>mdi-plus</v-icon>Create
          </v-btn>
        </router-link>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="primary d-flex pa-0 pt-4 pt-sm-0 pt-md-0 pt-lg-0 pt-xl-0 justify-end">
          <v-text-field v-model.trim="search" append-inner-icon="mdi-magnify" class="pt-0 w-full" label="" variant="underlined"
            placeholder="Search Agent" :disabled="loading" hide-details @update:model-value="searchDatatable"></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="tableItems"
      :items-length="totalItems"
      :loading="loading"
      class="elevation-1"
      hover
      @update:options="options = $event"
    >
      <template v-slot:[`item.logo`]="{item}">
        <v-sheet v-if="item.logo" rounded class="mx-auto" height="100" width="100">
          <v-img :src="item.logo" height="100%" width="100%"/>
        </v-sheet>
      </template>
      <template v-slot:[`item.action`]="{item}">
        <v-tooltip location="top" :disabled="!item.is_enabled">
            <template v-slot:activator="{ props }">
                <v-icon size="small" class="mr-2 cursor-pointer" color="warning" @click="$router.push(`/customer-portal/${item.id}`)"
                    v-bind="props"  v-if="item.is_enabled">mdi-cards</v-icon>
            </template>
            <span>View Customer Portal</span>
        </v-tooltip>
        <v-tooltip location="top">
            <template v-slot:activator="{ props }">
                <v-icon size="small" class="mr-2 cursor-pointer" color="primary" @click="$router.push(`/agents/${item.id}`)"
                    v-bind="props" >mdi-pencil
                </v-icon>
            </template>
            <span>Edit</span>
        </v-tooltip>
        <v-tooltip location="top">
            <template v-slot:activator="{ props }">
                <v-icon size="small" class="mr-2 cursor-pointer" color="danger" @click="notify=item.id" v-bind="props" >
                    mdi-trash-can
                </v-icon>
            </template>
            <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <Notify v-if="notify" @close="notify=null" :loading="deleteLoading" @saved="deleteAgentItem()"
      title="are you sure" success=", delete it!"/>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';

import { Notify } from "@/components/common";

export default {
  components: { Notify },
  data() {
    return {
      totalItems: 0,
      tableItems: [],
      search: '',
      loading: true,
      options: {},
      notify: null,
      deleteLoading: false,
      headers: [
          { title: 'Name', key: 'name' },
          { title: 'Logo', key: 'logo', align: 'center' },
          { title: 'Email', key: 'email' },
          { title: 'Phone', key: 'phone' },
          { title: 'ABN', key: 'abn' },
          {title: 'Action', key: 'action', sortable: false}
      ],
      pageLink: `${window.VUE_APP_MS_URL}agent/agents`,
      prevOptions: null,
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  created() {
    this.searchDatatable = _.debounce(this.searchDatatable, 500);
  },
  methods: {
    ...mapActions(['getModel', 'deleteAgent']),

    searchDatatable() { this.getDataFromApi('search') },
    getDataFromApi (type) {
      this.loading = true;
      this.getDTDetails(type).then(data => {
        this.tableItems = data.items;
        this.totalItems = data.total;
        this.loading = false;
      }).catch(err => {
        this.tableItems = [];
        this.totalItems = 0;
        this.loading = false;
      });
    },
    getDTDetails (type) {
      return new Promise((resolve, reject) => {
        const { sortBy, page, itemsPerPage } = this.options;
        const sortKey = sortBy.length ? sortBy[0].key : null;
        const sortOrder = sortBy.length ? sortBy[0].order : null;

        const endPoint = new URL(this.pageLink);
        endPoint.searchParams.set("per_page", itemsPerPage);
        endPoint.searchParams.set("page", page);
        if(type) endPoint.searchParams.set("page", 1);
        if(this.search !== '') {
          endPoint.searchParams.set("q", this.search);
        }
        this.getAPIdata(endPoint, this.options, type).then(resp => {
          let { items, total } = resp;
          if (sortKey && sortOrder) {
            items = items.sort((a, b) => {
              const sortA = a[sortKey];
              const sortB = b[sortKey];

              if (sortOrder === 'desc') {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            });
          }
          this.prevOptions = this.options;
          resolve({
            items,
            total,
          });

        }).catch(err => reject(err));
      });
    },
    getAPIdata(endPoint, options, type) {
      const { page, itemsPerPage } = options;
      return new Promise((resolve, reject) => {
        if (!type && this.prevOptions && this.prevOptions.page == page && this.prevOptions.itemsPerPage == itemsPerPage) {
            resolve({items: this.tableItems, total: this.totalItems});
            return true;
        }
        this.getModel(endPoint.toString()).then(resp => {
          resolve(resp);
        }).catch(err => reject(err));
      });
    },
    deleteAgentItem() {
      this.deleteLoading = true;
      this.deleteAgent(this.notify).then(() => {
        this.notify = null;
        this.deleteLoading = false;
        this.getDataFromApi('delete');
      }).catch(err => this.deleteLoading = false);
    }
  },
};
</script>
