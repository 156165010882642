<template>
  <div class="range-calendar position-fixed w-100">
    <vue-date-picker class="date-picker-range d-grid w-full" v-model="date" range inline hide-offset-dates
      :is-24="false" no-today auto-apply @update:model-value="!reseted && dateChange()">
      <template #calendar-header="{ index, day }">
        <div>
          {{ customHeader[index] }}
        </div>
      </template>
      <template #time-picker="{ time, updateTime }">
        <div class="start-date-time-picker d-flex align-items-center ms-2">
          <div class="clock-icon">
            <v-icon right size="x-small">mdi-clock-time-four-outline</v-icon>
          </div>
          <div class="inline-time">
            <div class="header-start" v-html="formattedStartDate"></div>
            <div class="custom-time-picker-component">
              <select class="select-input" v-model="startHours" @change="updateStartTime($event.target.value)">
                <option v-for="h in hoursArray" :key="h.value" :model-value="h.value">{{ h.text }}</option>
              </select>
              <div>:</div>
              <select class="select-input" v-model="startMinutes" @change="updateStartTime(null, $event.target.value)">
                <option v-for="m in minutesArray" :key="m.value" :model-value="m.value">{{ m.text }}</option>
              </select>
              <!-- AM/PM toggle button for start time -->
              <div class="toggle-am-pm">
                <button class="toggle-button" :class="{ active: startAMPM === 'AM' }"
                  @click="toggleAMPM('start', 'AM')">
                  AM
                </button>
                <button class="toggle-button" :class="{ active: startAMPM === 'PM' }"
                  @click="toggleAMPM('start', 'PM')">
                  PM
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="line_separator mb-1 mt-1 mx-2"></div>
        <div class="end-date-time-picker d-flex align-items-center ms-2">
          <div class="clock-icon">
            <v-icon right size="x-small">mdi-clock-time-four-outline</v-icon>
          </div>
          <div class="inline-time">
            <div class="header-start" v-html="formattedEndDate"></div>
            <div class="custom-time-picker-component">
              <select class="select-input" v-model="endHours" @change="updateEndTime($event.target.value)">
                <option v-for="h in hoursArray" :key="h.value" :model-value="h.value">{{ h.text }}</option>
              </select>
              <div>:</div>
              <select class="select-input" v-model="endMinutes" @change="updateEndTime(null, $event.target.value)">
                <option v-for="m in minutesArray" :key="m.value" :model-value="m.value">{{ m.text }}</option>
              </select>
              <!-- AM/PM toggle button for end time -->
              <div class="toggle-am-pm">
                <button class="toggle-button" :class="{ active: endAMPM === 'AM' }" @click="toggleAMPM('end', 'AM')">
                  AM
                </button>
                <button class="toggle-button" :class="{ active: endAMPM === 'PM' }" @click="toggleAMPM('end', 'PM')">
                  PM
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="px-2 py-3">
          <v-btn class="w-100" dark color="primary" @click="handleClose">
            Ok
          </v-btn>
        </div>
      </template>
    </vue-date-picker>
  </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
  props: ['initialDate', 'modelValue'],
  emits: ['close', 'save', 'remove', 'update:modelValue'],
  data() {
    return {
      date: this.initialDate && this.initialDate.length === 2
        ? [...this.initialDate]
        : [new Date(), new Date()],
      model: {
        ts: this.modelValue[0] || null,
        te: this.modelValue[1] || null,
      },
      startHours: 12,
      startMinutes: '00',
      startAMPM: 'AM',
      endHours: 11,
      endMinutes: 59,
      endAMPM: 'PM',
      customHeader: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
      hoursArray: [...Array(12).keys()].map(i => ({ value: (i + 1).toString().padStart(2, '0'), text: (i + 1).toString().padStart(2, '0') })),
      minutesArray: [...Array(60).keys()].map(i => ({ value: i.toString().padStart(2, '0'), text: i.toString().padStart(2, '0') })),
    }
  },
  computed: {
    formattedStartDate() {
      return this.formatDate(this.date[0]) || 'Select Start Date';
    },
    formattedEndDate() {
      return this.formatDate(this.date[1]) || 'Select End Date';
    },
  },
  watch: {
    modelValue: {
      handler(newValue) {
        if (newValue && newValue.length === 2) {
          const [startDate, endDate] = newValue;

          // Check if the dates are valid before setting them
          if (moment(startDate).isValid() && moment(endDate).isValid()) {
            this.date = [...newValue];
          } else {
            this.date = [new Date(), new Date()];
          }
        } else {
          // If no valid date range, set default dates
          this.date = [new Date(), new Date()];
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    formatDate(date) {
      if (date) {
        const formattedDate = this.$helpers.getMomentDatas('ddd MMM DD YYYY', date);
        const [dayOfWeek, month, day, year] = formattedDate.split(' ');
        return `
                    <span style="color: #4a5568;">${dayOfWeek}</span>
                    <span style="color: #3182CE;">${month}</span>
                    <span style="color: #3182CE;">${day}</span>
                    <span style="color: #a0aec0;">${year}</span>
                `;
      }
      return 'Select Date';
    },
    toggleAMPM(type, value) {
      if (type === 'start') {
        this.startAMPM = value;
        this.updateStartTime();
      } else if (type === 'end') {
        this.endAMPM = value;
        this.updateEndTime();
      }
    },
    updateStartTime(hours = this.startHours, minutes = this.startMinutes) {
      if (this.date.length > 0) {
        const startDate = this.date[0];
        let momentStartDate = moment(startDate).tz('Australia/Melbourne');
        const isPM = this.startAMPM === 'PM';
        const hourValue = parseInt(hours) === 12 ? (isPM ? 12 : 0) : (isPM ? parseInt(hours) + 12 : parseInt(hours));
        momentStartDate = momentStartDate.set({
          hour: hourValue,
          minute: parseInt(minutes)
        });
        this.date[0] = momentStartDate.toDate();
        this.model.ts = this.$helpers.getMomentDatas('YYYY-MM-DD HH:mm:ss', momentStartDate);
        this.$emit('update:modelValue', [this.model.ts, this.model.te]);
      }
    },
    updateEndTime(hours = this.endHours, minutes = this.endMinutes) {
      if (this.date.length > 1) {
        const endDate = this.date[1];
        let momentEndDate = moment(endDate).tz('Australia/Melbourne');
        const isPM = this.endAMPM === 'PM';
        momentEndDate = momentEndDate.set({
          hour: isPM ? parseInt(hours) + 12 : parseInt(hours),
          minute: parseInt(minutes)
        });
        this.date[1] = momentEndDate.toDate();
        this.model.te = this.$helpers.getMomentDatas('YYYY-MM-DD HH:mm:ss', momentEndDate);
        this.$emit('update:modelValue', [this.model.ts, this.model.te]);
      }
    },
    dateChange() {
      if (this.date.length === 2) {
        this.updateStartTime();
        this.updateEndTime();

        this.$emit('update:modelValue', [this.model.ts, this.model.te]);
      }
    },
    handleClose() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.custom-time-picker-component {
  display: flex;
  align-items: center;
  justify-content: left;
}

.select-input {
  margin: 5px 3px;
  padding: 5px;
  width: 100px;
  border-radius: 4px;
  border-color: var(--dp-border-color);
  outline: none;
  -webkit-appearance: menulist;
}

.header-start {
  margin-left: 10px;
  font-size: 14px !important;
}

select.select-input {
  width: 52px;
  height: 30px;
  background-color: #edf2f7 !important;
  color: black !important;
}

.toggle-am-pm {
  display: flex;
  padding: 4px;
  border-radius: 5px;
  background-color: #edf2f7;
}

.toggle-button {
  padding: 2px 5px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-button.active {
  background-color: #3182ce;
  color: white;
  border-radius: 3px;
}

.toggle-button:not(.active):hover {
  background-color: #e9ecef;
}
</style>