<template>
  <v-row id="panel-groups-form-page">
    <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-form ref="form" lazy-validation @submit.prevent="savePanelGroup">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" lg="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Name</v-list-subheader>
                            <v-text-field v-model="model.name" :error-messages="v$.model.name.$errors.map(e => e.$message)"
                                placeholder="Name" variant="outlined" ref="name" @update:model-value="v$.model.name.$touch()"  @blur="v$.model.name.$touch()" required />
                        </v-col>
                        <v-col cols="12" lg="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1">Upload Image
                                <span v-if="previewImage" class="mx-2">( Previous Uploaded Image:
                                    <span class="text-info cursor-pointer" @click="viewImage">
                                        <span class="text-capitalize">{{previewImage.name}}</span>.jpg
                                    </span> )
                                </span>
                            </v-list-subheader>
                            <v-text-field readonly v-model="model.image" :error-messages="v$.model.image.$errors.map(e => e.$message)"
                                placeholder="Upload Image" class="cursor-pointer" append-inner-icon="mdi-file-upload" variant="outlined" ref="image" @update:model-value="v$.model.image.$touch()"  @blur="v$.model.image.$touch()"
                                 @click="$refs.file.click()" required>
                            </v-text-field>
                            <input type="file" accept="image/*" style="display: none" ref="file"  @change="uploadedFile" />
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" lg="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Position</v-list-subheader>
                            <v-text-field type="number" ref="position" v-model.number="model.position"
                                :error-messages="v$.model.position.$errors.map(e => e.$message)"
                                placeholder="Position" variant="outlined" @update:model-value="v$.model.position.$touch()"  @blur="v$.model.position.$touch()" required/>
                        </v-col>
                    </v-row>
                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="primary"  class="mr-4">
                            Submit
                        </v-btn>
                        <v-btn type="button" variant="outlined" color="primary" class="mr-4" @click="$router.push('/panelgroups')">
                            Cancel
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";
import { mapActions } from 'vuex';

export default {
    data: (vm) => ({
        panelGroupId: vm.$route.params.panelGroupId,
        model: {
            name: '',
            image: '',
            position: '',
        },
        file: '',
        previewImage: null,
        v$: useVuelidate(),
    }),
    validations() {
        return {
            model: {
                name: { required},
                position: { required, minValue: minValue(1)},
                image: { required},
            }
        }
    },
    created() {
        if (this.panelGroupId != 'create') {
          this.getSelectedPanelGroup(this.panelGroupId).then(response => {
            if (response && response.image) {
                this.previewImage = {
                    name: (response.name).toLowerCase(),
                    image: response.image
                };
                response.image = '';
            }
            this.model = response;
        });
        }
    },
    methods: {
        ...mapActions(['getSelectedPanelGroup', 'createPanelGroup', 'updateSelectedPanelGroup']),

        viewImage() {
            const image = [];
            image.push(this.previewImage.image);
            this.$viewerApi({
                images: image
            })
        },
        uploadedFile(evt) {
            const files = evt.target.files[0]
            if (files == undefined) this.model.image = '';
            else {
                this.file = files;
                this.model.image = files.name;
                this.previewImage = null;
            }
        },
        async savePanelGroup() {
            this.v$.$touch();
            if (this.v$.$invalid) {
                this.$helpers.focusErrorElement(this.v$.model, this.$refs);
                return;
            }
            const reqData = new FormData(),
                headers =  { 'Content-Type': 'multipart/form-data' };
            Object.keys(this.model).forEach(key => {
                if (key != 'image') reqData.append(key, this.model[key]);
                else reqData.append('imageFile', this.file);
            });
            if (this.panelGroupId != 'create') {
                const updateReq = {
                    id: this.model.id,
                    data: reqData
                }
                this.updateSelectedPanelGroup(updateReq, headers).then(resp => this.$router.push("/panelgroups"));
            } else {
                this.createPanelGroup(reqData, headers).then(resp => this.$router.push("/panelgroups"));
            }
        },
    },
};
</script>
