export default {
    setPanelGroupLoading(state, data) {
        state.panelGroupLoading = data;
    },
    setPanelGroupsListLoading(state, data) {
        state.panelGroupsListLoading = data;
    },
    setPanelGroupsList(state, data) {        
        state.panelGroupsList = data;
        state.panelGroupsListLoading = false;
    },
    setPanelGroupsPosLoading(state, data) {
        state.panelGroupsPosLoading = data;
    },
    setPanelGroupsPosition(state, data) {
        state.panelGroupsPosition = data;
        state.panelGroupsPosLoading = false;
    },
}