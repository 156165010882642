import devices from '@/store/modules/devices/api';

export default {
    getSelectedDevice({ commit }, id) {
        commit("setDeviceLoading", true);
        return new Promise((res, rej) => {
            devices.deviceModel(id).then(response => {
                commit("setDeviceLoading", false);
                res(response);
            }).catch((error) => {
                commit("setDeviceLoading", false);
                rej(error)
            });
        });
    },
    createDevice({ commit }, data) {
        commit("setDeviceLoading", true);
        return new Promise((res, rej) => {
            devices.createDeviceModel(data).then(response => {
                commit("setDeviceLoading", false);
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Device Created Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setDeviceLoading", false);
                rej(error)
            });
        });
    },
    updateSelectedDevice({ commit }, data) {
        commit("setDeviceLoading", true);
        return new Promise((res, rej) => {
            devices.updateDeviceModel(data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Device Updated Successfully",
                    color: "success",
                });
                commit("setDeviceLoading", false);
                res(response);
            }).catch((error) => {
                commit("setDeviceLoading", false);
                rej(error)
            });
        });
    },
    deleteDevice({ commit }, deviceId) {
        return new Promise((res, rej) => {
            devices.deleteDeviceModel(deviceId).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Device Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    }
}