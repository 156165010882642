import http from '@/http';

class email {    
    sendMail(data = {}) {
        return http('email').post(`emails`, data)
    }
    createModel(endPoint, data = {}) {
        return http('email').post(`${endPoint}`, data);
    }
    getModel(endPoint) {
        return http('email').get(endPoint);
    }
    updateModel(endPoint, data) {
        return http('email').put(`${endPoint}/${data.id}`, data);
    }
    deleteModel(endPoint) {
        return http('email').deleteReq(endPoint);
    }
}

export default new email();