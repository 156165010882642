<template>
    <v-card class="pb-5">
      <v-row class="ma-0 bg-primary pa-3 px-4" no-gutters>
          <v-col cols="6" sm="4" md="4" lg="6" xl="6" class="mb-3 mb-sm-0 mb-md-0 mb-xl-0 mb-lg-0">
              <v-toolbar-title class="text-white">Webhooks</v-toolbar-title>
          </v-col>
          <v-col cols="6" sm="8" md="8" lg="6" xl="6" class="d-flex flex-wrap align-center justify-end">
              <v-btn color="white" @click="openModal='create'">
                  <v-icon start>mdi-plus</v-icon>Add endpoint
              </v-btn>
              <v-btn color="white" variant="text" size="small" icon class="ml-3" @click="searchable=false;search='';getDataFromApi('refresh')"
                  :disabled="loading">
                  <v-icon :size="25" :class="{'icon-loader': loading}">mdi-sync-circle</v-icon>
              </v-btn>
          </v-col>
      </v-row>
      <v-row class="ma-0 mx-3 mt-5 mb-2 align-center">
        <v-col cols="12" class="d-flex justify-end">
          <div class="w-45">
            <v-text-field v-model.trim="search" append-inner-icon="mdi-magnify" class="pt-0"
              label="" placeholder="Search Webhook" :disabled="loading" hide-details
              @update:model-value="searchDatatable" variant="underlined"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-data-table
          :headers="headers"
          :items="tableItems"
          :items-length="totalItems"
          :loading="loading"
          class="mx-5"
          hover
          @update:options="options = $event"
      >
          <template v-slot:[`item.endpoint`]="{item}">
              <a :href="item.endpoint" target="_blank" class="text-decoration-none">
                  {{ item.endpoint }}
              </a>
          </template>
          <template v-slot:[`item.events`]="{item}">
              <template v-for="event in item.events" :key="event">
                  <v-chip size="small" class="mr-2 bg-indigo-lighten-2">
                      {{ event }}
                  </v-chip>
              </template>
          </template>
          <template v-slot:[`item.secret`]="{item}">
              <v-tooltip location="top" attach :disabled="!item.secret">
                  <template v-slot:activator="{ props }">
                      <v-chip v-if="item.secret" :color="item.copyTxt ? 'success':''" :class="item.copyTxt ? 'bg-success-outline':'bg-info'" size="small" label :variant="item.copyTxt ? 'outlined' : null"
                          @click="toogleCopyTxt(item, `${item.id}-secret`)" :ref="`${item.id}-secret`" :id="`${item.id}-secret`"
                          v-bind="{ ...props, ref: `${item.id}-secret` }">
                          {{ item.secret }}
                          <v-icon size="13" class="ml-2" @click="toogleCopyTxt(item, `${item.id}-secret`)">mdi-content-copy</v-icon>
                      </v-chip>
                  </template>
                  <span>{{ item.copyTxt ? 'Copied!' : 'Copy' }}</span>
              </v-tooltip>
          </template>
          <template v-slot:[`item.action`]="{item}">
              <v-icon size="small" class="mr-2 cursor-pointer" color="primary" @click="openModal=item.id">
                  mdi-pencil
              </v-icon>
              <v-icon size="small" class="mr-2 cursor-pointer" color="danger" @click="notify=item.id">
                  mdi-trash-can
              </v-icon>
          </template>
      </v-data-table>
      <Notify v-if="notify" @close="notify=null" :loading="deleteLoading" @saved="deleteWebhookItem()"
        title="are you sure" success=", delete it!"/>
      <webhook-modal v-if="openModal" :open="openModal" @close="openModal=null" @save="openModal=null;getDataFromApi('saved');"/>
    </v-card>
  </template>

  <script>
  import { mapActions } from 'vuex';
  import _ from 'lodash';

  import { Notify } from "@/components/common";
  import WebhookModal from "./WebhookForm.vue";

  export default {
      props: ['agentId'],
      components: {
          Notify,
          WebhookModal,
      },
      data() {
          return {
              totalItems: 0,
              tableItems: [],
              search: '',
              loading: true,
              options: {},
              notify: null,
              deleteLoading: false,
              searchable: false,
              headers: [
                  { title: 'Endpoints', key: 'endpoint' },
                  { title: 'Events', key: 'events' },
                  { title: 'Secret', key: 'secret' },
                  { title: 'Action', key: 'action', sortable: false}
              ],
              pageLink: `${window.VUE_APP_MS_URL}agent/webhooks?agent_id=${this.agentId}`,
              prevOptions: null,
              openModal: null
          }
      },
      watch: {
          options: {
              handler () {
              this.getDataFromApi();
              },
              deep: true,
          }
      },
      created() {
          this.searchDatatable = _.debounce(this.searchDatatable, 500);
      },
      methods: {
          ...mapActions(['getModel', 'deleteWebhook']),

          searchDatatable() { this.getDataFromApi('search') },
          getDataFromApi (type) {
              this.loading = true;
              this.getDTDetails(type).then(data => {
                  this.tableItems = data.items.map(item => {
                      item.copyTxt = false;
                      return item;
                  });
                  this.totalItems = data.total;
                  this.loading = false;
              }).catch(err => {
                  this.tableItems = [];
                  this.totalItems = 0;
                  this.loading = false;
              });
          },
          getDTDetails (type) {
            return new Promise((resolve, reject) => {
                const { sortBy, page, itemsPerPage } = this.options;
                const sortKey = sortBy.length ? sortBy[0].key : null;
                const sortOrder = sortBy.length ? sortBy[0].order : null;

                const endPoint = new URL(this.pageLink);
                endPoint.searchParams.set("per_page", itemsPerPage);
                endPoint.searchParams.set("page", page);
                if(type) endPoint.searchParams.set("page", 1);
                if(this.search !== '') {
                endPoint.searchParams.set("q", this.search);
                }
                this.getAPIdata(endPoint, this.options, type).then(resp => {
                let { items, total } = resp;
                if (sortKey && sortOrder) {
                    items = items.sort((a, b) => {
                    const sortA = a[sortKey];
                    const sortB = b[sortKey];

                    if (sortOrder === 'desc') {
                        if (sortA < sortB) return 1
                        if (sortA > sortB) return -1
                        return 0
                    } else {
                        if (sortA < sortB) return -1
                        if (sortA > sortB) return 1
                        return 0
                    }
                    });
                }
                this.prevOptions = this.options;
                resolve({
                    items,
                    total,
                });

                }).catch(err => reject(err));
            });
          },
          getAPIdata(endPoint, options, type) {
              const { page, itemsPerPage } = options;
              return new Promise((resolve, reject) => {
                  if (!type && this.prevOptions && this.prevOptions.page == page && this.prevOptions.itemsPerPage == itemsPerPage) {
                      resolve({items: this.tableItems, total: this.totalItems});
                      return true;
                  }
                  this.getModel(endPoint.toString()).then(resp => {
                      resolve(resp);
                  }).catch(err => reject(err));
              });
          },
          toogleCopyTxt(item, refName) {
              if (!item.copyTxt) {
                  this.$helpers.copyTextFunc(this.$refs[refName], 'noInput');
                  item.copyTxt = true;
                  setTimeout(() => {
                      item.copyTxt = false;
                  }, 5000);
              }
          },
          deleteWebhookItem() {
              this.deleteLoading = true;
              this.deleteWebhook(this.notify).then(() => {
                  this.notify = null;
                  this.deleteLoading = false;
                  this.getDataFromApi('delete');
              }).catch(err => this.deleteLoading = false);
          },
      },
  };
  </script>
