<template>
    <v-card elevation="3">
        <v-list shaped>
            <div class="d-flex align-center justify-space-between mr-2 ml-3 my-2">
                <div density="compact" v-text="field" class="mr-2 font-weight-bold text-capitalize"></div>
                <div density="compact" v-text="data[`${field}_total`]" class="font-weight-bold"></div>
            </div>
            <v-list-item
                v-for="(item, i) in data.tooltip"
                :key="i"
                :value="item.value"
                density="compact"
                class="pr-0 pl-2"
            >
                <template #prepend>
                    <v-icon :color="item.color" :icon="item.icon" class="ma-0 pa-0"></v-icon>
                </template>
                <div class="d-flex align-center justify-space-between">
                    <div density="compact" v-text="item.text" class="mr-2"></div>
                    <div density="compact" v-text="item[field]" class="mr-2"></div>
                </div>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
    export default {
        props: {
            field: {
                type: String,
                default: 'dent'
            },
            data: {
                type: Object,
                default: () => ({})
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>