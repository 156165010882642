import http from '@/http';

class scans {
    scansList(data) {
        return http('scan').get('scans', data);
    }
    getScanMetaData(endPoint) {
        return http('scan').get(`meta/${endPoint}`);
    }
    getscanModel(endPoint, data = {}) {
        return http('scan').get(`${endPoint}`, data);
    }
    scanModel(scanId, data = {}) {
        return http('scan').get(`scans/${scanId}`, data);
    }
    createModel(endPoint, data = {}) {
        return http('scan').post(`${endPoint}`, data);
    }
    updateScanModel(data) {
        return http('scan').put(`scans/${data.id}`, data);
    }
    editDamageCount(data) {
        return http('scan').post('/scans/arch/edit-damage-count', data);
    };
    deleteScanModel(endPoint) {
        return http('scan').deleteReq(`scans/${endPoint}`)
    }
}

export default new scans();