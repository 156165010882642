export default {
    setRoleLoading(state, data) {
        state.roleLoading = data;
    },
    setRolesListLoading(state, data) {
        state.rolesListLoading = data;
    },
    setRolesList(state, data) {        
        state.rolesList = data;
        state.rolesListLoading = false;
    }
}