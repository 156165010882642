<template>
  <div>
    <v-list-subheader v-if="item.divider" class="text-12 mt-8 text-uppercase"> {{item.divider}} </v-list-subheader>
    <v-list-item 
      :to="`/${item.to}`"
      @click="sidebarClose(item)"
      :active-class="` ${isDark ? 'text-primary ' : 'text-black'}`"
      :height="48"
      class="text-18 base-item"
      :class="isDark ? 'text-primary ' : 'text-black'"
      v-ripple="{ class: 'text-primary' }"
    >
    <template v-slot:prepend>
      <div v-if="text">
        <v-icon v-if="text" class="v-list-item__icon--text" :icon="computedText" />
      </div>
      <div v-if="item.icon">
        <v-icon class="text-16 vertical-sidebar-icon" size="small" :color="isDark ? 'primary ' : 'black'" :icon="item.icon" />
      </div>
    </template>
      <div class="text-13 vertical-sidebar-text" v-if="item.title || item.subtitle">
        <v-list-item-title class="text-13 ms-2">{{ item.title }}</v-list-item-title>
        <v-list-item-subtitle class="text-13 ms-2">{{ item.subtitle }}</v-list-item-subtitle>
      </div>
    </v-list-item>
  </div>
</template>

<script>
import { useTheme } from "vuetify";
import { mapActions } from "vuex";

export default {
  name: "ItemTwo",

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { isDark } = useTheme();

    return {
      isDark
    };
  },

  computed: {
    computedText() {
      if (!this.item || !this.item.title) return "";

      let text = "";

      this.item.title.split(" ").forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
  },
  methods: {
    ...mapActions(["changeVerticalSidebarDrawer"]),
    sidebarClose(item) {
      if (item.to && ['home', 'lp-entry', 'producer', 'onair', 'staff', 'tally', 'sound-effect'].indexOf(item.to) !== -1) {
        this.changeVerticalSidebarDrawer();
      }
    }
  }
};
</script>
<style lang="scss">
</style>
