export default {
    setWebhookLoading(state, data) {
        state.webhookLoading = data;
    },
    setWebEventsListLoading(state, data) {
        state.webEventsListLoading = data;
    },
    setWebEventList(state, data) {        
        state.webEventsList = data;
        state.webEventsListLoading = false;
    }
}