import http from '@/http';

class panels {
    panelsList(data) {
        return http('quote').get('panels/lists', data);
    }
    panelModel(panelId) {
        return http('quote').get(`panels/${panelId}`);
    }
    createPanelModel(data) {
        return http('quote').post('panels', data);
    }
    updatePanelModel(data) {
        return http('quote').put(`panels/${data.id}`, data);
    }
    deletePanelModel(panelId) {
        return http('quote').deleteReq(`panels/${panelId}`)
    }
}

export default new panels();