<template>
  <div id="permissions-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" divider=">" class="pl-0 config-breadcrumbs mt-n15">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="!item.disabled && $router.push(item.href)"
          :class="breadcrumbList.length > 1 && item.disabled ? 'text-secondary' : 'text-primary'">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <permission-form v-if="permissionId" />
    <permission-home v-else />
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';

import permissionForm from "../permissions/components/PermissionForm.vue";
import permissionHome from "../permissions/components/PermissionHome.vue";

export default {
  name: "Permissions",
  setup() {
    useHead({
      title: "Permissions",
    })
  },
  components: {
    permissionForm,
    permissionHome,
  },
  data: (vm) => ({
    permissionId: vm.$route.params.permissionId,
    breadcrumbList: [{
      text: 'Permissions',
      disabled: true,
      href: '/permissions',
    }]
  }),
  watch: {
    $route(newVal) {
      this.permissionId = newVal.params.permissionId;
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.permissionId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.permissionId == 'create' ? 'Create' : 'Update'} Permission`,
          disabled: true,
          href: `/permissions/${this.permissionId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Permissions',
          disabled: true,
          href: '/permissions',
        }]
      }
    }
  }
};
</script>
