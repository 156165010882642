export default {
    setPermissionLoading(state, data) {
        state.permissionLoading = data;
    },
    setPermissionsListLoading(state, data) {
        state.permissionsListLoading = data;
    },
    setPermissionsList(state, data) {        
        state.permissionsList = data;
        state.permissionsListLoading = false;
    }
}