import locations from '@/store/modules/locations/api';

export default {
    getLocationLists({ commit }) {
        commit("setLocationsListLoading", true);
        return new Promise((res, rej) => {
            locations.locationsList().then(response => {
                commit("setLocationsList", response);
                res(response);
            }).catch((error) => {
                commit("setLocationsListLoading", false);
                rej(error)
            });
        });
    },
    getSelectedLocation({ commit }, id) {
        commit("setLocationLoading", true);
        return new Promise((res, rej) => {
            locations.locationModel(id).then(response => {
                commit("setLocationLoading", false);
                res(response);
            }).catch((error) => {
                commit("setLocationLoading", false);
                rej(error)
            });
        });
    },
    createLocation({ commit }, data) {
        commit("setLocationLoading", true);
        return new Promise((res, rej) => {
            locations.createLocationModel(data).then(response => {
                commit("setLocationLoading", false);
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Location Created Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setLocationLoading", false);
                rej(error)
            });
        });
    },
    updateSelectedLocation({ commit }, data) {
        commit("setLocationLoading", true);
        return new Promise((res, rej) => {
            locations.updateLocationModel(data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Location Updated Successfully",
                    color: "success",
                });
                commit("setLocationLoading", false);
                res(response);
            }).catch((error) => {
                commit("setLocationLoading", false);
                rej(error)
            });
        });
    },
    deleteLocation({ commit }, locationId) {
        return new Promise((res, rej) => {
            locations.deleteLocationModel(locationId).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Location Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    }
}