<template>
    <v-dialog :model-value="dialogVisible" scrollable max-width="700px" persistent >
        <v-card>
            <v-card-title class="bg-primary text-white font-weight-medium">Create Webhook</v-card-title>
            <v-divider></v-divider>
            <v-row class="ma-0 w-100 align-center justify-center" v-if="getLoading" style="height: 400px;">
                <div class="setting-spinner-loader"></div>
            </v-row>
            <v-form lazy-validation @submit.prevent="onSubmit" v-else>
                <v-card-text style="max-height: 500px;">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" >
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto text-capitalize">URL</v-list-subheader>
                            <v-text-field v-model="model.endpoint" :error-messages="v$.model.endpoint.$errors.map(e => e.$message)"
                                placeholder="Enter Endpoint URL" variant="outlined" ref="endpoint" @update:model-value="v$.model.endpoint.$touch()"  @blur="v$.model.url.$touch()"
                                required hide-details="auto"/>
                        </v-col>
                        <v-col cols="12">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto text-capitalize">Description</v-list-subheader>
                            <v-textarea hide-details="auto" class="mb-3" auto-grow
                                rows="4" v-model="model.description" row-height="20" placeholder="Description" variant="outlined"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" v-if="agentId && model.secret" class="d-flex">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto text-capitalize">Secret:</v-list-subheader>
                            <v-tooltip location="top">
                                <template v-slot:activator="{ props }">
                                    <v-chip :color="model.copyTxt? 'success':''" :class="model.copyTxt ? 'bg-success-outline':'bg-info'" size="small" label :variant="model.copyTxt ? 'outlined' : null" id="copy-text"
                                    @click="toogleCopyTxt(model, `${model.id}-secret`)" :ref="`${model.id}-secret`" :ripple="false" class="ml-2"
                                    v-bind="{ ...props, ref: `${model.id}-secret` }">
                                        {{ model.secret }}
                                        <v-icon size="13" class="ml-2" @click="toogleCopyTxt(model, `${model.id}-secret`)">mdi-content-copy</v-icon>
                                    </v-chip>
                                </template>
                                <span>{{model.copyTxt ? 'Copied!' : 'Copy'}}</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="12">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto text-capitalize">Events</v-list-subheader>
                            <v-autocomplete v-model="model.events" :items="webEventsList" item-title="text" item-value="value" :error-messages="v$.model.events.$errors.map(e => e.$message)"
                                placeholder="Select Event" variant="outlined" ref="events" @update:model-value="v$.model.events.$touch()"  @blur="v$.model.events.$touch()"
                                required hide-details="auto" multiple small-chips>
                                <template v-slot:chip="{ item, index }">
                                    <v-chip closable  @click:close="model.events.splice(index, 1)">
                                        <span class="text-capitalize">{{ item.title }}</span>
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="pa-5">
                    <v-spacer />
                    <v-btn color="primary" type="button" class="mr-4" variant="outlined" @click="$emit('close')"
                        :disabled="loading">
                        Cancel
                    </v-btn>
                    <v-btn class="bg-primary" type="submit" :loading="loading">Submit</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, url } from "@vuelidate/validators";

export default {
    props: ['open'],
    emits: ['close', 'save'],
    data: (vm) => ({
        agentId: vm.$route.params.agentId,
        model: {},
        getLoading: false,
        loading: false,
        copyTxt: false,
        v$: useVuelidate(),
    }),
    validations() {
        return {
            model: {
                endpoint: { required, url},
                events: { required },
            }
        }
    },
    computed: {
        ...mapGetters([ 'webEventsList']),

        dialogVisible() {
            return !!this.open; // Converts the value to a Boolean
        },
    },
    created() {
        this.getWebEventLists();
        if(this.open && this.open != 'create') this.getModel();
    },
    methods: {
        ...mapActions(['getSelectedWebhook', 'createWebhook', 'updateSelectedWebhook', 'getWebEventLists']),

        getModel() {
            const self = this;
            this.getLoading = true;
            this.getSelectedWebhook(this.open).then(response => {
                self.model = response;
                self.getLoading = false;
            }).catch(err => self.getLoading = false);
        },
        toogleCopyTxt(model, refName) {
            if (!model.copyTxt) {
                this.$helpers.copyTextFunc(this.$refs[refName], 'noInput');
                model.copyTxt = true;
                setTimeout(() => {
                    model.copyTxt = false;
                }, 5000);
            }
        },
        onSubmit() {
            this.v$.$touch();
            if (this.v$.$invalid) {
                this.$helpers.focusErrorElement(this.v$.model, this.$refs);
                return;
            }
            const self = this;
            this.loading = true;
            this.model.agent_id = this.agentId;
            if (this.open != 'create') {
                this.updateSelectedWebhook(this.model).then(resp => {
                    self.loading = false;
                    self.$emit('save');
                }).catch(err => self.loading = false);
            } else {
                this.createWebhook(this.model).then(resp => {
                    self.loading = false;
                    self.$emit('save');
                }).catch(err => self.loading = false);
            }
        }
    }
}
</script>

<style>

</style>