import http from '@/http';

class users {
    usersList(data) {
        return http('user').get('users', data);
    }
    userOptions(data) {
        return http('user').get('options', data);
    }
    userModel(userId) {
        return http('user').get(`users/${userId}`);
    }
    createUserModel(data, end='') {
        const endPoint = `users${end ? end : ''}`
        return http('user').post(endPoint, data);
    }
    updateUserModel(data) {
        return http('user').put(`users/${data.id}`, data);
    }
    deleteUserModel(userId) {
        return http('user').deleteReq(`users/${userId}`)
    }
    uploadFile(data) {
        return http('user').post(`file/upload`, data)
    }
    deleteUploadedFile(data) {
        return http('user').post(`file/delete`, data)
    }
}

export default new users();