const itemsObj = (defect = null) => {
    if (defect === 'scratch') {
        return { items_count: { spot: null, paint: null, conventional: null },
            focus: { spot: false, paint: false, conventional: false },
            placeholder: {  spot: '0', paint: '0 ($)', conventional:' 0 ($)' }}
    }
    return {
        items_count: {
            light: null,
            medium: null,
            heavy: null,
            severe: null,
            oversize: null,
            p2p: null,
            aluminium: null,
            conventional: null,
            replacement:null
        },
        focus: {
            light: false,
            medium: false,
            heavy: false,
            severe: false,
            oversize: false,
            p2p: false,
            aluminium: false,
            conventional: false,
            replacement: false,
        },
        placeholder: {
            light: '0',
            medium: '0',
            heavy: '0',
            severe: '0',
            oversize: '0',
            p2p: '0',
            aluminium: '0 (%)',
            conventional: '0 ($)',
            replacement: '0 ($)'
        }
    }  
}

export const ITEMS = (partItems, defect) => {
    return partItems.map(item => {
        return { parts: item, ...itemsObj(defect) };
    })
};

export const MODEL_ITEMS = (partItems) => {
    let prices = {dent: {}, scratch: {}};
    partItems.map((item, index) => {
        prices.dent[item.value] = (itemsObj()).items_count;
        prices.scratch[item.value] = (itemsObj('scratch')).items_count;
    });
    return prices;
};
export const DENT_HEADERS = ['PDR', 'light', 'medium', 'heavy', 'severe', 'oversize', 'P2P', 'Aluminium (%)', 'Conventional ($)', 'Replacement ($)'];

export const SCRATCH_HEADERS = ['PDR', 'SPOT', 'Push to Paint ($)', 'Conventional ($)'];