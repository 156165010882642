<template>
  <v-row id="panel-form-page">
    <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="savePanel">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" md="6">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Panel Group</v-list-subheader>
                            <v-autocomplete ref="panel_group_id" v-model="model.panel_group_id" :items="panelGroupsList" variant="outlined" label=""
                                item-title="text" item-value="value"
                                :error-messages="v$.model.panel_group_id.$errors.map(e => e.$message)" placeholder="Select Panel Group"
                                @update:model-value="v$.model.panel_group_id.$touch()"  @blur="v$.model.panel_group_id.$touch()"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Name</v-list-subheader>
                            <v-text-field  ref="name" v-model="model.name" :error-messages="v$.model.name.$errors.map(e => e.$message)"
                                placeholder="Name" variant="outlined" @update:model-value="v$.model.name.$touch()"  @blur="v$.model.name.$touch()" required/>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" md="6">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Label</v-list-subheader>
                            <v-text-field  ref="label" v-model="model.label" :error-messages="v$.model.label.$errors.map(e => e.$message)"
                                placeholder="Label" variant="outlined" @update:model-value="v$.model.label.$touch()"  @blur="v$.model.label.$touch()" required/>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Position</v-list-subheader>
                            <v-text-field type="number" ref="position" v-model.number="model.position"
                                :error-messages="v$.model.position.$errors.map(e => e.$message)"
                                placeholder="Position" variant="outlined" @update:model-value="v$.model.position.$touch()"  @blur="v$.model.position.$touch()" required/>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" md="6">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Type</v-list-subheader>
                            <v-select ref="type"  v-model="model.type" :items="typeList" item-title="text" item-value="value" variant="outlined" label=""
                                :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                                :error-messages="v$.model.type.$errors.map(e => e.$message)" placeholder="Select Type"
                                @update:modelValue="v$.model.type.$touch()"  @blur="v$.model.type.$touch()" required>
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Status</v-list-subheader>
                            <v-switch v-model="model.status" inset label="" :true-value="1" :false-value="0"></v-switch>
                        </v-col>
                    </v-row>
                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="primary"  class="mr-4">
                            Submit
                        </v-btn>
                        <v-btn type="button" variant="outlined" color="primary" class="mr-4" @click="$router.push('/panels')">
                            Cancel
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";
import { mapActions, mapGetters } from 'vuex';

export default {
    data: (vm) => ({
        panelId: vm.$route.params.panelId,
        model: {
            name: '',
            status: 1,
            type: 'D'
        },
        typeList: [{text: 'Default', value: 'D'}, {text: 'Large', value: 'L'}, {text: 'Extra Large', value: 'XL'}],
        v$: useVuelidate(),
    }),
    validations() {
        return {
            model: {
                panel_group_id: { required},
                name: { required},
                label: { required},
                type: { required},
                position: { required, minValue: minValue(1)},
            }
        }
    },
    computed: {
        ...mapGetters(['panelGroupsList'])
    },
    created() {
        if (this.panelId != 'create') {
          this.getSelectedPanel(this.panelId).then(response => this.model = response);
        }
        this.getPanelGroupLists();
    },
    methods: {
        ...mapActions(['getSelectedPanel', 'createPanel', 'updateSelectedPanel', 'getPanelGroupLists']),

        savePanel() {
            this.v$.$touch();
            if (this.v$.$invalid) {
                this.$helpers.focusErrorElement(this.v$.model, this.$refs);
                return;
            }
            if (this.panelId != 'create') {
                this.updateSelectedPanel(this.model).then(resp => this.$router.push("/panels"));
            } else {
                this.createPanel(this.model).then(resp => this.$router.push("/panels"));
            }
        },
    },
};
</script>
