<template>
  <div id="layout-page">
    <component :is="getThemeMode.layout" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "",
  data() {
    return {
      layoutName: "",
    };
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
  }
};
</script>

<style scoped>
</style>