<template>
  <div id="parts-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" divider=">" class="pl-0 config-breadcrumbs mt-n15">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="!item.disabled && $router.push(item.href)"
          :class="breadcrumbList.length > 1 && item.disabled ? 'text-secondary' : 'text-primary'">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <part-form v-if="partId" />
    <part-home v-else />
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';

import partForm from "./components/PartForm.vue";
import partHome from "./components/PartHome.vue";

export default {
  name: "Parts",
  setup() {
    useHead({
      title: "Parts",
    })
  },
  components: {
    partForm,
    partHome,
  },
  data: (vm) => ({
    partId: vm.$route.params.partId,
    breadcrumbList: [{
      text: 'Parts',
      disabled: true,
      href: '/parts',
    }]
  }),
  watch: {
    $route(newVal) {
      this.partId = newVal.params.partId;
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.partId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.partId == 'create' ? 'Create' : 'Update'} Part`,
          disabled: true,
          href: `/parts/${this.partId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Parts',
          disabled: true,
          href: '/parts',
        }]
      }
    }
  }
};
</script>
