<template>
  <div id="email-template-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" divider=">" class="pl-0 config-breadcrumbs mt-n15">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="!item.disabled && $router.push(item.href)"
          :class="breadcrumbList.length > 1 && item.disabled ? 'text-secondary' : 'text-primary'">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <email-template-form v-if="emailTemplateId" />
    <email-template-home v-else />
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';

import emailTemplateForm from "../template/components/TemplateForm.vue";
import emailTemplateHome from "../template/components/TemplateHome.vue";

export default {
  name: "EmailTemplates",
  setup() {
    useHead({
      title: "Email Templates",
    })
  },
  components: {
    emailTemplateForm,
    emailTemplateHome,
  },
  data: (vm) => ({
    emailTemplateId: vm.$route.params.emailTemplateId,
    breadcrumbList: [{
      text: 'Email Templates',
      disabled: true,
      href: '/email-templates',
    }]
  }),
  watch: {
    $route(newVal) {
      this.emailTemplateId = newVal.params.emailTemplateId;
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.emailTemplateId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.emailTemplateId == 'create' ? 'Create' : 'Update'} Template`,
          disabled: true,
          href: `/email-templates/${this.emailTemplateId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Email Templates',
          disabled: true,
          href: '/email-templates',
        }]
      }
    }
  }
};
</script>
