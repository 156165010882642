<template>
  <div id="panel-groups-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" divider=">" class="pl-0 config-breadcrumbs mt-n15">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="!item.disabled && $router.push(item.href)"
          :class="breadcrumbList.length > 1 && item.disabled ? 'text-secondary' : 'text-primary'">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <group-form v-if="panelGroupId" />
    <group-home v-else />
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';

import groupForm from "./components/PanelGroupForm.vue";
import groupHome from "./components/PanelGroupHome.vue";

export default {
  name: "PanelGroups",
  setup() {
    useHead({
      title: "PanelGroups",
    })
  },
  components: {
    groupForm,
    groupHome,
  },
  data: (vm) => ({
    panelGroupId: vm.$route.params.panelGroupId,
    breadcrumbList: [{
      text: 'Panel Groups',
      disabled: true,
      href: '/panelgroups',
    }]
  }),
  watch: {
    $route(newVal) {
      this.panelGroupId = newVal.params.panelGroupId;
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.panelGroupId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.panelGroupId == 'create' ? 'Create' : 'Update'} Panel Groups`,
          disabled: true,
          href: `/panelgroups/${this.panelGroupId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Panel Groups',
          disabled: true,
          href: '/panelgroups',
        }]
      }
    }
  }
};
</script>
