import webhooks from '@/store/modules/webhooks/api';

export default {
    getWebEventLists({ commit }) {
        commit("setWebEventsListLoading", true);
        return new Promise((res, rej) => {
            webhooks.eventsList().then(response => {
                commit("setWebEventList", response);
                res(response);
            }).catch((error) => {
                commit("setWebEventsListLoading", false);
                rej(error)
            });
        });
    },
    getSelectedWebhook({ commit }, id) {
        commit("setWebhookLoading", true);
        return new Promise((res, rej) => {
            webhooks.webhookModel(id).then(response => {
                commit("setWebhookLoading", false);
                res(response);
            }).catch((error) => {
                commit("setWebhookLoading", false);
                rej(error)
            });
        });
    },
    createWebhook({ commit }, data) {
        commit("setWebhookLoading", true);
        return new Promise((res, rej) => {
            webhooks.createWebhookModel(data).then(response => {
                commit("setWebhookLoading", false);
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Webhook Created Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setWebhookLoading", false);
                rej(error)
            });
        });
    },
    updateSelectedWebhook({ commit }, data) {
        commit("setWebhookLoading", true);
        return new Promise((res, rej) => {
            webhooks.updateWebhookModel(data.id, data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Webhook Updated Successfully",
                    color: "success",
                });
                commit("setWebhookLoading", false);
                res(response);
            }).catch((error) => {
                commit("setWebhookLoading", false);
                rej(error)
            });
        });
    },
    deleteWebhook({ commit }, webhookId) {
        return new Promise((res, rej) => {
            webhooks.deleteWebhookModel(webhookId).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Webhook Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    }
}