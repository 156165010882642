<template>
  <div id="roles-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" divider=">" class="pl-0 config-breadcrumbs mt-n15">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="!item.disabled && $router.push(item.href)"
          :class="breadcrumbList.length > 1 && item.disabled ? 'text-secondary' : 'text-primary'">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <role-form v-if="roleId" />
    <role-home v-else />
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';

import roleForm from "../roles/components/RoleForm.vue";
import roleHome from "../roles/components/RoleHome.vue";

export default {
  name: "Roles",
  setup() {
    useHead({
      title: "Roles",
    })
  },
  components: {
    roleForm,
    roleHome,
  },
  data: (vm) => ({
    roleId: vm.$route.params.roleId,
    breadcrumbList: [{
      text: 'Roles',
      disabled: true,
      href: '/roles',
    }]
  }),
  watch: {
    $route(newVal) {
      this.roleId = newVal.params.roleId;
      this.setBreadCrumb();
    },
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.roleId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.roleId == 'create' ? 'Create' : 'Update'} Role`,
          disabled: true,
          href: `/roles/${this.roleId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Roles',
          disabled: true,
          href: '/roles',
        }]
      }
    }
  }
};
</script>
