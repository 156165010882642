
import { MODEL_ITEMS } from '../../../pages/setting/clients/services/dent-scratch-data';

export default {
    setClientLoading(state, data) {
        state.clientLoading = data;
    },
    setClientsListLoading(state, data) {
        state.clientsListLoading = data;
    },
    setClientsList(state, data) {        
        state.clientsList = data;
        state.clientsListLoading = false;
    },
    setPDRlistLoading(state, data) {
        state.pdrListLoading = data;
    },
    setPDRlists(state, data) {
        if (data && data.length) state.priceLists = MODEL_ITEMS(data);
        state.pdrLists = data || [];
        state.pdrListLoading = false;
    },
}