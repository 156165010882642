<template>
  <v-row id="email-header-footer-page">
    <v-col cols="12">
        <v-card>
            <v-card-text>
              <v-form lazy-validation @submit.prevent="saveSetting">
                <v-row class="ma-0 pa-0">
                    <v-col cols="12">
                        <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Setting Name</v-list-subheader>
                        <v-text-field hide-details="auto" v-model="model.option_name" label="" :disabled="true" variant='outlined'/>
                    </v-col>
                    <v-col cols="12">
                      <v-skeleton-loader v-if="selectAppSettingLoading"
                                          height="100px"
                                          type="image"
                      ></v-skeleton-loader>

                      <v-textarea v-if="!selectAppSettingLoading && model.field_type !== 'editor'"
                                  hide-details="auto"
                                  auto-grow
                                  rows="4"
                                  v-model="model.option_value"
                                  row-height="20"
                                  label=""
                                  outlined
                      ></v-textarea>

                      <quill-editor v-if="!selectAppSettingLoading && model.field_type == 'editor'"
                                    v-model:value="model.option_value"
                                    ref="message"
                                    class="h-auto"
                                    :options="editorOption"/>
                    </v-col>
                </v-row>
                <div class="d-block d-lg-flex justify-end">
                    <v-btn type="submit" color="primary" :disabled="loading" :loading="loading" class="mr-4">
                        Save
                    </v-btn>
                    <v-btn type="button" variant="outlined" color="primary" class="mr-4" @click="$router.push('/app-settings')">
                        Cancel
                    </v-btn>
                </div>
              </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { useVuelidate } from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import { mapGetters, mapActions } from 'vuex';
  import { useHead } from '@vueuse/head';

  import { EDITOR_OPTIONS } from '@/constants/editor-options';

  export default {
    name: 'EditSetting',
    setup() {
      useHead({
        title: "Edit Setting",
      })
    },
    data: (vm) => ({
      appSettingId: vm.$route.params.appSettingId,
      model: {
          option_value: '',
          field_type: '',
      },
      submit: false,
      loading: false,
      v$: useVuelidate(),
      editorOption: EDITOR_OPTIONS('Your Message'),
    }),
    validations: {
      model: {
        option_value: { required },
      },
    },
    computed: {
      ...mapGetters(["selectedAppSettingModel", "selectAppSettingLoading"]),
    },
    created() {
      this.fetchUserOptions();
    },
    methods: {
      ...mapActions(['getUserOptions', 'updateOptionsData']),

      async fetchUserOptions() {
        this.loading = true;
        try {
          const response = await this.getUserOptions();
          const [{ option_name, option_value, field_type }] = response.data || null;
          this.model.option_name = option_name;
          this.model.option_value = option_value;
          this.model.field_type = field_type;
        } catch (error) {
          console.error("Error fetching user options:", error);
        } finally {
          this.loading = false;
        }
      },

      async saveSetting() {
        this.loading = true;
        try {
          const id = this.appSettingId;
          const data = {
            option_value: this.model.option_value,
            field_type: this.model.field_type,
          };
          await this.updateOptionsData({ id, data });
          this.Loading = false;
          this.$router.push("/app-settings");
        } catch (error) {
          console.error("Error saving setting:", error);
        } finally {
          this.loading = false;
        }
      },
    }
  }
</script>

<style>

</style>