<template>
    <v-col cols="12" class="pt-0 px-0">
        <v-card min-width="100%" class="mb-6" :loading="annotateSaveLoading" :disabled="annotateSaveLoading">
            <template slot="progress">
                <v-progress-linear :color="color" :style="`color: ${textColor}`" indeterminate></v-progress-linear>
            </template>
            <v-toolbar :color="color" :style="`color: ${textColor}`" elevation="3" class="scan-title-bar">
                <v-toolbar-title class="text-section-title"> Saved Images</v-toolbar-title>
            </v-toolbar>
            <div class="d-flex pa-5 justify-center" v-if="scanLoading">
                <template v-for="(item, index) in Array.from(Array(11).keys())" :key="index">
                    <v-skeleton-loader style="width: 100px; height: 100px" v-bind="{ class: 'mx-2', elevation: 1 }"
                        type="table-heading, list-item-two-line, image, table-tfoot">
                    </v-skeleton-loader>
                </template>
            </div>
            <template v-else-if="!scanLoading && (!lists || lists.length === 0)">
                <div class="ma-0 pa-5 pb-7 text-center">
                    No Data Available!.
                </div>
            </template>
            <v-sheet elevation="0" class="d-flex flex-wrap pa-0" v-else-if="!scanLoading && lists">
                <v-slide-group mandatory show-arrows >
                    <v-slide-group-item v-for="(annotate, index) in lists" :key="index">
                        <v-menu  offset-y :disabled="selectOpt">
                            <template v-slot:activator="{ props }">
                                <div class="mx-2 mb-2 image-view-container  mt-4" :class="selectOpt ? 'image-edit-static' : 'image-edit-hover'" :key="index"
                                    v-bind="props" >
                                    <v-lazy @click="selectOpt && imageSelected(annotate.path)">
                                        <img height="100" width="100" :id="`saved-${index}-${annotate.id}`" :src="annotate.src" class="ma-2 cursor-pointer"
                                            crossorigin="anonymous" v-bind="props" />
                                    </v-lazy>
                                    <div class="pinned pl-1" :class="selectOpt ? 'd-block' : 'd-none'" >
                                        <div class="d-flex justify-end align-center position-relative">
                                            <v-icon  v-if="selectOpt" size="25" color="info" @click="imageSelected(annotate.path)" class="select-img">
                                                {{`mdi-${ mailSelectedImage.indexOf(annotate.path) !== -1 ? 'check-circle' : 'circle-outline'}`}}
                                            </v-icon>
                                            <v-icon  v-else size="25" color="danger" @click="$event.stopPropagation(); handleDelete(annotate)" class="remove-img">
                                                mdi-close-circle
                                            </v-icon>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <v-list class="scan-edit-options">
                                <v-list-item class="cursor-pointer option" @click="showImg(index)">
                                    <v-list-item-title>
                                        <v-icon color="primary" class="mr-2">mdi-archive-eye-outline </v-icon>
                                        View
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item class="cursor-pointer option" @click="openCrop = annotate.src">
                                    <v-list-item-title>
                                        <v-icon color="primary" class="mr-2">mdi-crop</v-icon>
                                        Crop
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item class="cursor-pointer option" @click="showMarkerArea(`saved-${index}-${annotate.id}`)">
                                    <v-list-item-title>
                                        <v-icon color="primary" class="mr-2">mdi-image-plus </v-icon>
                                        Annotation
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-slide-group-item>
                </v-slide-group>
            </v-sheet>
            <CropperModal :openCrop="openCrop" v-if="!scanLoading && lists && openCrop" @cancel="openCrop = null"
                @cropped="$emit('saved', $event.value);openCrop = null" :name="'Cropped'"/>
        </v-card>
    </v-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as markerjs2 from "markerjs2";

import CropperModal from "./CropperModal.vue";

export default {
    props: ['lists', 'selectOpt', 'color', 'textColor'],
    emits: ['delete', 'saved', 'mailSelectImg'],
    components: {
        CropperModal,
    },
    data: (vm) => ({
        openCrop: null,
        mailSelectedImage: []
    }),
    computed: {
        ...mapGetters(['scanLoading', 'annotateSaveLoading'])
    },
    methods: {
        ...mapActions(['getSelectedScan', 'removeVehiclePhotoQuote', 'removeImage']),

        handleDelete(annotate) {
            if (annotate.vehicle_photo) {
                this.removeVehiclePhotoQuote(annotate.id)
                    .then(() => {
                        this.$emit('delete', annotate.id);
                    })
                    .catch((error) => {
                        console.error('Error deleting vehicle photo:', error);
                    });
            } else {
                this.$emit('delete', annotate.id);
            }
        },
        showImg(index) {
            this.$viewerApi({
                options: {
                    initialViewIndex: index
                },
                images: this.lists,
            })
        },
        showMarkerArea(imgId) {
            const target = document.getElementById(imgId);
            const markerArea = new markerjs2.MarkerArea(target);
            markerArea.uiStyleSettings.zoomOutButtonVisible = true;
            markerArea.uiStyleSettings.zoomButtonVisible = true;
            markerArea.renderImageType = 'image/jpeg';
            markerArea.settings.displayMode = "popup";
            markerArea.addEventListener("render", (event) => {
                this.$store.commit('setPageLoading', false);
                this.$emit('saved', event.dataUrl);
            });
            markerArea.show();
        },
        imageSelected(image) {
            const imageIndex = this.mailSelectedImage.findIndex(img => img == image);
            if(imageIndex == -1) this.mailSelectedImage.push(image);
            else this.mailSelectedImage.splice(imageIndex, 1);
            this.$emit('mailSelectImg', this.mailSelectedImage);
        }
    }
}
</script>

<style>

</style>