import http from '@/http';

class locations {
    locationsList(data) {
        return http('agent').get('locations/lists', data);
    }
    locationModel(locationId) {
        return http('agent').get(`locations/${locationId}`);
    }
    createLocationModel(data) {
        return http('agent').post('locations', data);
    }
    updateLocationModel(data) {
        return http('agent').put(`locations/${data.id}`, data);
    }
    deleteLocationModel(locationId) {
        return http('agent').deleteReq(`locations/${locationId}`)
    }
}

export default new locations();