<template>
    <v-row class="ma-0">
        <v-col cols="12" class="pl-0">
            <v-chip class="bg-primary" variant="flat" label text-color="white">
                <v-icon size="x-large" class="ml-n2" start> mdi-label</v-icon>Vehicle Surcharges
            </v-chip>
        </v-col>
        <slot></slot>
        <v-col cols="12" lg="4" xl="4" md="4">
            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Metalic</v-list-subheader>
            <v-text-field v-model="model.sc_metallic" append-inner-icon="mdi-percent-outline" placeholder="Add Metalic %"
                variant="outlined" ref="sc_metallic" type="number" min="0" hide-details="auto"/>
        </v-col>
        <v-col cols="12" lg="4" xl="4" md="4">
            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Pearl</v-list-subheader>
            <v-text-field v-model="model.sc_pearl" append-inner-icon="mdi-percent-outline" placeholder="Add Pearl %"
                variant="outlined" ref="sc_pearl" type="number" min="0" hide-details="auto"/>
        </v-col>
        <v-col cols="6" lg="4" xl="4" md="4">
            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Include P2P</v-list-subheader>
            <v-radio-group inline class="ml-4" v-model="model.sc_p2p" ref="sc_p2p" hide-details="auto">
                    <v-radio label="Yes" :value="1"></v-radio>
                    <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
        </v-col>
        <v-col cols="6" lg="4" xl="4" md="4">
          <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">
            Currency
            <span v-if="model.currency" class="text-secondary">({{ model.currency }})</span>
          </v-list-subheader>
          <v-select  v-model="model.currency" :items="currencyList" item-value="value" item-title="text" variant="outlined" label=""
               density="comfortable" :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
               hide-details="auto">
            </v-select>
        </v-col>
        <v-col cols="12" class="pl-0 mt-2">
            <v-chip class="bg-primary" variant="flat" label text-color="white">
                <v-icon size="x-large" class="ml-n2" start> mdi-label</v-icon>Average Calculation method
            </v-chip>
            <v-radio-group inline v-model="model.calculation_method" class="mt-5 ml-4" ref="calculation_method" row hide-details="auto">
                    <v-radio label="Threshold" :value="'T'"></v-radio>
                    <v-radio label="Weight" :value="'W'"></v-radio>
            </v-radio-group>
            <div v-if="model.calculation_method == 'T'" class="mt-5 ml-4">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Severity Threshold</v-list-subheader>
                <v-text-field v-model="model.sc_threshold" min="0" type="number" placeholder="Add Severity Threshold" variant="outlined"
                    ref="sc_threshold" hide-details="auto"/>
            </div>
            <v-card class="mt-5 ml-4" v-if="model.calculation_method == 'W'">
                <v-table>
                    <template v-slot:default>
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-center text-uppercase text-white text-center"
                                    v-for="title in calculationHeader" :key="title">{{title}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center pa-0 pa-3" v-for="(item, index) in calculationHeader" :key="item+index">
                                    <v-text-field class="box-shadow-none input-align-center px-0" type="number" :ref="item" variant="outlined" dense min="0"
                                        v-model.number="model.weighted_no[item]" hide-details="auto"/>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ['model', 'v'],
    data() {
        return {
            currencyList: [{ text: 'Dollar', value: '$' }, { text: 'Yen', value: '¥' }, { text: 'Euro', value: '€' }],
            calculationHeader: ['oversize', 'severe', 'heavy', 'medium', 'light']
        }
    }
}
</script>

<style>

</style>