<template>
  <v-row id="part-form-page">
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-form lazy-validation @submit.prevent="savePart">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" md="6" lg="6">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Name</v-list-subheader>
                <v-text-field ref="name" v-model="model.name" :error-messages="v$.model.name.$errors.map(e => e.$message)"
                              placeholder="Name" variant="outlined" @update:model-value="v$.model.name.$touch()" @blur="v$.model.name.$touch()" required/>
              </v-col>
              <v-col cols="12" md="6" lg="6">
                <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Type</v-list-subheader>
                <v-select v-model="model.type" variant="outlined" hide-details="auto" :items="['arch','chasis','tire']"
                          class="text-capitalize w-60" placeholder="Type"></v-select>
              </v-col>
              <v-col cols="12">
                <v-btn type="submit" color="primary" class="mr-4">
                  Submit
                </v-btn>
                <v-btn type="button" variant="outlined" color="primary" class="mr-4 button" @click="$router.push('/parts')">
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {mapActions} from 'vuex';

export default {
  data: (vm) => ({
    partId: vm.$route.params.partId,
    show: false,
    model: {
      name: ''
    },
    v$: useVuelidate(),
  }),
  validations() {
    return {
      model: {
        name: { required },
      }
    }
  },
  created() {
    if (this.partId != 'create') {
      this.getSelectedPart(this.partId).then(response => this.model = response);
    }
  },
  methods: {
    ...mapActions(['getSelectedPart', 'createPart', 'updateSelectedPart']),
    savePart() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.$helpers.focusErrorElement(this.v$.model, this.$refs);
        return;
      }
      if (this.partId != 'create') {
        this.updateSelectedPart(this.model).then(resp => this.$router.push("/parts"));
      } else {
        this.createPart(this.model).then(resp => this.$router.push("/parts"));
      }
    },
  },
};
</script>
