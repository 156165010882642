<template>
  <v-app>
    <router-view></router-view>
    <Snackbar></Snackbar>
  </v-app>
</template>

<script>
import { useHead } from '@vueuse/head';

import Snackbar from "./components/base/Snackbar.vue"
export default {
  name: "App",
  setup() {
    useHead({
      title : 'Insurance',
      titleTemplate: `%s | Scan N Go`,
    })
  },
  components: {
    Snackbar,
  }
};
</script>