
class filters {
    titleCase (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
    currencyFormat(value, format) {
        return format == '%' ? `${parseFloat(Number(value))}%` : `$${(Number(value)).toFixed(2)}`;
    }
}

export default new filters();
