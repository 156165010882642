import colors from "vuetify/util/colors";
export const themeConfig = {
  rtl: false, // options[Boolean] : true, false(default)
  verticalSidebarMini: false, // options[Boolean] : true, false(default)
  verticalSidebarDrawer: true, // options[Boolean] : true, false(default)
  verticalCompactSidebarDrawer: true, // options[Boolean] : true, false(default)
  verticalSaasSidebarDrawer: true, // options[Boolean] : true, false(default)
  showBreadcrumb: true, // options[Boolean] : true, false(default)

  layout: "VerticalSix",
  isLoading: false,
  isDark: false,
  verticalSidebarDrawerColor: "white", // primary,white,sucsess,warning,etc
  appBarColor: "white", // primary,white,sucsess,warning,etc
};

export const themePreset = {
  display: {
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },

  rtl: false,

  theme: {
    dark: false,

    default: "light",
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: true,
      minifyTheme: undefined,
      themeCache: undefined,
    },
    themes: {
      light: {
        colors: {
          primary: "#0C1839",
          'on-primary': '#ffffff',
          secondary: "#A7B3C8",
          success: "#6BBC3E",
          danger: "#FF0000",
          error: "#FF0000",
          warning: '#FF8401',
          info: "#0486DE",
          dark: "#242939",
          accent: "#82B1FF",
          anchor: '#0081FF',
          background: '#F2F4F7',
        }
      },
      dark: {
        colors: {
          primary: colors.blue.darken3,
          secondary: colors.pink.accent3,
          success: colors.green.accent4,
          danger: colors.red.accent3,
          warning: colors.deepOrange.accent4,
          info: colors.indigo.darken2,
          accent: colors.cyan.accent2,
        }
      },
    },
  },
};
