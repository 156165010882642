import AxiosInstance from "@/http/axios.interceptor";

let API;

const http = (microServiceName) => {
    API = AxiosInstance(microServiceName);
    return {
        get,
        post,
        put,
        deleteReq
    };
};

// GET method
function get(endPoint, data) {
    return API.get(endPoint, data);
}

// POST method
function post(endPoint, data, options = {}) {
    return API.post(endPoint, data, options);
}

// PUT method
function put(endPoint, data, options = {}) {
    return API.put(endPoint, data, options);
}

//DELETE method
function deleteReq(endPoint, data) {
    return API.delete(endPoint, data);
}

export default http;
