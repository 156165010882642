import http from '@/http';

class permissions {
    permissionsList(data) {
        return http('user').get('permissions/lists', data);
    }
    permissionModel(permissionId) {
        return http('user').get(`permissions/${permissionId}`);
    }
    createPermissionModel(data) {
        return http('user').post('permissions', data);
    }
    updatePermissionModel(data) {
        return http('user').put(`permissions/${data.id}`, data);
    }
    deletePermissionModel(permissionId) {
        return http('user').deleteReq(`permissions/${permissionId}`)
    }
}

export default new permissions();