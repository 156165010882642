import { PerfectScrollbarPlugin } from "vue3-perfect-scrollbar";

// theme Main scss
import "@/assets/scss/index.scss";

// plugins menu

// VueCalendar
import "@/plugins/calendar.js";

// axios
import axios from "axios";
import VueAxios from "vue-axios";

// form-Wizard
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import { createHead } from "@vueuse/head";

//Quill Editor Box
import VueViewer from 'v-viewer';
import { quillEditor } from 'vue3-quill'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

//vuepic-datepicker
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import VerticalSix from "../layouts/component/Index";
// end plugins

export default {
  install(app) {
    const head = createHead()
    app.use(head);
    app.use(VueFormWizard);
    app.use(VueAxios, axios);
    app.use(VueViewer);
    app.use(quillEditor);
    app.use(PerfectScrollbarPlugin);
    app.component("VerticalSix", VerticalSix);
    app.component('VueDatePicker', VueDatePicker);
  },
};