import http from '@/http';

class auth {
    authForgetPassword(data) {
        return http('user').post('auth/forgot-password', data);
    }
    authResetPassword(data) {
        return http('user').post('auth/reset-password', data);
    }
    authLogin(data) {
        return http('user').post('auth/login', data);
    }
    authUser() {
        return http('user').get('auth/user');
    }
    impersonateUser(id) {
        return http('user').get(`auth/impersonate/take/${id}`);
    }
    leaveImpersonation(id) {
        return http('user').get(`auth/impersonate/${id}/leave`);
    }
}

export default new auth();