<template>
    <v-dialog :model-value="visible" scrollable max-width="1000px" @click:outside="$emit('close')">
        <v-card>
            <v-card-text style="max-height: 800px;" class="pa-0 pt-2">
                <v-row class="ma-0 pa-0">
                    <v-col cols="12" order-xl="2" order-lg="2" lg="4" xl="4">
                        <v-row class="w-full align-center justify-center" no-gutters>
                            <v-btn-toggle density="comfortable" v-model="damages_view" mandatory>
                                <v-btn size="small" @click="selectedDamage=null">All</v-btn>
                                <v-btn size="small" @click="selectedDamage=null">Dent</v-btn>
                                <v-btn size="small" @click="selectedDamage=null">Scratch</v-btn>
                            </v-btn-toggle>
                        </v-row>
                        <v-row class="flex-wrap mt-2 align-center justify-center">
                            <template v-for="panel in panelGroupsPosition" :key="panel.id">
                                <v-card v-if="!damages_view || (damages_view == 1 && allDamageDetails[panel.name].dent_total) || (damages_view == 2 && allDamageDetails[panel.name].scratch_total)"
                                    elevation="1" class="ma-2 pa-2 pt-3 position-relative"
                                    :class="selectedDamage && selectedDamage.group_name === panel.name ? 'image-edit-static' : ''">
                                    <img height="50" width="100" @click="handleDamageDetail(panel)"
                                        :src="panel.image" class="ma-2 cursor-pointer"/>
                                    <div v-if="allDamageDetails[panel.name].dent_total" class="dent-badge position-absolute" style="right: 12%; top: 5%;"></div>
                                    <div v-if="allDamageDetails[panel.name].scratch_total" class="scratch-badge position-absolute" style="right: 2%; top: 5%;"></div>
                                </v-card>
                                <div v-if="(damages_view == 1 && !checkAllDents) || (damages_view == 2 && !checkAllScratchs)" class="pa-7 d-flex align-center justify-center">
                                    {{ damages_view == 1 ? 'Dent' : !damages_view ? 'Damage' : 'Scratch' }} Not Availble
                                </div>
                            </template>
                        </v-row>
                        <v-row class="mt-2 align-center justify-center" v-if="selectedDamage && (selectedDamage.dent_total || selectedDamage.scratch_total)">
                            <v-col cols="12" :lg="damages_view == 1 || (!damages_view && !selectedDamage.scratch_total) ? 12 : 6" :xl="damages_view == 1 || (!damages_view && !selectedDamage.scratch_total) ? 12 : 6"
                                :md="damages_view == 1 || (!damages_view && !selectedDamage.scratch_total) ? 12 : 6" :sm="damages_view == 1 || (!damages_view && !selectedDamage.scratch_total) ? 12 : 6"
                                v-if="(!damages_view || damages_view == 1) && selectedDamage.dent_total">
                                <damage-count :data="selectedDamage"
                                    :class="damages_view == 1 || (!damages_view && !selectedDamage.scratch_total) ? 'w-full' : 'w-auto'"/>
                            </v-col>
                            <v-col cols="12" :lg="damages_view == 2 || (!damages_view && !selectedDamage.dent_total) ? 12 : 6" :xl="damages_view == 2 || (!damages_view && !selectedDamage.dent_total) ? 12 : 6"
                                :md="damages_view == 2 || (!damages_view && !selectedDamage.dent_total) ? 12 : 6" :sm="damages_view == 2 || (!damages_view && !selectedDamage.dent_total) ? 12 : 6"
                                v-if="(!damages_view || damages_view == 2 ) && selectedDamage.scratch_total">
                                <damage-count field="scratch" :data="selectedDamage"
                                    :class="damages_view == 2 || (!damages_view && !selectedDamage.dent_total) ? 'w-full' : 'w-auto'"/>
                            </v-col>
                        </v-row>
                        <v-row v-if="selectedDamage && !selectedDamage.dent_total && !selectedDamage.scratch_total" class="mt-7 pa-5 pt-7 align-center justify-center"> Damage Not Detected</v-row>
                    </v-col>
                    <v-col cols="12" order-xl="1" order-lg="1" lg="8" xl="8">
                        <image-section :damages="getDamageImg('dents')" title="Dents" v-if="selectedDamage && (getDamageImg('dents')).length"
                            @updated="$emit('edited', $event)"/>
                        <image-section :damages="getDamageImg('scratchs')" title="Scratchs" v-if="selectedDamage && (getDamageImg('scratchs')).length"
                            @updated="$emit('edited', $event)"/>
                        <v-row v-if="!selectedDamage || (!(getDamageImg('dents')).length && !(getDamageImg('scratchs')).length)"
                            class="pa-7 mt-7 text-center w-full align-center justify-center"> Damages Not Available!.</v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-5">
                <v-spacer />
                <v-btn color="primary" type="button" class="mr-4" variant="outlined" @click="$emit('close')">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapGetters } from "vuex";
    import _ from 'lodash';

    import {CommonModal} from "@/components/common";
    import DamageCount from "./DamageCount.vue";
    import ImageSection from "./ImageSection.vue";

    export default {
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            name: {
                type: String,
                default: 'TOP',
            },
            list: {
                type: Object,
                default: () => ({})
            },
            damages: {
                type: Array,
                default: () => ([])
            },
        },
        components: {
            CommonModal,
            DamageCount,
            ImageSection,
        },
        data () {
            return {
                damages_view: null,
                allDamageDetails: null,
                damageLoading: false,
                openCrop: null,
                selectedDamage: null,
            }
        },
        computed: {
            ...mapGetters(['panelGroupsPosition']),

            checkAllDents() {
                const groupName = Object.keys(this.allDamageDetails);
                const dentTotal = groupName.reduce((n, name) => n + this.allDamageDetails[name].dent_total, 0);
                return dentTotal ;
            },
            checkAllScratchs() {
                const groupName = Object.keys(this.allDamageDetails);
                const scratchTotal = groupName.reduce((n, name) => n + this.allDamageDetails[name].scratch_total, 0);
                return scratchTotal;
            }
        },
        watch: {
            panelGroupsPosition(value) {
                this.updateSelectVal();
            },
            name(value) {
                this.updateSelectVal();
            }
        },
        created() {
            this.updateSelectVal();
        },
        methods: {
            getDamageImg(type) {
                const groupIndex = this.panelGroupsPosition.findIndex(group => group.name === this.selectedDamage.group_name);
                const panelsList = _.map(this.panelGroupsPosition[groupIndex].panels.filter(panel => panel.type === 'D'), 'carPanel');
                return _.filter(this.damages, item => item.type === type && panelsList.includes(item.carPanel));
            },
            updateSelectVal() {
                const groupIndex = this.panelGroupsPosition.findIndex(group => group.name === this.name);
                if (groupIndex !== -1) this.handleDamageDetail(this.panelGroupsPosition[groupIndex]);
                this.handleAllDamageDetails();
            },
            handleDamageDetail(panelGroup, selected=true) {
                this.damageLoading = true;
                const panelsList = _.map(panelGroup.panels.filter(panel => panel.type === 'D'), 'carPanel');
                const damageGroupItem = panelsList.reduce((result, item) => {
                    result.dent_total = result.dent_total + this.list[item].dent_total;
                    result.scratch_total = result.scratch_total + this.list[item].scratch_total;
                    _.map(this.list[item].tooltip, tooltip => {
                        const resultIndex = result.tooltip.findIndex(damage => damage.text === tooltip.text);
                        result.tooltip[resultIndex].dent = result.tooltip[resultIndex].dent + tooltip.dent;
                        result.tooltip[resultIndex].scratch = result.tooltip[resultIndex].scratch + tooltip.scratch;
                    });
                    return result;
                }, {
                    dent_total: 0,
                    group_name: null,
                    scratch_total: 0,
                    tooltip: [
                        { text: 'Oversize', icon: 'mdi-checkbox-blank', color: '#561329', dent: 0, scratch: 0 },
                        { text: 'Severe', icon: 'mdi-checkbox-blank', color: '#81182E', dent: 0, scratch: 0},
                        { text: 'Heavy', icon: 'mdi-checkbox-blank', color: '#C1484E', dent: 0, scratch: 0 },
                        { text: 'Medium', icon: 'mdi-checkbox-blank', color: '#CC9188', dent: 0, scratch: 0 },
                        { text: 'Light', icon: 'mdi-checkbox-blank', color: '#D8D1C1', dent: 0, scratch: 0 },
                    ]
                });
                damageGroupItem['group_name'] = panelGroup.name;
                this.damageLoading = false;
                if(selected) {
                    this.selectedDamage = damageGroupItem;
                    return;
                } else return damageGroupItem;
            },
            handleAllDamageDetails() {
                this.allDamageDetails = {};
                this.panelGroupsPosition.map(group => {
                    this.allDamageDetails[group.name] = this.handleDamageDetail(group, false);
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
.scratch-badge {
    height: 10px;
    width: 10px;
    background-color: #334A8F;
    border-radius: 50%;
    border: 1px solid transparent;
}
.dent-badge {
    height: 10px;
    width: 10px;
    background-color: #B54054;
    border-radius: 50%;
    border: 1px solid transparent;
}
</style>