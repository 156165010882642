export default {
    setProductLoading(state, data) {
        state.productLoading = data;
    },
    setProductsListLoading(state, data) {
        state.productsListLoading = data;
    },
    setProductsList(state, data) {
        state.productsList = data;
        state.productsListLoading = false;
    }
}