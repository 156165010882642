import http from '@/http';

import auth from '@/store/modules/auth';
import scans from '@/store/modules/scans';
import users from '@/store/modules/users';
import roles from '@/store/modules/roles';
import permissions from '@/store/modules/permissions';
import devices from '@/store/modules/devices';
import locations from '@/store/modules/locations';
import clients from '@/store/modules/clients';
import quote from '@/store/modules/quote';
import agents from '@/store/modules/agents';
import products from '@/store/modules/products';
import parts from '@/store/modules/parts';
import panelgroups from '@/store/modules/panel-groups';
import appsettings from '@/store/modules/app-settings';
import panels from '@/store/modules/panels';
import email from '@/store/modules/email';
import webhooks from '@/store/modules/webhooks';

export default {
    modules: {
        auth,
        scans,
        users,
        roles,
        permissions,
        devices,
        locations,
        clients,
        quote,
        agents,
        products,
        parts,
        panelgroups,
        panels,
        appsettings,
        email,
        webhooks
    },
    actions: {
        getModel({ commit }, data) {
            return new Promise((res, rej) => {
                http().get(data).then((response) => {
                    res({items: response.data, total: response.meta.total, last_page: response.meta.last_page});  
                }).catch(error => {
                    rej(error);
                })
            });
        },
    }
}