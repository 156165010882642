<template>
  <v-row id="device-form-page">
    <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="saveDevice">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" lg="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Location</v-list-subheader>
                            <v-autocomplete ref="location_id" v-model="model.location_id" :items="locationsList" 
                                item-title="text" item-value="value" variant="outlined" label="" 
                                :error-messages="v$.model.location_id.$errors.map(e => e.$message)" placeholder="Select Location"
                                @update:model-value="v$.model.location_id.$touch()"  @blur="v$.model.location_id.$touch()"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Type</v-list-subheader>
                            <v-select ref="type"  v-model="model.type" :items="typeList" item-title="text" item-value="value" variant="outlined" label="" class="text-capitalize"
                                :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}" 
                                :error-messages="v$.model.type.$errors.map(e => e.$message)" placeholder="Select Type"
                                @update:modelValue="v$.model.type.$touch()"  @blur="v$.model.type.$touch()" required>
                                    <template slot="item" slot-scope="{ item }">
                                        <span class="text-capitalize">{{ item }}</span>
                                    </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" lg="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Agent</v-list-subheader>
                            <v-autocomplete ref="agent_id" v-model="model.agent_id" :items="agentsList" item-title="text" item-value="value" variant="outlined" label=""
                                :error-messages="v$.model.agent_id.$errors.map(e => e.$message)" placeholder="Select Agent"
                                @update:model-value="v$.model.agent_id.$touch()"  @blur="v$.model.agent_id.$touch()"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Serial</v-list-subheader>
                            <v-text-field v-model="model.serial" :error-messages="v$.model.serial.$errors.map(e => e.$message)"
                                placeholder="Serial" variant="outlined" ref="serial" @update:model-value="v$.model.serial.$touch()"  @blur="v$.model.serial.$touch()" required />
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" lg="6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-list-subheader class="text-primary pa-0 ma-0 h-auto mb-1 text-capitalize">Client</v-list-subheader>
                            <v-autocomplete ref="default_client_id" v-model="model.default_client_id" :items="clientsList" item-title="text" item-value="value" variant="outlined" label=""
                                :error-messages="v$.model.default_client_id.$errors.map(e => e.$message)" placeholder="Select Client"
                                @update:model-value="v$.model.default_client_id.$touch()"  @blur="v$.model.default_client_id.$touch()"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="primary"  class="mr-4">
                            Submit
                        </v-btn>
                        <v-btn type="button" variant="outlined" color="primary" class="mr-4" @click="$router.push('/devices')">
                            Cancel
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions, mapGetters } from 'vuex';

export default {
    data: (vm) => ({
        deviceId: (vm.$route.params.deviceId).toLowerCase(),
        model: {
            location_id: '',
            type: '',
            agent_id: '',
            serial: '',
            default_client_id: ''
        },
        typeList: ['arch', 'chasis', 'tire'],
        v$: useVuelidate(),
    }),
    validations() {
        return {
            model: {
                location_id: { required},
                type: { required },
                agent_id: { required },
                serial: { required },
                default_client_id: { required }
            }
        }
    },
    computed: {
        ...mapGetters(['locationsListLoading', 'locationsList', 'agentsList', 'clientsList']),
    },
    created() {
        if (this.deviceId != 'create') {
          this.getSelectedDevice(this.deviceId).then(response => this.model = response);
        }
        this.getLocationLists();
        this.getAgentLists();
        this.getClientLists();
    },
    methods: {
        ...mapActions(['getLocationLists', 'getSelectedDevice', 'createDevice', 'updateSelectedDevice',
        'getAgentLists', 'getClientLists']),

        saveDevice() {
            this.v$.$touch();
            if (this.v$.$invalid) {
                this.$helpers.focusErrorElement(this.v$.model, this.$refs);
                return;
            }
            if (this.deviceId != 'create') {
                this.updateSelectedDevice(this.model).then(resp => this.$router.push("/devices"));
            } else {
                this.createDevice(this.model).then(resp => this.$router.push("/devices"));
            }
        },
    },
};
</script>
