<template>
  <div id="login-page">
    <v-row>
      <v-col cols="12" md="7" class="bg-primary mt-3" style="height: 100vh;">
      </v-col>
      <v-col cols="12" md="5" class="ma-0 pa-0">
        <div class="ma-0 pa-0">
          <div class="d-flex justify-center align-center text-center md:h-screen h-full-vh">
            <div class="w-60">
              <v-img :src="Logo" class="mb-10" contain/>
              <v-form @submit.prevent="onSubmit">
                <v-text-field
                  prepend-icon="mdi-account" ref="email"
                  label="Email"
                  v-model="user.email"
                  class="mb-4"
                  :error-messages="
                    v$.user.email.$errors.map(e => e.$message)
                  "
                  required
                  @update:model-value="v$.user.email.$touch()"
                  @blur="v$.user.email.$touch()"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-account-lock" ref="password"
                  :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  name="input-10-2"
                  label="Password"
                  v-model="user.password"
                  :error-messages="v$.user.password.$errors.map(e => e.$message)"
                  required @update:model-value="v$.user.password.$touch()" @blur="v$.user.password.$touch()"
                  @click:append="show = !show"
                ></v-text-field>
                <v-btn
                  type="submit"
                  size="large"
                  block
                  :loading="loading"
                  :disabled="loading"
                  class="mt-2"
                  color="primary"
                >
                  Sign In
                </v-btn>
              </v-form>

              <div class="my-4">
                <v-divider></v-divider>
              </div>

              <div class="text-center">
                <div class="d-flex justify-end flex-wrap">
                  <v-btn type="button"
                    variant="text"
                    size="small"
                    class="mb-2"
                    @click="$router.push('forgot-password')"
                    >Forgot Password</v-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { useHead } from '@vueuse/head';

import Logo from "../../assets/images/scanspek-logo.png";

export default {
  name: "Login",
  setup () {
    useHead({
      title: 'Login',
    })
  },
  data() {
    return {
      show: false,
      user: {
        email: "",
        password: "",
      },
      v$: useVuelidate(),
      Logo,
    };
  },
  validations: {
    user: {
      email: { required, email },
      password: { required },
    },
  },
  computed: {
    ...mapGetters(["loading"])
  },
  methods: {
    ...mapActions(["login"]),

    onSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.$helpers.focusErrorElement(this.v$.user, this.$refs);
        return;
      }
      this.login(this.user).then(response => {
          this.$router.push("/");
        }).catch(error => {
          console.error("Invalid Login Credentials >>", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
