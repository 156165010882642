<template>  
    <v-row id="error-403-page" class="flex-column ma-0 pa-0 align-center justify-center">
        <div class="text-primary text-center font-weight-bold pa-0 d-flex align-center justify-center mb-3">
            <div class="error-code">4</div>
            <v-img :src="errorImage" width="200" height="200" />
            <div class="error-code">3</div>
        </div>
        <div class="text-h4 text-center text-primary font-weight-bold mb-4 text-uppercase">page access denied !</div>
        <div class="text-center text-uppercase mt-6 mb-2 text-secondary">
            YOU SEEM TO BE TRYING TO FIND THIS WAY HOME
        </div>
        <v-btn class="ma-2" variant="outlined" color="primary" @click="goHome">Back To Home</v-btn>
    </v-row>
</template>

<script>
import { useHead } from '@vueuse/head'; 

import menuItems from "@/constants/sidebar-menu";
import errorImage from "@/assets/images/hand-denied.png";

export default {
    name: 'Error403',
    setup() {
      useHead({
        title: "Error 403",
      })
    },
    data () {
        return {
            errorImage,
        }
    },
    methods: {
        goHome() {
            const userAccessPermission = this.$helpers.getCurData('curUserPermissions'),
                route = menuItems.find(item => (item.childAccess && item.childAccess.indexOf(userAccessPermission[0]) !== -1) ||
                        item.access === userAccessPermission[0]),
                navRoute = route.children ? route.children.find(item => (item.childAccess && item.childAccess.includes(userAccessPermission[0])) ||
                    (item.access && item.access === userAccessPermission[0])) : route;
            if (this.$helpers.getCurData('curUser')) {
                if (userAccessPermission.indexOf('scan') !== -1) this.$router.push('/');
                else this.$router.push(`/${navRoute.childAccess ? navRoute.children[0].to : navRoute.to}`);
            } else this.$router.push('/login');
        }
    }
}
</script>

<style>

</style>