import http from '@/http';

class webhooks {
    eventsList(data) {
        return http('agent').get('webhooks/events', data);
    }
    webhookModel(webhookId) {
        return http('agent').get(`webhooks/${webhookId}`);
    }
    createWebhookModel(data) {
        return http('agent').post('webhooks', data);
    }
    updateWebhookModel(id, data) {
        return http('agent').put(`webhooks/${id}`, data);
    }
    deleteWebhookModel(webhookId) {
        return http('agent').deleteReq(`webhooks/${webhookId}`)
    }
}

export default new webhooks();