import http from '@/http';

class products {
    productsList(data) {
        return http('quote').post('products/lists', data);
    }
    productModel(productId) {
        return http('quote').get(`products/${productId}`);
    }
    createProductModel(data) {
        return http('quote').post('products', data);
    }
    updateProductModel(data) {
        return http('quote').put(`products/${data.id}`, data);
    }
    deleteProductModel(productId) {
        return http('quote').deleteReq(`products/${productId}`)
    }
}

export default new products();