<template>
    <v-col cols="12" md="12" lg="9">
        <v-card class="mx-auto">
            <v-toolbar color="primary" dark class="scan-title-bar" elevation="3">
                <v-toolbar-title class="text-section-title">Quote Details</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-row class="ma-0 mb-10 align-center justify-center">
                    <v-switch inset label="Metallic" class="ma-3" :true-value="1" :false-value="0"
                        v-model="optModel.metallic"></v-switch>
                    <v-switch inset label="Pearl" class="ma-3" :true-value="1" :false-value="0"
                        v-model="optModel.pearl"></v-switch>
                    <v-switch inset label="Aluminium" class="ma-3" :true-value="1" :false-value="0"
                        v-model="optModel.aluminium"></v-switch>
                    <v-switch inset label="Lg Vehicle" class="ma-3" :true-value="'L'" :false-value="'D'"
                        v-model="optModel.vehicle_type"></v-switch>
                    <v-switch inset label="XL Vehicle" class="ma-3" :true-value="'XL'" :false-value="'D'"
                        v-model="optModel.vehicle_type"></v-switch>
                </v-row>
                <quote-arch-cards :scanId="quoteScanId" :measures="measures" @itemsUpdate="model.items = $event" :optModel="optModel" :loading="apiLoading"
                    @totalModelUpdate="$emit('totalUpdate', $event)" :panelItems="itemsModel ? itemsModel: null"/>
                <add-products @productsUpdate="model.products = $event"
                    @priceUpdate="$emit('prodPriceUpdate', $event)" :lists="model && model.products ? model.products: []"/>
                <add-parts @partsUpdate="model.parts = $event" :parts="partsList" :lists="model && model.parts ? model.parts: []"/>
                <add-vehicle-photo @vehiclePhotoUpdate="updateVehiclePhoto" :vehiclePhoto="vehicle_photo"/>
                <add-comment @modelUpdate="model = {...model, ...$event}" :data="commentModel"/>
                <v-row class="ma-0 my-3 align-center justify-center">
                    <v-btn type="submit" color="primary" @click="saveGenerateQuote" :disabled="loading">
                        {{ loading ? 'Loading..' : 'Save and Generate Quote' }}
                        <v-icon end v-if="!loading">mdi-download</v-icon>
                        <v-progress-circular indeterminate color="primary" v-if="loading"
                            :size="20" class="ml-2"></v-progress-circular>
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';

import quoteArchCards from "./QuoteCards.vue";
import addProducts from "./AddProducts.vue";
import addParts from "./AddPart.vue";
import addVehiclePhoto from './AddVehiclePhoto.vue';
import addComment from "./AddComment.vue";

export default {
    emits: ['totalUpdate', 'prodPriceUpdate'],
    components: {
        quoteArchCards,
        addProducts,
        addParts,
        addVehiclePhoto,
        addComment,
    },
    data: (vm) => ({
        quoteScanId: vm.$route.params.quoteScanId,
        model: {
            scan_id: vm.$route.params.scanId,
            parts: [],
            products: [],
            assessor: vm.$helpers.getCurData('curUser').name || '',
        },
        partsList: [],
        optModel: {
            aluminium: 0,
            metallic: 0,
            pearl: 0,
            vehicle_type: 'D'
        },
        scanType: '',
        commentModel: null,
        vehicle_photo: '',
        itemsModel: null,
        loading: false,
        apiLoading: false,
        vehiclePhotoFile: null,
        measures: {},
    }),
    created() {
        this.apiLoading = true;
        this.getQuoteEvaluation(this.quoteScanId).then(resp => {
            this.measures = resp.measures;
            this.getQuote(resp);
        });
    },
    methods: {
        ...mapActions(['createQuote', 'searchQuote', 'getQuoteEvaluation']),

        getQuote(quoteEval) {
            this.searchQuote(this.quoteScanId).then(resp => {
                if(!_.isEmpty(resp)) {
                    this.itemsModel = resp.items;
                    this.model = resp;
                    this.model.client_id = quoteEval.client ? quoteEval.client.id : '';
                    if (quoteEval.client.id !== resp.client_id) this.model.products = [];
                    this.commentModel = {manual_check: resp.manual_check, assessor_comments: resp.assessor_comments};
                    this.vehicle_photo = resp.vehicle_photo || '';
                    Object.keys(this.optModel).map(item => {
                        this.optModel[item] = resp[item] !== undefined && resp[item] !== null ? resp[item] : item === 'vehicle_type' ? 'D' : this.optModel[item];
                    });
                } else {
                    this.model = { scan_id: this.quoteScanId, client_id: quoteEval.client ? quoteEval.client.id : '' };
                }
                this.apiLoading = false;
            }).catch(err => {
                this.model = { scan_id: this.quoteScanId, client_id: quoteEval.client ? quoteEval.client.id : '' };
                this.apiLoading = false;
            });

            if (quoteEval.scanInfo) {
              this.scanType = quoteEval.scanInfo.type || '';
            }

            // Check if clientData has parts before mapping over it
            if (quoteEval.client && quoteEval.client.parts) {
              this.partsList = quoteEval.client.parts.map((v) => {
                return { "text": v, "value": v }
              });
            }
        },
        updateVehiclePhoto(photoFile) {
            this.vehiclePhotoFile = photoFile;
        },
        async saveGenerateQuote() {
            this.loading = true;
            const self = this;
            const formData = new FormData();
            const headers = {
                'Content-Type': 'multipart/form-data'
            };
            const reqData = this.$helpers.cloneData(this.model);
            reqData['items'] = {};
            reqData['scan_id'] = this.quoteScanId;
            reqData['client_id'] = this.model.client_id || '';
            reqData['type'] = this.scanType;

            // Add vehicle photo file if it exists
            if (this.vehiclePhotoFile) {
                formData.append('vehicle_photo', this.vehiclePhotoFile);
            }
            formData.append('client_id', reqData['client_id']);
            formData.append('scan_id', reqData['scan_id']);
            formData.append('type', reqData['type']);
            await Object.keys(this.model.items).map(key => {
                if (!this.model.items[key].hasLargeVariant || this.optModel.vehicle_type == this.model.items[key].vehicle_type) {
                    reqData['items'][key] = this.model.items[key];
                }
            });
            formData.append('items', JSON.stringify(reqData['items']));
            formData.append('assessor_comments', this.model.assessor_comments || '');
            formData.append('parts', this.model.parts || []);
            formData.append('products', JSON.stringify(this.model.products || []));
            Object.keys(this.optModel).forEach(key => {
                formData.append(key, this.optModel[key]);
            });
            try {
                const resp = await this.createQuote(formData, { headers });
                self.loading = false;
                self.$router.push(`/quotes/${resp.id}/invoice`);
            } catch (err) {
                this.loading = false;
                console.error(err);
            }
        }
    }
}
</script>

<style>

</style>
