<template>
   <v-row class="ma-0 mt-2" >
        <v-col cols="12" class="pl-0">
            <v-chip color="primary" variant="flat" label text-color="white">
                <v-icon size="x-large" class="ml-n2" start> mdi-label</v-icon>Dents
            </v-chip>
        </v-col>
        <v-col cols="12">
            <v-card>
                <v-table hover>
                    <template v-slot:default>
                        <thead>
                            <tr class="bg-primary">
                                <th class="text-center text-uppercase text-white" :class="title !== 'PDR' ? 'text-center' : 'text-left'"
                                    v-for="title in tableModel.headers" :key="title">{{title}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="pdrListLoading">
                                <td :colspan="tableModel.headers.length" class="pa-0">
                                    <v-skeleton-loader :loading="true" class="w-full"
                                        type="table"
                                    ></v-skeleton-loader>
                                </td>
                            </tr>
                            <template v-if="!pdrListLoading && tableModel.items.length">
                                <tr v-for="item in tableModel.items" :key="item.parts.value">
                                    <td class="text-left text-primary bg-secondary border-b-0" width="20%">{{item.parts.text}}</td>
                                    <td class="text-center pa-0 pa-3" v-for="(value, key, index) in item.items_count" :key="index">
                                        <v-text-field class="box-shadow-none input-align-center px-0" type="number"
                                            variant="outlined" min="0" solo density="compact"
                                            v-model.number="model[item.parts.value][key]" hide-details="auto" :placeholder="!item.focus[key] ? item.placeholder[key] : ''"
                                            @focus="item.focus[key] = true" @focusout="item.focus[key] = false"
                                            @update:model-value="$emit('modelUpdate', {key: item.parts.value, field: key, value: $event})"
                                        />
                                    </td>
                                </tr>
                            </template>
                            <template v-else-if="!pdrListLoading && tableModel.items.length === 0">
                                <tr>
                                    <td :colspan="tableModel.headers.length" class="text-center text-caption pa-5">
                                        No PDR Data Found!.
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </template>
                </v-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

import { DENT_HEADERS, ITEMS } from '../services/dent-scratch-data';

export default {
    props:['modelPrices'],
    emits:['modelUpdate'],
    data: (vm) => ({
        tableModel: {
            headers: DENT_HEADERS,
            items: []
        },
        model: {}
    }),
    computed: {
        ...mapGetters(['pdrListLoading', 'pdrLists']),
    },
    watch: {
        pdrLists(newVal) {
            if (newVal.length) this.tableModel.items = ITEMS(newVal);
        },
        modelPrices(newVal) {
            this.model = {...this.model, ...newVal};
        }
    }
}
</script>

<style>

</style>