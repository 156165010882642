<template>
  <div name="snackbars" id="toastr-page" @click="show = false">
    <v-snackbar
      v-model="show"
      :color="color"
      :timeout="timeout"
      fixed
      location='top right'
      elevation="24"
    >
      <span class="font-weight-bold text-white">{{ text }}</span>

      <template v-slot:actions="{ props }">
        <v-btn dark text v-bind="props" @click="show = false">
          <v-icon color="white">mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation && mutation.type === "snackbar/SHOW_MESSAGE") {
        this.text = state.commonModules.snackbar.text;
        this.color = state.commonModules.snackbar.color;
        this.timeout = 10000;
        this.show = true;
      }
    });
  },
  data() {
    return {
      show: false,
      color: "",
      text: "",
      timeout: -1,
    };
  },
};
</script>
