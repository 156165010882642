<template>
  <common-modal :open="openCrop ? true : false" :width="'1000px'" :persistent="true">
    <v-card class="cropper-modal-container position-relative">
      <v-card-text v-if="!imgLoading" class="loading-spinner">
        <v-row class="ma-0 pa-7 align-center justify-center">
          <div class="spinner-design-loader"></div>
        </v-row>
      </v-card-text>
      <v-card-text>
        <cropper :class="{ 'cropper-image-loading': !imgLoading }" @ready="imageLoadingTime" class="custom-cropper" :src="openCrop" @change="imageCropping" :canvas="{maxArea: 1024*1024}"/>
      </v-card-text>
      <v-divider v-if="imgLoading"></v-divider>
      <v-card-actions v-if="imgLoading">
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="loading" @click="saveCroppedImg">Crop</v-btn>
        <v-btn color="primary" :disabled="loading" variant="outlined" @click="cancelCrop">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </common-modal>
</template>

<script>
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import {CommonModal} from "@/components/common";

export default {
  props: ['openCrop', 'name'],
  emits: ['cropped', 'cancel'],
  components: {
    CommonModal,
    Cropper,
  },
  data: (vm) => ({
    croppedImage: '',
    loading: false,
    imgLoading: false,
  }),
  created() { 
    this.loading = false;
  },
  methods: {
    dataURLtoBlob(dataURL) {
      // Decode the dataURL
      var binary = atob(dataURL.split(',')[1]);
      // Create 8-bit unsigned array
      var array = [];
      for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      // Return our Blob object
      return new Blob([new Uint8Array(array)], {type: 'image/jpg'});
    },
    imageCropping({ canvas }) {
      this.croppedImage = canvas.toDataURL('image/jpg');
    },
    async saveCroppedImg() {
      this.loading = true;
      const image = await this.dataURLtoBlob(this.croppedImage);
      this.$emit('cropped', {value: image, option: this.name});
      this.croppedImage = '';
    },
    cancelCrop() {
      this.$emit('cancel');
      this.croppedImage = null;
    },
    imageLoadingTime() {
      const self = this;
      setTimeout(() => {
        self.imgLoading = true;
      }, 300);
    }
  }
}
</script>